import { QueryObserverOptions, useQuery } from 'react-query';
import { CToken } from 'types';

import getCTokenBalanceOf, {
  GetCTokenBalanceOfInput,
  GetCTokenBalanceOfOutput,
} from 'clients/api/queries/getCTokenBalanceOf';
import { useCTokenContract } from 'clients/contracts/hooks';
import FunctionKey from 'constants/functionKey';

interface TrimmedParams extends Omit<GetCTokenBalanceOfInput, 'cTokenContract'> {
  cToken: CToken;
}

type Options = QueryObserverOptions<
  GetCTokenBalanceOfOutput,
  Error,
  GetCTokenBalanceOfOutput,
  GetCTokenBalanceOfOutput,
  [FunctionKey.GET_C_TOKEN_BALANCE, { accountAddress: string; cTokenAddress: string }]
>;

const useGetCTokenBalanceOf = ({ accountAddress, cToken }: TrimmedParams, options?: Options) => {
  const cTokenContract = useCTokenContract(cToken);

  return useQuery(
    [FunctionKey.GET_C_TOKEN_BALANCE, { accountAddress, cTokenAddress: cToken.address }],
    () => getCTokenBalanceOf({ cTokenContract, accountAddress }),
    options,
  );
};

export default useGetCTokenBalanceOf;
