import { QueryObserverOptions, useQuery } from 'react-query';

import { GnftTokenType } from 'types';

import { DEFAULT_REFETCH_INTERVAL_MS } from 'constants/defaultRefetchInterval';
import FunctionKey from 'constants/functionKey';

import getQuoteLiquidityForLock, {
  GetQuoteLiquidityForLockInput,
  GetQuoteLiquidityForLockOutput,
} from '.';

type Options = QueryObserverOptions<
  GetQuoteLiquidityForLockOutput,
  Error,
  GetQuoteLiquidityForLockOutput,
  GetQuoteLiquidityForLockOutput,
  [FunctionKey.GET_TPI_REWARD, GnftTokenType]
>;

const useGetQuoteLiquidityForLock = (input: GetQuoteLiquidityForLockInput, options?: Options) =>
  useQuery([FunctionKey.GET_TPI_REWARD, input.tokenType], () => getQuoteLiquidityForLock(input), {
    refetchInterval: DEFAULT_REFETCH_INTERVAL_MS,
    ...options,
  });

export default useGetQuoteLiquidityForLock;
