import { checkForComptrollerTransactionError } from 'errors';
import type { TransactionReceipt } from 'web3-core/types';

import { GnftSegmentManager } from 'types/contracts';

export interface ActivateGnftSegmentWithProofInput {
  gnftSegmentManagerContract: GnftSegmentManager;
  fromAccountAddress: string;
  tokenId: string;
  nonce: string;
  proof: string[];
  market: string;
}

export type ActivateGnftSegmentWithProofOutput = TransactionReceipt;

const activateGnftSegmentWithProof = async ({
  gnftSegmentManagerContract,
  fromAccountAddress,
  tokenId,
  nonce,
  proof,
  market,
}: ActivateGnftSegmentWithProofInput): Promise<ActivateGnftSegmentWithProofOutput> => {
  const resp = await gnftSegmentManagerContract.methods
    .activateSegmentWithProof(tokenId, fromAccountAddress, nonce, proof, market)
    .send({ from: fromAccountAddress });

  return checkForComptrollerTransactionError(resp);
};

export default activateGnftSegmentWithProof;
