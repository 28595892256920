import { ChainId, Token } from 'types';

import btcb from 'assets/img/tokens/btcb.svg';
import eth from 'assets/img/tokens/eth.svg';
import usdc from 'assets/img/tokens/usdc.svg';
import usdt from 'assets/img/tokens/usdt.svg';
import pTon from 'assets/img/tokens/pTon.svg';
import tpi from 'assets/img/tokens/tpi.svg';
import dai from 'assets/img/tokens/dai.svg';
import cbeth from 'assets/img/tokens/cbeth.svg';
import reth from 'assets/img/tokens/reth.svg';
import seth2 from 'assets/img/tokens/seth2.svg';

import TOKEN_ADDRESSES from '../../contracts/addresses/tokens.json';

export const MAINNET_TOKENS = {
  usdc: {
    id: 'usdc',
    symbol: 'USDC',
    decimals: 6,
    address: TOKEN_ADDRESSES.usdc[ChainId.MAINNET],
    asset: usdc,
  } as Token,
  usdt: {
    id: 'usdt',
    symbol: 'USDT',
    decimals: 6,
    address: TOKEN_ADDRESSES.usdt[ChainId.MAINNET],
    asset: usdt,
  } as Token,
  dai: {
    id: 'dai',
    symbol: 'DAI',
    decimals: 18,
    address: TOKEN_ADDRESSES.dai[ChainId.MAINNET],
    asset: dai,
  } as Token,
  wbtc: {
    id: 'wbtc',
    symbol: 'wBTC',
    decimals: 8,
    address: TOKEN_ADDRESSES.wbtc[ChainId.MAINNET],
    asset: btcb,
  } as Token,
  pton: {
    id: 'pton',
    symbol: 'pTON',
    decimals: 9,
    address: TOKEN_ADDRESSES.pton[ChainId.MAINNET],
    asset: pTon,
  },
  weth: {
    id: 'weth',
    symbol: 'wETH',
    decimals: 18,
    address: TOKEN_ADDRESSES.weth[ChainId.MAINNET],
    asset: eth,
  } as Token,
  tpi: {
    id: 'tpi',
    symbol: 'TPI',
    decimals: 18,
    address: TOKEN_ADDRESSES.tpi[ChainId.MAINNET],
    asset: tpi,
  } as Token,
  cbeth: {
    id: 'cbeth',
    symbol: 'cbETH',
    decimals: 18,
    address: TOKEN_ADDRESSES.cbeth[ChainId.MAINNET],
    asset: cbeth,
  } as Token,
  reth: {
    id: 'reth',
    symbol: 'rETH',
    decimals: 18,
    address: TOKEN_ADDRESSES.reth[ChainId.MAINNET],
    asset: reth,
  } as Token,
  seth2: {
    id: 'seth2',
    symbol: 'sETH2',
    decimals: 18,
    address: TOKEN_ADDRESSES.seth2[ChainId.MAINNET],
    asset: seth2,
  } as Token,
};
