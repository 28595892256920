import * as React from 'react';
import { SVGProps } from 'react';

const SvgDiamond = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6 2L1 8L12 22L23 8L18 2H6Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path d="M1 8L23 8" stroke="currentColor" strokeWidth="2" strokeLinejoin="round" />
    <path d="M12 8L12 21" stroke="currentColor" strokeWidth="2" strokeLinejoin="round" />
    <path d="M6 2L12 8L18 2" stroke="currentColor" strokeWidth="2" strokeLinejoin="round" />
  </svg>
);

export default SvgDiamond;
