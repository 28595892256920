import { CToken, ChainId } from 'types';

import cWbtc from 'assets/img/tokens/cWbtc.svg';
import cEth from 'assets/img/tokens/cEth.svg';
import cUsdc from 'assets/img/tokens/cUsdc.svg';
import cUsdt from 'assets/img/tokens/cUsdt.svg';
import cpTon from 'assets/img/tokens/cpTon.svg';
import cDai from 'assets/img/tokens/cDai.svg';
import cSeth2 from 'assets/img/tokens/cSeth2.svg';
import cReth from 'assets/img/tokens/cReth.svg';
import cCbeth from 'assets/img/tokens/cCbeth.svg';

import C_TOKEN_ADDRESSES from '../../contracts/addresses/cTokens.json';
import { MAINNET_TOKENS } from '../common/mainnet';

export const MAINNET_CERC_TOKENS = {
  usdc: {
    id: 'usdc',
    symbol: 'tUSDC',
    address: C_TOKEN_ADDRESSES.usdc[ChainId.MAINNET],
    decimals: 8,
    asset: cUsdc,
    underlyingToken: MAINNET_TOKENS.usdc,
  } as CToken,
  usdt: {
    id: 'usdt',
    symbol: 'tUSDT',
    address: C_TOKEN_ADDRESSES.usdt[ChainId.MAINNET],
    decimals: 8,
    asset: cUsdt,
    underlyingToken: MAINNET_TOKENS.usdt,
  } as CToken,
  dai: {
    id: 'dai',
    symbol: 'tDAI',
    address: C_TOKEN_ADDRESSES.dai[ChainId.MAINNET],
    decimals: 8,
    asset: cDai,
    underlyingToken: MAINNET_TOKENS.dai,
  } as CToken,
  wbtc: {
    id: 'wbtc',
    symbol: 'tWBTC',
    address: C_TOKEN_ADDRESSES.wbtc[ChainId.MAINNET],
    decimals: 8,
    asset: cWbtc,
    underlyingToken: MAINNET_TOKENS.wbtc,
  } as CToken,
  weth: {
    id: 'weth',
    symbol: 'tWETH',
    address: C_TOKEN_ADDRESSES.weth[ChainId.MAINNET],
    decimals: 8,
    asset: cEth,
    underlyingToken: MAINNET_TOKENS.weth,
  } as CToken,
  pton: {
    id: 'pton',
    symbol: 'tPTON',
    address: C_TOKEN_ADDRESSES.pton[ChainId.MAINNET],
    decimals: 8,
    asset: cpTon,
    underlyingToken: MAINNET_TOKENS.pton,
  } as CToken,
  cbeth: {
    id: 'cbeth',
    symbol: 'tcbETH',
    address: C_TOKEN_ADDRESSES.cbeth[ChainId.MAINNET],
    decimals: 8,
    asset: cCbeth,
    underlyingToken: MAINNET_TOKENS.cbeth,
  } as CToken,
  reth: {
    id: 'reth',
    symbol: 'trETH',
    address: C_TOKEN_ADDRESSES.reth[ChainId.MAINNET],
    decimals: 8,
    asset: cReth,
    underlyingToken: MAINNET_TOKENS.reth,
  } as CToken,
  seth2: {
    id: 'seth2',
    symbol: 'tsETH2',
    address: C_TOKEN_ADDRESSES.seth2[ChainId.MAINNET],
    decimals: 8,
    asset: cSeth2,
    underlyingToken: MAINNET_TOKENS.seth2,
  } as CToken,
};
