import { MutationObserverOptions, useMutation } from 'react-query';
import { CToken } from 'types';

import { BorrowInput, BorrowOutput, borrow, queryClient } from 'clients/api';
import { useCTokenContract } from 'clients/contracts/hooks';
import FunctionKey from 'constants/functionKey';

type Options = MutationObserverOptions<BorrowOutput, Error, Omit<BorrowInput, 'CTokenContract'>>;

const useBorrow = ({ cToken }: { cToken: CToken }, options?: Options) => {
  const CTokenContract = useCTokenContract(cToken);

  return useMutation(
    FunctionKey.BORROW,
    params =>
      borrow({
        CTokenContract,
        ...params,
      }),
    {
      ...options,
      onSuccess: (...onSuccessParams) => {
        const { fromAccountAddress } = onSuccessParams[1];

        queryClient.invalidateQueries(FunctionKey.GET_C_TOKEN_BALANCES_ALL);
        queryClient.invalidateQueries([
          FunctionKey.GET_BALANCE_OF,
          {
            accountAddress: fromAccountAddress,
            cTokenAddress: cToken.address,
          },
        ]);
        queryClient.invalidateQueries(FunctionKey.GET_MAIN_MARKETS);

        if (options?.onSuccess) {
          options.onSuccess(...onSuccessParams);
        }
      },
    },
  );
};

export default useBorrow;
