import type { TransactionReceipt } from 'web3-core';

import {
  ComptrollerErrorReporterError,
  ComptrollerErrorReporterFailureInfo,
  TokenErrorReporterError,
  TokenErrorReporterFailureInfo,
  GnftErrorReporterError,
  GnftErrorReporterInfo,
  GnftSegmentsManagerErrorReporterError,
  GnftSegmentsManagerErrorReporterInfo,
  TpiAirdropErrorReporterError,
  TpiAirdropErrorReporterInfo,
} from 'constants/contracts/errorReporter';

import { VError, VErrorPhraseMap } from './VError';

const checkForTransactionError = (
  receipt: TransactionReceipt,
  errorEnum:
    | typeof ComptrollerErrorReporterError
    | typeof TokenErrorReporterError
    | typeof GnftErrorReporterError
    | typeof GnftSegmentsManagerErrorReporterError
    | typeof TpiAirdropErrorReporterError,
  infoEnum:
    | typeof ComptrollerErrorReporterFailureInfo
    | typeof TokenErrorReporterFailureInfo
    | typeof GnftErrorReporterInfo
    | typeof GnftSegmentsManagerErrorReporterInfo
    | typeof TpiAirdropErrorReporterInfo,
) => {
  if (receipt.events?.Failure) {
    const { error, info } = receipt.events?.Failure.returnValues;
    throw new VError({
      type: 'transaction',
      code: errorEnum[error] as VErrorPhraseMap['transaction'],
      data: {
        error: errorEnum[error] as VErrorPhraseMap['transaction'],
        info: infoEnum[info] as VErrorPhraseMap['transaction'],
      },
    });
  }
  return receipt;
};

export const checkForComptrollerTransactionError = (receipt: TransactionReceipt) =>
  checkForTransactionError(
    receipt,
    ComptrollerErrorReporterError,
    ComptrollerErrorReporterFailureInfo,
  );

export const checkForTokenTransactionError = (receipt: TransactionReceipt) =>
  checkForTransactionError(receipt, TokenErrorReporterError, TokenErrorReporterFailureInfo);

export const checkForGnftTransactionError = (receipt: TransactionReceipt) =>
  checkForTransactionError(receipt, GnftErrorReporterError, GnftErrorReporterInfo);

export const checkForGnftSegmentsManagerTransactionError = (receipt: TransactionReceipt) =>
  checkForTransactionError(
    receipt,
    GnftSegmentsManagerErrorReporterError,
    GnftSegmentsManagerErrorReporterInfo,
  );

export const checkForTpiAirdropTransactionError = (receipt: TransactionReceipt) =>
  checkForTransactionError(
    receipt,
    GnftSegmentsManagerErrorReporterError,
    GnftSegmentsManagerErrorReporterInfo,
  );
