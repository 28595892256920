import React from 'react';
import clsx from 'classnames';
import { ReactComponent as Logo } from 'assets/img/tonpoundLogoPure.svg';
import { ReactComponent as LogoText } from 'assets/img/tonpoundLogoWithText.svg';

import './Logo.css';

export const LogoNoText = ({ className, ...props }: any) => (
  <div className={clsx('logo-wrapper', className)} {...props}>
    <Logo className="logo" />
  </div>
);

export const LogoWithText = ({ className, ...props }: any) => (
  <div className={clsx('logo-wrapper', className)} {...props}>
    <LogoText className="logo" />
  </div>
);
