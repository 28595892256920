/** @jsxImportSource @emotion/react */
import BigNumber from 'bignumber.js';
import { VError } from 'errors';
import React, { useContext } from 'react';
import { useTranslation } from 'translation';
import type { TransactionReceipt } from 'web3-core/types';

import { getContractAddress } from 'utilities';

import { useClaimTpiReward, useGetTpiReward } from 'clients/api';
import { TOKENS } from 'constants/tokens';
import { AuthContext } from 'context/AuthContext';
import useConvertWeiToReadableTokenString from 'hooks/useConvertWeiToReadableTokenString';
import useHandleTransactionMutation from 'hooks/useHandleTransactionMutation';

import { ButtonProps, SecondaryButton } from '../../Button';
import { TokenIcon } from '../../TokenIcon';
import TEST_IDS from '../testIds';
import { useStyles } from './styles';


export interface ClaimTpiRewardButtonProps extends Omit<ButtonProps, 'onClick'> {
  onClaimReward: () => Promise<TransactionReceipt | void>;
  amountWei?: BigNumber;
}

export const ClaimTpiRewardButtonUi: React.FC<ClaimTpiRewardButtonProps> = ({
  amountWei,
  onClaimReward,
  ...otherProps
}) => {
  const { t, Trans } = useTranslation();
  const styles = useStyles();

  const handleTransactionMutation = useHandleTransactionMutation();

  const readableAmount = useConvertWeiToReadableTokenString({
    valueWei: amountWei,
    token: TOKENS.tpi,
    minimizeDecimals: true,
  });

  // // Check readable amount isn't 0 (since we strip out decimals)
  // if (!amountWei || readableAmount.split(' ')[0] === '0') {
  //   return null;
  // }

  const handleClick = () =>
    handleTransactionMutation({
      mutate: onClaimReward,
      successTransactionModalProps: transactionReceipt => ({
        title: t('claimTpiRewardButton.successfulTransactionModal.title'),
        content: t('claimTpiRewardButton.successfulTransactionModal.message'),
        amount: {
          valueWei: amountWei || new BigNumber('0'),
          token: TOKENS.tpi,
        },
        transactionHash: transactionReceipt.transactionHash,
      }),
    });

  const disabled = parseFloat(readableAmount) === 0;

  return (
    <SecondaryButton
      data-testid={TEST_IDS.claimTpiRewardButton}
      css={styles.button}
      onClick={handleClick}
      {...otherProps}
      disabled={disabled}
      variant="secondary"
    >
      <Trans
        i18nKey="claimTpiRewardButton.title"
        components={{
          Icon: <TokenIcon token={TOKENS.tpi} css={styles.icon} />,
        }}
        values={{
          amount: readableAmount,
        }}
      />
    </SecondaryButton>
  );
};

export const ClaimTpiRewardButton: React.FC<ButtonProps> = props => {
  const { account } = useContext(AuthContext);

  const { data: TpiRewardData } = useGetTpiReward(
    {
      accountAddress: account?.address || '',
      tpiAddress: getContractAddress('tpi'),
      comptrollerAddress: getContractAddress('comptroller'),
    },
    {
      enabled: !!account?.address,
    },
  );

  const { mutateAsync: claimTpiReward, isLoading: isClaimTpiRewardLoading } = useClaimTpiReward();

  const handleClaim = async () => {
    if (!account?.address) {
      throw new VError({ type: 'unexpected', code: 'walletNotConnected' });
    }

    return claimTpiReward({
      fromAccountAddress: account.address,
    });
  };

  return (
    <ClaimTpiRewardButtonUi
      amountWei={TpiRewardData?.TpiRewardWei}
      loading={isClaimTpiRewardLoading}
      onClaimReward={handleClaim}
      {...props}
    />
  );
};

export default ClaimTpiRewardButton;
