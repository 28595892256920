import { css } from '@emotion/react';

export const useStyles = () => ({
  card: css`
    margin-bottom: 20px;
  `,
  easterEggs: css`
    max-width: 488px;
    margin: 0 auto;
  `,
  easterEggsTitle: css`
    margin-bottom: 20px;
  `,
  easterEgg: css`
    border: 1px solid #25272B;
    border-radius: 15px;
    padding: 10px;

    display: flex;
    justify-content: space-between;

    & + & {
        margin-top: 20px;
    }
  `,

  noEasterEggs: css`
    display: flex;
    align-items: center;
    gap: 10px;
    border: 1px solid #25272B;
    border-radius: 15px;
    padding: 10px;

    a {
      color: #00A6FF;
    }
  `,

  easterEggIcon: css`
    color: #00A6FF;
    width: 36px;
    height: 36px;
  `,
  easterEggInfo: css`
    display: flex;
    gap: 10px;
  `,
  easterEggReward: css`
    
  `,
  easterEggTitle: css`
    color: #777;
    font-size: 12px;
  `,
});
