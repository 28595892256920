/** @jsxImportSource @emotion/react */
import React, { useMemo, useContext } from 'react';
import { NavLink } from 'react-router-dom';

import { AuthContext } from 'context/AuthContext';

import { useGetEasterEggs, useGetGnftTokenIdsInAccount } from 'clients/api';

import { routes } from 'constants/routing';

import { Spinner } from 'components/Spinner';

import { Icon } from '../../Icon';

import { useStyles } from './styles';

type EasterEggCounterProps = {
  small?: boolean;
  onClick?: () => void;
};

const EasterEggCounter: React.FC<EasterEggCounterProps> = ({ small, onClick }) => {
  const styles = useStyles();
  const { account } = useContext(AuthContext);
  const accountAddress = account?.address || '';
  const { data: tokenIds = [], isLoading: isLoadingTokenIds } = useGetGnftTokenIdsInAccount(
    {
      accountAddress,
    },
    {
      enabled: !!accountAddress,
    },
  );
  const { data = [], isLoading: isLoadingEasterEggs } = useGetEasterEggs(
    {
      tokenIds,
    },
    { enabled: !!tokenIds.length },
  );
  const notClaimedEggs = useMemo(() => data.filter(item => !item.claimed), [data]);
  const isLoading = isLoadingTokenIds || isLoadingEasterEggs;
  const counterCss = [styles.counter];
  const counterWrapperCss = [styles.counterWrapper];
  if (!notClaimedEggs.length) {
    counterCss.push(styles.counterEmpty);
  }
  if (small) {
    counterWrapperCss.push(styles.counterWrapperSmall);
  }
  if (!accountAddress) return null;
  return (
    <NavLink to={routes.easterEggs.path} css={counterWrapperCss} onClick={onClick}>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Icon css={styles.counterIcon} name="easterEgg" />
          <div css={counterCss}>{notClaimedEggs.length}</div>
        </>
      )}
    </NavLink>
  );
};

export default EasterEggCounter;
