/** @jsxImportSource @emotion/react */
/* eslint-disable react/no-array-index-key */

import React, { useContext, useState } from 'react';
import { FormControl, FormControlLabel, FormLabel, Paper, Radio, RadioGroup } from '@mui/material';
import { useHistory } from 'react-router-dom';

import TpiIcon from 'assets/img/tokens/tpi.svg';

import { AuthContext } from 'context/AuthContext';
import {
  AirdropSelectionChose,
  useClaimTpiAirdrop,
  useGetAirdropProofs,
  useSetAirdropSelection,
} from 'clients/api';
import { useWeb3 } from 'clients/web3';
import { ChooseId, Proof } from 'clients/api/queries/getAirdropProofs';

import { Button, Icon, Spinner } from 'components';
import StatsContainer from 'components/Stats/StatsContainer';

import { useStyles } from './styles';

const ClaimTpiAirdropButton: React.FC<{ proof: Proof }> = ({ proof }) => {
  const { account: { address: accountAddress = '' } = {} } = useContext(AuthContext);
  const [loading, setLoading] = useState<boolean>(false);
  const { mutateAsync } = useClaimTpiAirdrop();
  const onClick = async () => {
    try {
      setLoading(true);
      await mutateAsync({
        fromAccountAddress: accountAddress,
        nonce: proof.nonce,
        proofs: proof.proof,
      });
    } catch (e) {
      alert((e as any).message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Button small onClick={onClick} loading={loading}>
      Claim
    </Button>
  );
};

type StatsProps = {
  proofs: Proof[];
  toChooseIds: ChooseId[];
};

const Stats: React.FC<StatsProps> = ({ proofs, toChooseIds }) => {
  const totalProofs = (proofs || []).length;
  const totalClaimedProofs = (proofs || []).filter(proof => proof.claimed).length;
  const totalUnclaimedProofs = (toChooseIds || []).length;
  const totalPassed = totalProofs + totalUnclaimedProofs;

  const cells = [
    {
      label: 'Total Claimed',
      value: `${totalClaimedProofs}/12`,
    },
    {
      label: 'Total Passed',
      value: `${totalPassed}/12`,
    },
  ];

  return <StatsContainer cells={cells} />;
};

type AirdropSelectionItem = ChooseId | Proof;

const AirdropSelection: React.FC<{ item: AirdropSelectionItem }> = ({ item }) => {
  const history = useHistory();
  const styles = useStyles();
  const [selection, setSelection] = useState<AirdropSelectionChose>(AirdropSelectionChose.GNFT);
  const [loading, setLoading] = useState<boolean>(false);
  const { mutateAsync } = useSetAirdropSelection();
  const { account: { address: accountAddress = '' } = {} } = useContext(AuthContext);
  const web3 = useWeb3();
  if (typeof (item as ChooseId).salt !== 'undefined') {
    const chose = item as ChooseId;

    const onSaveSelection = async () => {
      try {
        setLoading(true);
        const signature = await web3.eth.personal.sign(chose.salt, accountAddress, '');
        await mutateAsync({
          selection: [
            {
              id: chose.id,
              chose: selection,
              signature,
            },
          ],
        });
      } catch (e) {
        alert((e as any).message);
      } finally {
        setLoading(false);
      }
    };

    const onChangeSelection = (e: any) => {
      setSelection(parseInt(e.target.value, 10));
    };

    return (
      <div css={styles.airdropItem}>
        <div css={styles.airdropItemInfo}>
          <div css={styles.airdropItemIcon}>
            <Icon name="airdropBox" />
          </div>
          <FormControl>
            <div>Early Birds monthly airdrop</div>
            <FormLabel css={styles.airdropItemCaption}>Choose airdrop type</FormLabel>
            <RadioGroup value={selection} onChange={onChangeSelection} name="selection">
              <FormControlLabel
                value={AirdropSelectionChose.GNFT}
                label="gNFT Segment"
                control={<Radio />}
              />
              <FormControlLabel
                value={AirdropSelectionChose.TPI}
                label="800 TPI"
                control={<Radio />}
              />
            </RadioGroup>
          </FormControl>
        </div>
        <Button small loading={loading} onClick={onSaveSelection}>
          Save
        </Button>
      </div>
    );
  }

  const proof = item as Proof;

  const onGnftUseClick = () => {
    history.push('/gnft');
  };
  const isGNFTSegment = proof.selection === AirdropSelectionChose.GNFT;
  const title = isGNFTSegment ? 'Free gNFT Segment' : '800 TPI';
  const ItemIcon = isGNFTSegment ? () => <img src={TpiIcon} alt="TPI" /> : () => <Icon name="gnftSegment" />;

  return (
    <div css={styles.airdropItem}>
      <div css={styles.airdropItemInfo}>
        <div css={styles.airdropItemIcon}>
          <ItemIcon />
        </div>
        <div>
          <div>{title}</div>
          <div css={styles.airdropItemCaption}>Early Birds monthly airdrop</div>
        </div>
      </div>
      <div>
        {proof.claimed && (
          <Button css={styles.airdropItemUsed} small disabled>
            Used
          </Button>
        )}
        {!proof.claimed ? (
          <>
            {isGNFTSegment ? (
              <Button small onClick={onGnftUseClick}>
                Use
              </Button>
            ) : (
              <ClaimTpiAirdropButton proof={proof} />
            )}
          </>
        ) : null}
      </div>
    </div>
  );
};

type AirdropUIProps = {
  accountAddress: string;
  proofs: Proof[];
  toChooseIds: ChooseId[];
  isLoading: boolean;
};

const AirdropUI: React.FC<AirdropUIProps> = ({ proofs, toChooseIds, isLoading }) => {
  const styles = useStyles();
  if (isLoading) return <Spinner />;

  const items: AirdropSelectionItem[] = [...toChooseIds, ...proofs];

  return (
    <div css={styles.airdropContainer}>
      <h4 css={styles.title}>Your Early Birds stats</h4>
      <Stats proofs={proofs} toChooseIds={toChooseIds} />
      <h4 css={styles.title}>Available monthly rewards</h4>
      {items.length ? items.map((item, index) => (
        <Paper key={index} css={styles.airdropPaper}>
          <AirdropSelection item={item} />
        </Paper>
      )) : (
        <Paper>
          <p>No rewards yet</p>
        </Paper>
      )}
    </div>
  );
};

const Airdrop = () => {
  const { account } = useContext(AuthContext);
  const accountAddress = account?.address || '';

  const { data, isLoading } = useGetAirdropProofs(
    { accountAddress },
    { enabled: !!accountAddress },
  );

  const { proofs, toChooseIds } = data || { proofs: [], toChooseIds: [] };

  return (
    <AirdropUI
      accountAddress={accountAddress}
      proofs={proofs}
      toChooseIds={toChooseIds}
      isLoading={isLoading}
    />
  );
};

export default Airdrop;
