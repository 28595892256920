import { MutationObserverOptions, useMutation } from 'react-query';

import FunctionKey from 'constants/functionKey';

import {
  queryClient,
  setAirdropSelection,
  SetAirdropSelectionInput,
  SetAirdropSelectionOutput,
} from 'clients/api';

type Options = MutationObserverOptions<SetAirdropSelectionOutput, Error, SetAirdropSelectionInput>;

const useSetAirdropSelection = (options?: Options) =>
  useMutation(FunctionKey.SET_AIRDROP_SELECTION, params => setAirdropSelection(params), {
    ...options,
    onSuccess: () => {
      queryClient.invalidateQueries(FunctionKey.GET_AIRDROP);
    },
  });

export default useSetAirdropSelection;
