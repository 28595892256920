import { MutationObserverOptions, useMutation } from 'react-query';

import {
  ClaimTpiRewardInput,
  ClaimTpiRewardOutput,
  claimTpiReward,
  queryClient,
} from 'clients/api';
import { useComptrollerContract } from 'clients/contracts/hooks';
import FunctionKey from 'constants/functionKey';

type Options = MutationObserverOptions<
  ClaimTpiRewardOutput,
  Error,
  Omit<ClaimTpiRewardInput, 'comptrollerContract' | 'venusLensContract'>
>;

const useClaimTpiReward = (options?: Options) => {
  const comptrollerContract = useComptrollerContract();

  return useMutation(
    FunctionKey.CLAIM_TPI_REWARD,
    (params: Omit<ClaimTpiRewardInput, 'comptrollerContract' | 'venusLensContract'>) =>
      claimTpiReward({
        comptrollerContract,
        ...params,
      }),
    {
      ...options,
      onSuccess: async (...onSuccessParams) => {
        queryClient.resetQueries(FunctionKey.GET_TPI_REWARD);

        if (options?.onSuccess) {
          options.onSuccess(...onSuccessParams);
        }
      },
    },
  );
};

export default useClaimTpiReward;
