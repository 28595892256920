/** @jsxImportSource @emotion/react */
import { Typography } from '@mui/material';
import BigNumber from 'bignumber.js';
import {
  AccountData,
  FormikSubmitButton,
  FormikTokenTextField,
  IsolatedAssetWarning,
  SecondaryButton,
  toast,
} from 'components';
import { VError, formatVErrorToReadableString } from 'errors';
import React, { useContext } from 'react';
import { useTranslation } from 'translation';
import { Asset, Pool } from 'types';
import { formatTokensToReadableValue } from 'utilities';

import { AuthContext } from 'context/AuthContext';
import useTokenApproval from 'hooks/useTokenApproval';

import { AmountForm, AmountFormProps, ErrorCode } from 'containers/AmountForm';

import { useStyles } from '../styles';
import TEST_IDS from './testIds';

interface SupplyFormUiProps {
  asset: Asset;
  pool: Pool;
  maxInput: BigNumber;
  inputLabel: string;
  enabledButtonKey: string;
  disabledButtonKey: string;
  isTransactionLoading: boolean;
  amountValue: string;
}

export const SupplyContent: React.FC<SupplyFormUiProps> = ({
  asset,
  pool,
  maxInput,
  inputLabel,
  enabledButtonKey,
  disabledButtonKey,
  isTransactionLoading,
  amountValue,
}) => {
  const styles = useStyles();
  const { t, Trans } = useTranslation();

  const amount = new BigNumber(amountValue || 0);
  const isValidAmount = amount && !amount.isZero() && !amount.isNaN();

  const token = asset.cToken.underlyingToken;
  const spenderAddress = asset.cToken.address;

  const { account } = useContext(AuthContext);

  const {
    isTokenApprovalStatusLoading,
    approveToken,
    isApproveTokenLoading,
    allowance,
  } = useTokenApproval({
    token,
    spenderAddress,
    accountAddress: account?.address,
  });

  const isApproveEnough = allowance >= parseFloat(amountValue || '0');

  const isSubmitDisabled = !isValidAmount || isTokenApprovalStatusLoading || !isApproveEnough;

  return (
    <>
      {pool.isIsolated && (
        <IsolatedAssetWarning
          token={asset.cToken.underlyingToken}
          pool={pool}
          type="supply"
          css={styles.isolatedAssetWarning}
        />
      )}

      <FormikTokenTextField
        data-testid={TEST_IDS.valueInput}
        name="amount"
        token={asset.cToken.underlyingToken}
        disabled={isTransactionLoading}
        rightMaxButton={{
          label: t('supplyWithdraw.max').toUpperCase(),
          valueOnClick: maxInput.toFixed(),
        }}
        css={styles.input}
        // Only display error state if amount is higher than borrow limit
        displayableErrorCodes={[ErrorCode.HIGHER_THAN_MAX]}
      />

      <Typography
        component="div"
        variant="small2"
        css={[styles.greyLabel, styles.getRow({ isLast: true })]}
      >
        <Trans
          i18nKey={inputLabel}
          components={{
            White: <span css={styles.whiteLabel} />,
          }}
          values={{
            amount: formatTokensToReadableValue({
              value: maxInput,
              token: asset.cToken.underlyingToken,
            }),
          }}
        />
      </Typography>

      <AccountData amountTokens={amount} asset={asset} pool={pool} action="supply" />

      <div>
        {!isApproveEnough ? (
          <SecondaryButton
            disabled={isTokenApprovalStatusLoading || isApproveTokenLoading}
            loading={isTokenApprovalStatusLoading || isApproveTokenLoading}
            fullWidth
            onClick={approveToken}
            style={{ marginBottom: '10px' }}
          >
            {t('enableToken.enableButtonLabel')}
          </SecondaryButton>
        ) : null}

        <FormikSubmitButton
          fullWidth
          data-testid={TEST_IDS.submitButton}
          disabled={isSubmitDisabled}
          loading={isTransactionLoading}
          enabledLabel={enabledButtonKey}
          disabledLabel={disabledButtonKey}
        />
      </div>
    </>
  );
};

interface SupplyFormProps extends Omit<SupplyFormUiProps, 'amountValue'> {
  onSubmit: AmountFormProps['onSubmit'];
}

const SupplyForm: React.FC<SupplyFormProps> = ({ onSubmit, maxInput, ...props }) => {
  const onSubmitHandleError: AmountFormProps['onSubmit'] = async (value: string) => {
    try {
      await onSubmit(value);
    } catch (error) {
      let { message } = error as Error;
      if (error instanceof VError) {
        message = formatVErrorToReadableString(error);
        toast.error({
          message,
        });
      }
    }
  };

  return (
    <AmountForm onSubmit={onSubmitHandleError} maxAmount={maxInput.toFixed()}>
      {({ values }) => <SupplyContent maxInput={maxInput} amountValue={values.amount} {...props} />}
    </AmountForm>
  );
};

export default SupplyForm;
