import { restService } from 'utilities';

export enum AirdropSelectionChose {
  TPI,
  GNFT,
}

export interface SetAirdropSelectionInput {
  selection: {
    id: number;
    chose: AirdropSelectionChose;
    signature: string;
  }[];
}

export type SetAirdropSelectionOutput = {
  id: number;
  message: string;
  status: 'success' | 'fail';
}[];

const setAirdropSelection = ({
  selection,
}: SetAirdropSelectionInput): Promise<SetAirdropSelectionOutput> =>
  restService<SetAirdropSelectionOutput>({
    endpoint: '/airdrop/community-giveaway/set-selection',
    method: 'POST',
    params: {
      selection,
    },
  }) as any;

export default setAirdropSelection;
