import { css } from '@emotion/react';

export const useStyles = () => ({
  airdropContainer: css`
    max-width: 488px;
    margin: 0 auto;
  `,
  title: css`
    margin-bottom: 20px;
  `,
  airdropPaper: css`
    display: flex;
    align-items: flex-start;
    gap: 10px;

    & + & {
      margin-top: 10px;
    }
  `,
  airdropNumber: css`
    color: rgba(255,255,255, .5);
  `,
  airdropItem: css`
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  `,
  airdropItemInfo: css`
    display: flex;
    gap: 20px;
  `,
  airdropItemIcon: css`
    width: 32px;
    height: 32px;

    & > svg,
    & > img {
      width: 100%;
      height: 100%;
    }
  `,
  airdropItemUsed: css`
    background: #0D0E0F !important;
    border-color: #0D0E0F !important;
  `,
  airdropItemCaption: css`
    font-size: 14px;
    color: #8D9196;
  `,
});
