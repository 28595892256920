import { Layout, ResetScrollOnRouteChange } from 'components';
import React from 'react';
import { QueryClientProvider } from 'react-query';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import 'assets/styles/App.scss';
import { queryClient } from 'clients/api';
import { Web3Wrapper } from 'clients/web3';
import { routes } from 'constants/routing';
import { AuthProvider } from 'context/AuthContext';
import { SuccessfulTransactionModalProvider } from 'context/SuccessfulTransactionModalContext';
import Account from 'pages/Account';
import Dashboard from 'pages/Dashboard';
import History from 'pages/History';
import Pool from 'pages/Pool';
import Market from 'pages/Market';
import GNFT from 'pages/GNFT';
import TPI from 'pages/TPI';

import { MuiThemeProvider } from 'theme/MuiThemeProvider';
import Statistic from './Statistic';
import Airdrop from './Airdrop';
import EasterEggs from './EasterEggs';

const App = () => (
  <Web3Wrapper>
    <QueryClientProvider client={queryClient}>
      <MuiThemeProvider>
        <AuthProvider>
          <SuccessfulTransactionModalProvider>
            <BrowserRouter>
              <ToastContainer />

              <Layout>
                <ResetScrollOnRouteChange />

                <Switch>
                  <Route exact path={routes.dashboard.path} component={Dashboard} />

                  <Route exact path={routes.account.path} component={Account} />

                  <Route exact path={routes.markets.path} component={Pool} />

                  <Route exact path={routes.market.path} component={Market} />

                  <Route exact path={routes.history.path} component={History} />

                  <Route exact path={routes.gnft.path} component={GNFT} />

                  <Route exact path={routes.tpi.path} component={TPI} />

                  <Route exact path={routes.statistics.path} component={Statistic} />

                  <Route exact path={routes.airdrop.path} component={Airdrop} />

                  <Route exact path={routes.easterEggs.path} component={EasterEggs} />

                  <Redirect to={routes.dashboard.path} />
                </Switch>
              </Layout>
            </BrowserRouter>
          </SuccessfulTransactionModalProvider>
        </AuthProvider>
      </MuiThemeProvider>
    </QueryClientProvider>
  </Web3Wrapper>
);

export default App;
