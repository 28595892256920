import { VError } from 'errors';

import { GnftStatistc } from 'types';

import { restService } from 'utilities';

export interface GetTokenStatisticsResponse {
  topazAmount: number;
  finishedTopazAmount: number;
  usedTopazAmount: number;
  emeraldAmount: number;
  finishedEmeraldAmount: number;
  usedEmeraldAmount: number;
  diamondAmount: number;
  finishedDiamondAmount: number;
  usedDiamondAmount: number;
}

const getGnftStatistic = async (): Promise<GnftStatistc> => {
  try {
    const response = await restService<GetTokenStatisticsResponse>({
      endpoint: '/token-statistics',
      method: 'GET',
    });

    const payload = response.data?.data;

    if (!payload) {
      throw new VError({ type: 'unexpected', code: 'somethingWentWrongRetrievingTransactions' });
    }

    const {
      topazAmount,
      finishedTopazAmount,
      usedTopazAmount,
      emeraldAmount,
      finishedEmeraldAmount,
      usedEmeraldAmount,
      diamondAmount,
      finishedDiamondAmount,
      usedDiamondAmount,
    } = payload;
    return {
      total: topazAmount + emeraldAmount + diamondAmount,
      opened: finishedTopazAmount + finishedEmeraldAmount + finishedDiamondAmount,
      used: usedTopazAmount + usedEmeraldAmount + usedDiamondAmount,
      emerald: {
        total: emeraldAmount,
        opened: finishedEmeraldAmount,
        used: usedEmeraldAmount,
      },
      topaz: {
        total: topazAmount,
        opened: finishedTopazAmount,
        used: usedTopazAmount,
      },
      diamond: {
        total: diamondAmount,
        opened: finishedDiamondAmount,
        used: usedDiamondAmount,
      },
    };
  } catch (e) {
    console.error(e);
    return {
      total: 0,
      opened: 0,
      used: 0,
      emerald: {
        total: 0,
        opened: 0,
        used: 0,
      },
      diamond: {
        total: 0,
        opened: 0,
        used: 0,
      },
      topaz: {
        total: 0,
        opened: 0,
        used: 0,
      },
    };
  }
};

export default getGnftStatistic;
