import { GnftTokenType } from 'types';

const gnftInfo = {
  [GnftTokenType.TOPAZ]: {
    key: 'topaz',
    title: 'Topaz',
    image: '/jewelery/topaz.png',
  },
  [GnftTokenType.EMERALD]: {
    key: 'emerald',
    title: 'Emerald',
    image: '/jewelery/emerald.png',
  },
  [GnftTokenType.DIAMOND]: {
    key: 'diamond',
    title: 'Diamond',
    image: '/jewelery/diamond.png',
  },
};

export default gnftInfo;
