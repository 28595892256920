import BigNumber from 'bignumber.js';
import { checkForTokenTransactionError } from 'errors';
import { CToken } from 'types';
import Web3 from 'web3';
import type { TransactionReceipt } from 'web3-core';

import { getCTokenContract } from 'clients/contracts';
import { CErc20 } from 'types/contracts';

export interface SupplyInput {
  cToken: CToken;
  web3: Web3;
  accountAddress: string;
  amountWei: BigNumber;
}

export type SupplyOutput = TransactionReceipt;

const supply = async ({
  web3,
  cToken,
  accountAddress,
  amountWei,
}: SupplyInput): Promise<SupplyOutput> => {
  const tokenContract = getCTokenContract(cToken, web3) as CErc20;
  const resp = await tokenContract.methods.mint(amountWei.toFixed()).send({ from: accountAddress });

  return checkForTokenTransactionError(resp);
};

export default supply;
