import { QueryObserverOptions, useQuery } from 'react-query';

import getCTokenBalancesAll, {
  GetCTokenBalancesAllInput,
  IGetCTokenBalancesAllOutput,
} from 'clients/api/queries/getCTokenBalancesAll';
import { useTonpoundLensContract } from 'clients/contracts/hooks';
import { DEFAULT_REFETCH_INTERVAL_MS } from 'constants/defaultRefetchInterval';
import FunctionKey from 'constants/functionKey';

type Options = QueryObserverOptions<
  IGetCTokenBalancesAllOutput,
  Error,
  IGetCTokenBalancesAllOutput,
  IGetCTokenBalancesAllOutput,
  [FunctionKey.GET_C_TOKEN_BALANCES_ALL, Omit<GetCTokenBalancesAllInput, 'tonpoundLensContract'>]
>;

const useGetCTokenBalancesAll = (
  { account, cTokenAddresses }: Omit<GetCTokenBalancesAllInput, 'tonpoundLensContract'>,
  options?: Options,
) => {
  const tonpoundLensContract = useTonpoundLensContract();
  return useQuery(
    [FunctionKey.GET_C_TOKEN_BALANCES_ALL, { account, cTokenAddresses }],
    () => getCTokenBalancesAll({ tonpoundLensContract, account, cTokenAddresses }),
    {
      refetchInterval: DEFAULT_REFETCH_INTERVAL_MS,
      ...options,
    },
  );
};

export default useGetCTokenBalancesAll;
