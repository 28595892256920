/** @jsxImportSource @emotion/react */
import BigNumber from 'bignumber.js';
import {
  AccountData,
  ConnectWallet,
  EnableToken,
  LabeledInlineContent,
  NoticeWarning,
  PrimaryButton,
  Spinner,
  SecondaryButton,
  TertiaryButton,
  TokenTextField,
  toast,
} from 'components';
import { VError, formatVErrorToReadableString } from 'errors';
import React, { useContext } from 'react';
import { useTranslation } from 'translation';
import { Asset, CToken, Pool } from 'types';
import {
  areTokensEqual,
  convertTokensToWei,
  formatToReadablePercentage,
  formatTokensToReadableValue,
} from 'utilities';

import { useGetPool, useRepay } from 'clients/api';
import { AmountForm, AmountFormProps, ErrorCode } from 'containers/AmountForm';
import { AuthContext } from 'context/AuthContext';
import useAssetInfo from 'hooks/useAssetInfo';
import useTokenApproval from 'hooks/useTokenApproval';
import useSuccessfulTransactionModal from 'hooks/useSuccessfulTransactionModal';
import useDataLayer from 'hooks/useDataLayer';

import { useStyles as useSharedStyles } from '../styles';
import { useStyles } from './styles';
import TEST_IDS from './testIds';

// TODO: add stories

export const PRESET_PERCENTAGES = [25, 50, 75, 100];

export interface RepayFormProps {
  repay: (amountWei: BigNumber) => Promise<string | undefined>;
  isRepayLoading: boolean;
  limitTokens: string;
  asset: Asset;
  pool: Pool;
}

export const RepayForm: React.FC<RepayFormProps> = ({
  asset,
  pool,
  repay,
  isRepayLoading,
  limitTokens,
}) => {
  const { t, Trans } = useTranslation();

  const sharedStyles = useSharedStyles();
  const styles = useStyles();

  const { openSuccessfulTransactionModal } = useSuccessfulTransactionModal();

  const getTokenBorrowBalancePercentageTokens = React.useCallback(
    (percentage: number) =>
      asset.userBorrowBalanceTokens
        .multipliedBy(percentage / 100)
        .decimalPlaces(asset.cToken.underlyingToken.decimals)
        .toFixed(),
    [asset.userBorrowBalanceTokens, asset.cToken.underlyingToken.decimals],
  );

  const readableTokenBorrowBalance = React.useMemo(
    () =>
      formatTokensToReadableValue({
        value: asset.userBorrowBalanceTokens,
        token: asset.cToken.underlyingToken,
      }),
    [asset.userBorrowBalanceTokens, asset.cToken.underlyingToken],
  );

  const readableTokenWalletBalance = React.useMemo(
    () =>
      formatTokensToReadableValue({
        value: asset.userWalletBalanceTokens,
        token: asset.cToken.underlyingToken,
      }),
    [asset.userWalletBalanceTokens, asset.cToken.underlyingToken],
  );

  const onSubmit: AmountFormProps['onSubmit'] = async amountTokens => {
    const formattedAmountTokens = new BigNumber(amountTokens);

    const amountWei = convertTokensToWei({
      value: formattedAmountTokens,
      token: asset.cToken.underlyingToken,
    });

    try {
      // Send request to repay tokens
      const transactionHash = await repay(amountWei);
      if (transactionHash) {
        // Display successful transaction modal
        openSuccessfulTransactionModal({
          title: t('borrowRepayModal.repay.successfulTransactionModal.title'),
          content: t('borrowRepayModal.repay.successfulTransactionModal.message'),
          amount: {
            valueWei: amountWei,
            token: asset.cToken.underlyingToken,
          },
          transactionHash,
        });
      }
    } catch (error) {
      let { message } = error as Error;
      if (error instanceof VError) {
        message = formatVErrorToReadableString(error);
      }
      toast.error({
        message,
      });
    }
  };

  const shouldDisplayFullRepaymentWarning = React.useCallback(
    (repayAmountTokens: string) =>
      repayAmountTokens !== '0' && asset.userBorrowBalanceTokens.eq(repayAmountTokens),
    [asset.cToken.underlyingToken, asset.userBorrowBalanceTokens],
  );

  const token = asset.cToken.underlyingToken;
  const spenderAddress = asset.cToken.address;

  const { account } = useContext(AuthContext);

  const {
    isTokenApprovalStatusLoading,
    approveToken,
    isApproveTokenLoading,
    allowance,
  } = useTokenApproval({
    token,
    spenderAddress,
    accountAddress: account?.address,
  });

  return (
    <AmountForm onSubmit={onSubmit} maxAmount={limitTokens}>
      {({ values, setFieldValue, handleBlur, dirty, isValid, errors }) => {
        const amountValue = values.amount;
        const isApproveEnough = allowance >= parseFloat(amountValue || '0');

        const isSubmitDisabled = isRepayLoading || !isApproveEnough;
        return (
          <>
            <LabeledInlineContent
              css={sharedStyles.getRow({ isLast: true })}
              label={t('borrowRepayModal.repay.currentlyBorrowing')}
            >
              {readableTokenBorrowBalance}
            </LabeledInlineContent>

            <div css={[sharedStyles.getRow({ isLast: false })]}>
              <TokenTextField
                name="amount"
                token={asset.cToken.underlyingToken}
                value={values.amount}
                onChange={amount => setFieldValue('amount', amount, true)}
                disabled={isRepayLoading}
                onBlur={handleBlur}
                rightMaxButton={{
                  label: t('borrowRepayModal.repay.rightMaxButtonLabel'),
                  valueOnClick: limitTokens,
                }}
                data-testid={TEST_IDS.tokenTextField}
                // Only display error state if amount is higher than limit
                hasError={errors.amount === ErrorCode.HIGHER_THAN_MAX}
                description={
                  <Trans
                    i18nKey="borrowRepayModal.repay.walletBalance"
                    components={{
                      White: <span css={sharedStyles.whiteLabel} />,
                    }}
                    values={{ balance: readableTokenWalletBalance }}
                  />
                }
              />
            </div>

            <div css={[sharedStyles.getRow({ isLast: true })]}>
              <div css={styles.selectButtonsContainer}>
                {PRESET_PERCENTAGES.map(percentage => (
                  <TertiaryButton
                    key={`select-button-${percentage}`}
                    css={styles.selectButton}
                    onClick={() =>
                      setFieldValue(
                        'amount',
                        getTokenBorrowBalancePercentageTokens(percentage),
                        true,
                      )
                    }
                  >
                    {formatToReadablePercentage(percentage)}
                  </TertiaryButton>
                ))}
              </div>

              {shouldDisplayFullRepaymentWarning(values.amount) && (
                <NoticeWarning
                  css={sharedStyles.notice}
                  description={t('borrowRepayModal.repay.fullRepaymentWarning')}
                />
              )}
            </div>

            <AccountData
              asset={asset}
              pool={pool}
              amountTokens={new BigNumber(values.amount || 0)}
              action="repay"
            />

            <div>
              {!isApproveEnough ? (
                <SecondaryButton
                  disabled={isTokenApprovalStatusLoading || isApproveTokenLoading}
                  loading={isTokenApprovalStatusLoading || isApproveTokenLoading}
                  fullWidth
                  onClick={approveToken}
                  style={{ marginBottom: '10px' }}
                >
                  {t('enableToken.enableButtonLabel')}
                </SecondaryButton>
              ) : null}

              <PrimaryButton
                type="submit"
                loading={isRepayLoading}
                disabled={isSubmitDisabled || !isValid || !dirty}
                fullWidth
              >
                {dirty && isValid
                  ? t('borrowRepayModal.repay.submitButton')
                  : t('borrowRepayModal.repay.submitButtonDisabled')}
              </PrimaryButton>
            </div>
          </>
        );
      }}
    </AmountForm>
  );
};

export interface RepayProps {
  cToken: CToken;
  poolComptrollerAddress: string;
  onClose: () => void;
}

const Repay: React.FC<RepayProps> = ({ cToken, poolComptrollerAddress, onClose }) => {
  const { t } = useTranslation();
  const { account: { address: accountAddress = '' } = {} } = React.useContext(AuthContext);
  const dataLayer = useDataLayer();

  const { data: getPoolData } = useGetPool({
    poolComptrollerAddress,
    accountAddress,
  });
  const pool = getPoolData?.pool;
  const asset = pool?.assets.find(item => areTokensEqual(item.cToken, cToken));

  const limitTokens = React.useMemo(
    () =>
      asset
        ? BigNumber.min(asset.userBorrowBalanceTokens, asset.userWalletBalanceTokens)
        : new BigNumber(0),
    [asset?.userBorrowBalanceTokens, asset?.userWalletBalanceTokens],
  );

  const { mutateAsync: repay, isLoading: isRepayLoading } = useRepay({
    cToken,
  });

  const assetInfo = useAssetInfo({
    asset,
    type: 'borrow',
  });

  const handleRepay: RepayFormProps['repay'] = async amountWei => {
    if (!accountAddress) {
      throw new VError({ type: 'unexpected', code: 'walletNotConnected' });
    }

    const isRepayingFullLoan = amountWei.eq(
      convertTokensToWei({
        value: asset!.userBorrowBalanceTokens,
        token: asset!.cToken.underlyingToken,
      }),
    );

    const args = {
      event: 'tonpound_event',
      event_context: 'repay_start',
      event_content: cToken.underlyingToken.symbol,
      event_auth: accountAddress,
    };
    dataLayer.push(args);

    const res = await repay({
      amountWei,
      accountAddress,
      isRepayingFullLoan,
    });

    if (asset) {
      const { decimals } = asset.cToken.underlyingToken;

      const value = amountWei.div(new BigNumber(10).pow(decimals));
      dataLayer.push({
        ...args,
        event_context: 'repay_success',
        event_value: (asset.tokenPriceDollars || new BigNumber('0'))
          .multipliedBy(value)
          .toFixed(0),
      });
    }

    // Close modal on success
    onClose();

    return res.transactionHash;
  };

  return (
    <ConnectWallet message={t('borrowRepayModal.repay.connectWalletMessage')}>
      {asset && pool ? (
        <EnableToken
          token={cToken.underlyingToken}
          spenderAddress={cToken.address}
          title={t('borrowRepayModal.repay.enableToken.title', {
            symbol: cToken.underlyingToken.symbol,
          })}
          assetInfo={assetInfo}
        >
          <RepayForm
            asset={asset}
            pool={pool}
            repay={handleRepay}
            isRepayLoading={isRepayLoading}
            limitTokens={limitTokens.toFixed()}
          />
        </EnableToken>
      ) : (
        <Spinner />
      )}
    </ConnectWallet>
  );
};

export default Repay;
