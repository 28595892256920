import { useMemo } from 'react';
import { CToken, Token } from 'types';

import { useWeb3 } from 'clients/web3';

import {
  getCTokenContract,
  getComptrollerContract,
  getInterestModelContract,
  getPriceOracleContract,
  getTokenContract,
  getTokenContractByAddress,
  getTonpoundLensContract,
  getGNFTContract,
  getGNFTSegmentManagerContract,
  getTpiViewerContract,
  getTreasuryViewContract,
  getTpiAirdropContract,
} from './getters';

export const useTokenContract = (token: Token) => {
  const web3 = useWeb3();
  return useMemo(() => getTokenContract(token, web3), [web3, token]);
};

export const useTokenContractByAddress = (address: string) => {
  const web3 = useWeb3();
  return useMemo(() => getTokenContractByAddress(address, web3), [web3, address]);
};

export const useCTokenContract = (cToken: CToken) => {
  const web3 = useWeb3();
  return useMemo(() => getCTokenContract(cToken, web3), [web3, cToken]);
};

export const useComptrollerContract = () => {
  const web3 = useWeb3();
  return useMemo(() => getComptrollerContract(web3), [web3]);
};

export const usePriceOracleContract = () => {
  const web3 = useWeb3();
  return useMemo(() => getPriceOracleContract(web3), [web3]);
};

export const useInterestModelContract = (address: string) => {
  const web3 = useWeb3();
  return useMemo(() => getInterestModelContract(address, web3), [web3]);
};

export const useTonpoundLensContract = () => {
  const web3 = useWeb3();
  return useMemo(() => getTonpoundLensContract(web3), [web3]);
};

export const useGnftContract = () => {
  const web3 = useWeb3();
  return useMemo(() => getGNFTContract(web3), [web3]);
};

export const useGnftSegmentManagerContract = () => {
  const web3 = useWeb3();
  return useMemo(() => getGNFTSegmentManagerContract(web3), [web3]);
};

export const useTpiViewerContract = () => {
  const web3 = useWeb3();

  return useMemo(() => getTpiViewerContract(web3), [web3]);
};

export const useTreasuryViewContract = () => {
  const web3 = useWeb3();

  return useMemo(() => getTreasuryViewContract(web3), [web3]);
};

export const useTpiAirdropContract = () => {
  const web3 = useWeb3();

  return useMemo(() => getTpiAirdropContract(web3), [web3]);
};
