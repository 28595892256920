import BigNumber from 'bignumber.js';
import { GnftSegmentManager } from 'types/contracts';

export interface GetGnftSegmentsActivationPriceInput {
  gnftSegmentManagerContract: GnftSegmentManager;
  tokenId: string;
  segmentsToOpen: number;
  discounted?: boolean;
}

export type GetGnftSegmentsActivationPriceOutput = {
  priceWei: BigNumber;
};

const getGnftSegmentsActivationPrice = async ({
  gnftSegmentManagerContract,
  tokenId,
  segmentsToOpen,
  discounted = false,
}: GetGnftSegmentsActivationPriceInput): Promise<GetGnftSegmentsActivationPriceOutput> => {
  const priceWei = await gnftSegmentManagerContract.methods
    .getActivationPrice(tokenId, segmentsToOpen, discounted)
    .call();

  return {
    priceWei: new BigNumber(priceWei),
  };
};

export default getGnftSegmentsActivationPrice;
