/** @jsxImportSource @emotion/react */
import BigNumber from 'bignumber.js';
import { ConnectWallet, ModalProps, Spinner } from 'components';
import React, { useContext } from 'react';
import { useTranslation } from 'translation';
import { Asset, CToken, Pool } from 'types';
import { areTokensEqual, convertTokensToWei } from 'utilities';

import { useGetCTokenBalanceOf, useGetPool, useRedeem, useRedeemUnderlying } from 'clients/api';
import { AmountFormProps } from 'containers/AmountForm';
import { AuthContext } from 'context/AuthContext';
import useSuccessfulTransactionModal from 'hooks/useSuccessfulTransactionModal';
import useDataLayer from 'hooks/useDataLayer';

import { useStyles } from '../styles';
import WithdrawForm from './form';

export interface WithdrawProps {
  onClose: ModalProps['handleClose'];
  cToken: CToken;
  poolComptrollerAddress: string;
}

export interface WithdrawUiProps extends Omit<WithdrawProps, 'cToken' | 'poolComptrollerAddress'> {
  onSubmit: AmountFormProps['onSubmit'];
  isLoading: boolean;
  className?: string;
  asset?: Asset;
  pool?: Pool;
}

export const WithdrawUi: React.FC<WithdrawUiProps> = ({
  className,
  asset,
  pool,
  onSubmit,
  isLoading,
}) => {
  const styles = useStyles();

  const { t } = useTranslation();

  const maxInput = React.useMemo(() => {
    if (
      !asset ||
      pool?.userBorrowBalanceCents === undefined ||
      pool?.userBorrowLimitCents === undefined
    ) {
      return new BigNumber(0);
    }

    let maxInputTokens;

    // If asset isn't used as collateral user can withdraw the entire supply
    // balance without affecting their borrow limit
    if (!asset.isCollateralOfUser) {
      maxInputTokens = asset.userSupplyBalanceTokens;
    } else {
      // Calculate how much token user can withdraw before they risk getting
      // liquidated (if their borrow balance goes above their borrow limit)

      // Return 0 if borrow limit has already been reached
      if (pool.userBorrowBalanceCents > pool.userBorrowLimitCents) {
        return new BigNumber(0);
      }

      const marginWithBorrowLimitDollars =
        (pool.userBorrowLimitCents - pool.userBorrowBalanceCents) / 100;

      const collateralAmountPerTokenDollars = asset.tokenPriceDollars.multipliedBy(
        asset.collateralFactor,
      );
      const maxTokensBeforeLiquidation = new BigNumber(marginWithBorrowLimitDollars)
        .dividedBy(collateralAmountPerTokenDollars)
        .dp(asset.cToken.underlyingToken.decimals, BigNumber.ROUND_DOWN);

      maxInputTokens = BigNumber.minimum(maxTokensBeforeLiquidation, asset.userSupplyBalanceTokens);
    }

    return maxInputTokens;
  }, [asset, pool]);

  if (!asset) {
    return <></>;
  }

  return (
    <div className={className} css={styles.container}>
      <ConnectWallet message={t('supplyWithdraw.connectWalletToWithdraw')}>
        {asset && pool ? (
          <WithdrawForm
            key="form-withdraw"
            asset={asset}
            pool={pool}
            onSubmit={onSubmit}
            inputLabel={t('supplyWithdraw.withdrawableAmount')}
            enabledButtonKey={t('supplyWithdraw.withdraw')}
            disabledButtonKey={t('supplyWithdraw.enterValidAmountWithdraw')}
            maxInput={maxInput}
            isTransactionLoading={isLoading}
          />
        ) : (
          <Spinner />
        )}
      </ConnectWallet>
    </div>
  );
};

const WithdrawModal: React.FC<WithdrawProps> = ({ cToken, poolComptrollerAddress, onClose }) => {
  const { account: { address: accountAddress = '' } = {} } = useContext(AuthContext);

  const dataLayer = useDataLayer();

  const { data: getPoolData } = useGetPool({ poolComptrollerAddress, accountAddress });
  const pool = getPoolData?.pool;
  const asset = pool?.assets.find(item => areTokensEqual(item.cToken, cToken));

  const { t } = useTranslation();
  const { openSuccessfulTransactionModal } = useSuccessfulTransactionModal();

  const { data: getCTokenBalanceData } = useGetCTokenBalanceOf(
    {
      accountAddress,
      cToken,
    },
    {
      enabled: !!accountAddress,
    },
  );
  const cTokenBalanceWei = getCTokenBalanceData?.balanceWei;

  const { mutateAsync: redeem, isLoading: isRedeemLoading } = useRedeem({
    cToken,
    accountAddress,
  });

  const {
    mutateAsync: redeemUnderlying,
    isLoading: isRedeemUnderlyingLoading,
  } = useRedeemUnderlying({
    cToken,
    accountAddress,
  });

  const isWithdrawLoading = isRedeemLoading || isRedeemUnderlyingLoading;

  const onSubmit: AmountFormProps['onSubmit'] = async value => {
    if (!asset) {
      return;
    }

    const amount = new BigNumber(value);
    const amountEqualsSupplyBalance = amount.eq(asset.userSupplyBalanceTokens);
    let transactionHash;

    const args = {
      event: 'tonpound_event',
      event_context: 'withdraw_start',
      event_content: cToken.underlyingToken.symbol,
      event_auth: accountAddress,
    };
    dataLayer.push(args);

    if (amountEqualsSupplyBalance && cTokenBalanceWei) {
      const res = await redeem({ amountWei: cTokenBalanceWei });

      ({ transactionHash } = res);
      // Successful transaction modal will display
    }

    if (!amountEqualsSupplyBalance) {
      const withdrawAmountWei = convertTokensToWei({
        value: new BigNumber(value),
        token: asset.cToken.underlyingToken,
      });

      const res = await redeemUnderlying({
        amountWei: withdrawAmountWei,
      });

      ({ transactionHash } = res);
    }

    dataLayer.push({
      ...args,
      event_context: 'withdraw_success',
      event_value: (asset?.tokenPriceDollars || new BigNumber('0')).multipliedBy(value).toFixed(0),
    });

    onClose();

    if (transactionHash) {
      openSuccessfulTransactionModal({
        title: t('supplyWithdraw.successfulWithdrawTransactionModal.title'),
        content: t('supplyWithdraw.successfulWithdrawTransactionModal.message'),
        amount: {
          valueWei: convertTokensToWei({ value: amount, token: cToken.underlyingToken }),
          token: cToken.underlyingToken,
        },
        transactionHash,
      });
    }
  };

  return (
    <WithdrawUi
      onClose={onClose}
      asset={asset}
      pool={pool}
      onSubmit={onSubmit}
      isLoading={isWithdrawLoading}
    />
  );
};

export default WithdrawModal;
