import { CToken } from 'types';

import { CERC_TOKENS } from 'constants/tokens';

const getCTokenByAddress = (address: string) => {
  let token: CToken | undefined;

  Object.keys(CERC_TOKENS)
    .filter(key => Object.prototype.hasOwnProperty.call(CERC_TOKENS, key))
    .forEach(tokenId => {
      const currentToken = CERC_TOKENS[tokenId as keyof typeof CERC_TOKENS];
      if (currentToken?.address.toLowerCase() === address.toLowerCase()) {
        token = currentToken;
      }
    });

  return token;
};

export default getCTokenByAddress;
