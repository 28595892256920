import { ChainId } from 'types';

// export const BLOCK_TIME_MS = 3000;
export const BLOCK_TIME_MS = 15000;
// 20 blocks a minute, 60 minutes an hour, 24 hours a day
export const BLOCKS_PER_DAY = (60 / (BLOCK_TIME_MS / 1000)) * 60 * 24;

export const SCAN_URLS = {
  [ChainId.MAINNET]: 'https://etherscan.com',
  [ChainId.GOERLI]: 'https://goerli.etherscan.io',
  [ChainId.MUMBAI]: 'https://mumbai.polygonscan.com/',
};
