import BigNumber from 'bignumber.js';

import { Comptroller } from 'types/contracts';

export interface GetHypotheticalAccountLiquidityInput {
  comptrollerContract: Comptroller;
  accountAddress: string;
  cTokenAddress: string;
  cTokenBalanceOfWei: BigNumber;
  cTokenBorrowAmountWei?: BigNumber;
}

export type GetHypotheticalAccountLiquidityOutput = { 0: string; 1: string; 2: string };

const getHypotheticalAccountLiquidity = ({
  comptrollerContract,
  accountAddress,
  cTokenAddress,
  cTokenBalanceOfWei,
  cTokenBorrowAmountWei = new BigNumber(0),
}: GetHypotheticalAccountLiquidityInput): Promise<GetHypotheticalAccountLiquidityOutput> =>
  comptrollerContract.methods
    .getHypotheticalAccountLiquidity(
      accountAddress.toLowerCase(),
      cTokenAddress,
      cTokenBalanceOfWei.toFixed(),
      cTokenBorrowAmountWei.toFixed(),
    )
    .call();

export default getHypotheticalAccountLiquidity;
