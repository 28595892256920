import { MutationObserverOptions, useMutation } from 'react-query';

import { MintGnftInput, MintGnftOutput, mintGnft, queryClient } from 'clients/api';
import { useGnftSegmentManagerContract } from 'clients/contracts/hooks';
import FunctionKey from 'constants/functionKey';

type Options = MutationObserverOptions<MintGnftOutput, Error, Omit<MintGnftInput, 'gnftSegmentManagerContract'>>;

const useMintGnft = (options?: Options) => {
  const gnftSegmentManagerContract = useGnftSegmentManagerContract();

  return useMutation(
    FunctionKey.MINT_GNFT,
    (params: Omit<MintGnftInput, 'gnftSegmentManagerContract'>) =>
      mintGnft({
        gnftSegmentManagerContract,
        ...params,
      }),
    {
      ...options,
      onSuccess: async (...onSuccessParams) => {
        queryClient.resetQueries(FunctionKey.MINT_GNFT);

        if (options?.onSuccess) {
          options.onSuccess(...onSuccessParams);
        }
      },
    },
  );
};

export default useMintGnft;
