import { useMemo } from 'react';
import BigNumber from 'bignumber.js';

import { CToken, GnftTokenType } from 'types';

import { convertWeiToTokens, getCTokenByAddress, indexBy } from 'utilities';

import { CERC_TOKENS } from 'constants/tokens';

import {
  IGetCTokenBalancesAllOutput,
  useGetCTokenBalancesAll,
  useGetQuoteLiquidityForLock,
} from 'clients/api';

const cTokenAddresses = Object.values(CERC_TOKENS).reduce(
  (acc, item) => (item.address ? [...acc, item.address] : acc),
  [] as string[],
);

const useAvailableMarketsForLock = (tokenType: GnftTokenType, accountAddress: string) => {
  const {
    data: quoteLiquidityForLock,
    isLoading: isLoadingQuoteLiquidityForLock,
  } = useGetQuoteLiquidityForLock({ tokenType, markets: cTokenAddresses });

  const {
    data: cTokenBalancesAccount = { balances: [] },
    isLoading: isGetCTokenBalancesAccountLoading,
  } = useGetCTokenBalancesAll(
    { account: accountAddress || '', cTokenAddresses },
    { enabled: !!accountAddress, placeholderData: { balances: [] } },
  );

  const cTokenBalances = useMemo(
    () =>
      indexBy(
        (item: IGetCTokenBalancesAllOutput['balances'][number]) => item.cToken.toLowerCase(), // index by CToken address
        cTokenBalancesAccount.balances,
      ),
    [cTokenBalancesAccount],
  );

  const availableMarketsToLock = useMemo(() => {
    const markets: { cToken: CToken; balance: number; amountToLock: number }[] = [];
    (quoteLiquidityForLock?.quoteLiquidityForLock || []).forEach(item => {
      const cToken = getCTokenByAddress(item.cTokenAddress);
      if (!cToken) return;
      const balance = convertWeiToTokens({
        valueWei: new BigNumber(cTokenBalances[item.cTokenAddress.toLowerCase()]?.balanceOf || '0'),
        token: cToken,
      }).toNumber();
      const amountToLock = convertWeiToTokens({
        valueWei: item.amount,
        token: cToken,
      }).toNumber();

      const canSelect = balance > amountToLock;

      if (!canSelect) return;

      markets.push({
        cToken,
        balance,
        amountToLock,
      });
    });
    return markets;
  }, [quoteLiquidityForLock?.quoteLiquidityForLock, cTokenBalances]);

  return {
    data: availableMarketsToLock,
    isLoading: isLoadingQuoteLiquidityForLock || isGetCTokenBalancesAccountLoading,
  };
};

export default useAvailableMarketsForLock;
