/** @jsxImportSource @emotion/react */
/* eslint-disable react/no-array-index-key */
import { Paper, Typography } from '@mui/material';
import { Cell, Icon, Spinner } from 'components';
import React, { useContext, useMemo } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'translation';
import { Pool } from 'types';
import { formatCentsToReadableValue, getContractAddress } from 'utilities';

import { useGetPool } from 'clients/api';
import PLACEHOLDER_KEY from 'constants/placeholderKey';
import { routes } from 'constants/routing';
import { AuthContext } from 'context/AuthContext';

import StatsContainer from 'components/Stats/StatsContainer';

import Table from './Table';
import { useStyles } from './styles';


export interface PoolUiProps {
  pool?: Pool;
}

export const PoolUi: React.FC<PoolUiProps> = ({ pool }) => {
  const styles = useStyles();
  const { t } = useTranslation();

  const cells: Cell[] = useMemo(() => {
    const { totalSupplyCents, totalBorrowCents } = (pool?.assets || []).reduce(
      (acc, item) => ({
        totalSupplyCents: acc.totalSupplyCents + item.supplyBalanceCents,
        totalBorrowCents: acc.totalBorrowCents + item.borrowBalanceCents,
      }),
      {
        totalSupplyCents: 0,
        totalBorrowCents: 0,
      },
    );

    return [
      {
        label: t('pool.header.totalSupplyLabel'),
        value: formatCentsToReadableValue({
          value: totalSupplyCents,
        }),
      },
      {
        label: t('pool.header.totalBorrowLabel'),
        value: formatCentsToReadableValue({
          value: totalBorrowCents,
        }),
      },
      {
        label: t('pool.header.availableLiquidityLabel'),
        value: formatCentsToReadableValue({
          value: totalSupplyCents - totalBorrowCents,
        }),
      },
      {
        label: t('pool.header.assetsLabel'),
        value: pool?.assets.length || PLACEHOLDER_KEY,
      },
    ];
  }, [pool]);

  return pool ? (
    <>
      <div css={styles.header}>
        <StatsContainer cells={cells} />
      </div>

      {pool.isIsolated && (
        <div css={styles.banner}>
          <div css={styles.bannerContent}>
            <Icon name="attention" css={styles.bannerIcon} />

            <Typography variant="small2" css={styles.bannerText}>
              {t('pool.bannerText')}
            </Typography>
          </div>
        </div>
      )}
      <Paper css={styles.tableWrapper}>
        <Table pool={pool} />
      </Paper>
    </>
  ) : (
    <Spinner />
  );
};

export type PoolPageProps = RouteComponentProps;

const PoolPage: React.FC<PoolPageProps> = () => {
  const { account } = useContext(AuthContext);

  const { data: getPoolData, isLoading: isGetPoolLoading } = useGetPool({
    accountAddress: account?.address,
    poolComptrollerAddress: getContractAddress('comptroller'),
  });

  // Redirect to Pools page if pool Comptroller address is incorrect
  if (!isGetPoolLoading && !getPoolData?.pool) {
    <Redirect to={routes.dashboard.path} />;
  }

  return <PoolUi pool={getPoolData?.pool} />;
};

export default PoolPage;
