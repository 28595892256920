import { ChainId } from 'types';

export const API_ENDPOINT_URLS = {
  [ChainId.MAINNET]: 'https://app.tonpound.com/api',
  [ChainId.GOERLI]: 'https://testnet.tonpound.com/api',
  [ChainId.MUMBAI]: 'https://testnet.tonpound.com/api',
};

export const RPC_URLS: {
  [key: string]: string[];
} = {
  [ChainId.MAINNET]: [
    'https://eth.llamarpc.com',
    'https://virginia.rpc.blxrbdn.com',
    'https://1rpc.io/eth',
    'https://uk.rpc.blxrbdn.com',
    'https://rpc.mevblocker.io',
    // 'https://eth-rpc.gateway.pokt.network',
    // 'https://rpc.builder0x69.io',
  ],
  [ChainId.GOERLI]: [
    'https://rpc.ankr.com/eth_goerli',
    'https://goerli.blockpi.network/v1/rpc/public',
    // 'https://endpoints.omniatech.io/v1/eth/goerli/public',
  ],
  [ChainId.MUMBAI]: [
    'https://rpc-mumbai.maticvigil.com',
    'https://rpc.ankr.com/polygon_mumbai',
    'https://endpoints.omniatech.io/v1/matic/mumbai/public',
  ],
};
