import { ChainId, Token } from 'types';

import btcb from 'assets/img/tokens/btcb.svg';
import eth from 'assets/img/tokens/eth.svg';
import usdc from 'assets/img/tokens/usdc.svg';
import usdt from 'assets/img/tokens/usdt.svg';
import pTon from 'assets/img/tokens/pTon.svg';
import tpi from 'assets/img/tokens/tpi.svg';
import dai from 'assets/img/tokens/dai.svg';

import TOKEN_ADDRESSES from '../../contracts/addresses/tokens.json';

export const TESTNET_TOKENS = {
  usdc: {
    id: 'usdc',
    symbol: 'USDC',
    decimals: 6,
    address: TOKEN_ADDRESSES.usdc[ChainId.MUMBAI],
    asset: usdc,
  } as Token,
  usdt: {
    id: 'usdt',
    symbol: 'USDT',
    decimals: 6,
    address: TOKEN_ADDRESSES.usdt[ChainId.MUMBAI],
    asset: usdt,
  } as Token,
  dai: {
    id: 'dai',
    symbol: 'DAI',
    decimals: 18,
    address: TOKEN_ADDRESSES.dai[ChainId.MUMBAI],
    asset: dai,
  } as Token,
  wbtc: {
    id: 'wbtc',
    symbol: 'wBTC',
    decimals: 8,
    address: TOKEN_ADDRESSES.wbtc[ChainId.MUMBAI],
    asset: btcb,
  } as Token,
  weth: {
    id: 'weth',
    symbol: 'wETH',
    decimals: 18,
    address: TOKEN_ADDRESSES.weth[ChainId.MUMBAI],
    asset: eth,
  } as Token,
  pton: {
    id: 'pton',
    symbol: 'pTON',
    decimals: 9,
    address: TOKEN_ADDRESSES.pton[ChainId.MUMBAI],
    asset: pTon,
  },
  tpi: {
    id: 'tpi',
    symbol: 'TPI',
    decimals: 18,
    address: TOKEN_ADDRESSES.tpi[ChainId.MUMBAI],
    asset: tpi,
  } as Token,
};
