/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react';

import { CToken } from 'types';

import { Select, TokenIcon } from 'components';

import { useStyles } from './styles';

type LockMarketSelectProps = {
  value: string;
  onChange: (e: any) => void;
  markets: {
    cToken: CToken;
    balance: any;
    amountToLock: number;
  }[];
};

const LockMarketSelect: React.FC<LockMarketSelectProps> = ({ value, onChange, markets }) => {
  const styles = useStyles();
  const selectedMarket = markets.find(market => market.cToken.address === value);
  const options = useMemo(
    () =>
      markets.map(market => ({
        label: (
          <div css={styles.option}>
            {market.amountToLock.toString()}
            <div css={styles.token}>
              <TokenIcon token={market.cToken.underlyingToken} /> {market.cToken.symbol}
            </div>
          </div>
        ),
        value: market.cToken.address,
      })),
    [markets],
  );
  return (
    <div css={styles.marketSelectWrapper}>
      <div css={styles.marketSelectLabel}>
        <div>Select tToken to lock</div>
        <div css={styles.marketSelectBalance}>
          <span>Balance:</span> {selectedMarket?.balance || 0} {selectedMarket?.cToken.symbol || ''}
        </div>
      </div>
      <Select ariaLabel="Select tToken" value={value} options={options} onChange={onChange} />
    </div>
  );
};

export default LockMarketSelect;
