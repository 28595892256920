/** @jsxImportSource @emotion/react */
import React from 'react';

import { Cell } from 'components/CellGroup';

import { useStyles } from './styles';

type StatsItemProps = Cell;

const StatsItem: React.FC<StatsItemProps> = ({ label, value }) => {
  const styles = useStyles();
  return (
    <div css={styles.statsItem}>
      <div css={styles.statsItemValue}>{value}</div>
      <div css={styles.statsItemTitle}>{label}</div>
    </div>
  );
};

export default StatsItem;
