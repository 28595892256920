/** @jsxImportSource @emotion/react */
import { Paper, Typography } from '@mui/material';
import BigNumber from 'bignumber.js';
import { EllipseAddress, Icon, LabeledProgressBar, TokenIcon } from 'components';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'translation';
import {
  convertWeiToTokens,
  formatTokensToReadableValue,
  generateScanUrl,
  getContractAddress,
} from 'utilities';

import { useGetBalanceOf } from 'clients/api';
import useGetUserMarketInfo from 'clients/api/queries/useGetMarketInfo';

import { TOKENS } from 'constants/tokens';
import { AuthContext } from 'context/AuthContext';
import useCopyToClipboard from 'hooks/useCopyToClipboard';

import { MINTED_TPI_WEI } from '../constants';
import { useStyles } from '../styles';

interface HeaderProps {
  className?: string;
}

interface HeaderContainerProps {
  remainingDistributionWei: BigNumber;
  dailyTpiWei: BigNumber;
}

export const HeaderUi: React.FC<HeaderProps & HeaderContainerProps> = ({
  className,
  remainingDistributionWei,
  dailyTpiWei,
}) => {
  const styles = useStyles();
  const { t } = useTranslation();

  const totalTpiDistributedWei = MINTED_TPI_WEI.minus(remainingDistributionWei);

  const copy = useCopyToClipboard(t('interactive.copy.tpiAddress'));
  const copyAddress = () => copy(TOKENS.tpi.address);

  const readableDailyDistribution = useMemo(() => {
    const dailyTpiTokens = convertWeiToTokens({
      valueWei: dailyTpiWei,
      token: TOKENS.tpi,
    });

    return formatTokensToReadableValue({
      value: dailyTpiTokens,
      token: TOKENS.tpi,
      minimizeDecimals: true,
    });
  }, [dailyTpiWei.toFixed()]);

  const readableRemainingDistribution = useMemo(
    () =>
      convertWeiToTokens({
        valueWei: remainingDistributionWei,
        token: TOKENS.tpi,
        returnInReadableFormat: true,
        minimizeDecimals: true,
      }),
    [remainingDistributionWei.toFixed()],
  );

  const percentOfTpiDistributed = useMemo(
    () => totalTpiDistributedWei.dividedBy(MINTED_TPI_WEI).multipliedBy(100).toNumber(),
    [totalTpiDistributedWei],
  );

  return (
    <Paper className={className} css={styles.headerRoot}>
      <div css={styles.addressContainer}>
        <div css={styles.xvsIconContainer}>
          <TokenIcon token={TOKENS.tpi} css={styles.icon} />
        </div>

        <Typography
          href={generateScanUrl('tpi', 'token')}
          target="_blank"
          rel="noreferrer"
          variant="small2"
          component="a"
          css={[styles.whiteText, styles.addressText]}
        >
          <EllipseAddress address={TOKENS.tpi.address} ellipseBreakpoint="xl" />
        </Typography>

        <div css={styles.copyIconContainer}>
          <Icon name="copy" onClick={copyAddress} css={styles.copyIcon} size={styles.iconSizeXl} />
        </div>
      </div>

      <div css={styles.slider}>
        <LabeledProgressBar
          css={styles.progressBar}
          min={1}
          max={100}
          step={1}
          value={percentOfTpiDistributed}
          ariaLabel={t('tpi.progressBar')}
          greyLeftText={t('tpi.dailyDistribution')}
          whiteLeftText={readableDailyDistribution}
          greyRightText={t('tpi.remaining')}
          whiteRightText={readableRemainingDistribution}
        />
      </div>
    </Paper>
  );
};

const Header: React.FC<HeaderProps> = ({ className }) => {
  const { account } = useContext(AuthContext);
  const {
    data: { dailyTpiWei },
  } = useGetUserMarketInfo({
    accountAddress: account?.address,
  });
  const { data: tpiRemainingDistributionData } = useGetBalanceOf({
    token: TOKENS.tpi,
    accountAddress: getContractAddress('comptroller'),
  });

  return (
    <HeaderUi
      remainingDistributionWei={tpiRemainingDistributionData?.balanceWei || new BigNumber(0)}
      className={className}
      dailyTpiWei={dailyTpiWei}
    />
  );
};

export default Header;
