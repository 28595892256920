import { css } from '@emotion/react';

export const useStyles = () => ({
  counterWrapper: css`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    padding: 12px;
    border: 1px solid transparent;
    color: #fff;
    background-color: #101112;
    border-color: #31363D;
    position: relative;
    font-size: 0;
    height: 50px;

    &:hover {
        color: #00A6FF;
    }
  `,
  counterWrapperSmall: css`
    height: 40px;
  `,
  counter: css`
    position: absolute;
    font-size: 10px;
    border-radius: 50%;
    padding: 1px;
    background: #f00;
    width: 15px;
    height: 15px;
    top: 8px;
    right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  counterEmpty: css`
    background: #777;
  `,
  counterIcon: css`
    color: inherit;
    width: 24px;
    height: 24px;
  `,
});
