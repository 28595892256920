import { checkForTpiAirdropTransactionError } from 'errors';
import type { TransactionReceipt } from 'web3-core/types';

import { TpiAirdrop } from 'types/contracts';
import { EasterEggData } from 'clients/api/queries/getEasterEggs';

export interface ClaimEasterEggInput {
  tpiAirdropContract: TpiAirdrop;
  fromAccountAddress: string;
  easterEgg: EasterEggData;
}

export type ClaimEasterEggOutput = TransactionReceipt;

const claimEasterEgg = async ({
  tpiAirdropContract,
  fromAccountAddress,
  easterEgg,
}: ClaimEasterEggInput): Promise<ClaimEasterEggOutput> => {
  const resp = await tpiAirdropContract.methods
    .permit(
      fromAccountAddress,
      easterEgg.tpiAmount,
      easterEgg.nonce,
      easterEgg.deadline,
      easterEgg.signature.v,
      easterEgg.signature.r,
      easterEgg.signature.s,
    )
    .send({ from: fromAccountAddress });

  return checkForTpiAirdropTransactionError(resp);
};

export default claimEasterEgg;
