import { getGnftStatistic, getTpiStatistic } from 'clients/api';
import getTreasuryBalances from '../getTreasuryTotal';

const getStatistic = async () => {
  const gnftStatistic = await getGnftStatistic();
  const tpiStatistic = await getTpiStatistic();
  const treasuryTotals = await getTreasuryBalances();

  return {
    gnft: gnftStatistic,
    tpi: tpiStatistic,
    treasury: treasuryTotals,
  };
};

export default getStatistic;
