import { MutationObserverOptions, useMutation } from 'react-query';
import { CToken } from 'types';

import redeemUnderlying, {
  RedeemUnderlyingInput,
  RedeemUnderlyingOutput,
} from 'clients/api/mutations/redeemUnderlying';
import queryClient from 'clients/api/queryClient';
import { useCTokenContract } from 'clients/contracts/hooks';
import FunctionKey from 'constants/functionKey';

const useRedeemUnderlying = (
  { cToken, accountAddress }: { cToken: CToken; accountAddress: string },
  options?: MutationObserverOptions<
    RedeemUnderlyingOutput,
    Error,
    Omit<RedeemUnderlyingInput, 'cTokenContract' | 'accountAddress'>
  >,
) => {
  const cTokenContract = useCTokenContract(cToken);

  return useMutation(
    FunctionKey.REDEEM_UNDERLYING,
    params =>
      redeemUnderlying({
        cTokenContract,
        accountAddress,
        ...params,
      }),
    {
      ...options,
      onSuccess: (...onSuccessParams) => {
        queryClient.invalidateQueries(FunctionKey.GET_C_TOKEN_BALANCES_ALL);
        queryClient.invalidateQueries([
          FunctionKey.GET_C_TOKEN_BALANCE,
          {
            accountAddress,
            cTokenAddress: cToken.address,
          },
        ]);
        queryClient.invalidateQueries(FunctionKey.GET_MAIN_MARKETS);

        if (options?.onSuccess) {
          options.onSuccess(...onSuccessParams);
        }
      },
    },
  );
};

export default useRedeemUnderlying;
