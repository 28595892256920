/** @jsxImportSource @emotion/react */
import React, { useMemo, useState } from 'react';
import { fromWei } from 'web3-utils';

import { NULL_ADDRESS } from 'constants/address';
import { CERC_TOKENS, TOKENS } from 'constants/tokens';

import { useTranslation } from 'translation';

import { getContractAddress, getCTokenByAddress, getTokenByAddress } from 'utilities';

import useTokenApproval from 'hooks/useTokenApproval';

import useHandleGNFTSegmentOpenTransactionMutation, {
  TransactionReceiptWithEasterEggs,
} from 'hooks/useHandleGNFTSegmentOpenTransactionMutation';

import { CToken, GNFT, gnftTokenInfoByType, Token } from 'types';
import { Proof } from 'clients/api/queries/getAirdropProofs';

import {
  useActivateGnftSegments,
  useGetBalanceOf,
  useGetGnftSegmentsActivationPrice,
  useAvailableMarketsForLock,
  useGetAirdropProofs,
  AirdropSelectionChose,
  useActivateGnftSegmentWithProof,
  getEasterEggs,
} from 'clients/api';

import { routes } from 'constants/routing';

import { Button, Spinner } from 'components';

import { Modal, ModalProps } from 'components/Modal';
import SegmentsSlider from 'components/SegmentsSlider';
import InfoItem from 'components/InfoItem';
import LockMarketSelect from 'components/MarketSelect';

import { useStyles } from './styles';


export interface GnftModalProps extends Omit<ModalProps, 'children'> {
  gnft: GNFT;
  className?: string;
  accountAddress: string;
  refetch: () => Promise<any>;
}

const weiToNumber = (wei: string) => parseFloat(fromWei(wei));

type MarketsListProps = {
  onChange: (address: string) => void;
  value: string;
  accountAddress: string;
  loading?: boolean;
  markets: {
    cToken: CToken;
    balance: any;
    amountToLock: number;
  }[];
};

const MarketsList: React.FC<MarketsListProps> = ({ onChange, value, loading, markets }) => {
  const styles = useStyles();

  if (loading) return <Spinner />;

  const handleChange = (e: any) => onChange(e.target.value);

  return (
    <div>
      <div css={styles.lastSegmentInfo}>
        <div css={styles.lastSegmentInfoTitle}>You are opening last segment.</div>
        <div>
          To finish your gNFT and start to receive rewards you need to lock provided liquidity equal
          to $100 on your gNFT. You will be able to unlock it anytime. After unlock your gNFT will
          stop receive rewards.
        </div>
      </div>
      <LockMarketSelect onChange={handleChange} value={value} markets={markets} />
    </div>
  );
};

export const GnftModal: React.FC<GnftModalProps> = ({
  accountAddress,
  className,
  gnft,
  isOpen,
  handleClose,
  refetch,
}) => {
  const { t } = useTranslation();
  const handleGNFTSegmentOpenTransactionMutation = useHandleGNFTSegmentOpenTransactionMutation();

  const { data: markets, isLoading: isLoadingMarkets } = useAvailableMarketsForLock(
    gnft.meta.tokenType,
    accountAddress,
  );

  const { data: airdropProofs, isLoading: isLoadingAirdropProofs } = useGetAirdropProofs(
    { accountAddress },
    { enabled: !!accountAddress },
  );

  const { data: tpiBalanceWei, isLoading: isLoadingTpiBalance } = useGetBalanceOf({
    accountAddress,
    token: TOKENS.tpi,
  });

  const tpiBalance = useMemo(
    () => (tpiBalanceWei?.balanceWei ? weiToNumber(tpiBalanceWei.balanceWei.toFixed()) : 0),
    [tpiBalanceWei?.balanceWei],
  );

  const oldActiveSegments = gnft.meta.activeSegment;

  const [activeSegments, setActiveSegments] = useState<number>(oldActiveSegments);
  const [marketToLock, setMarketToLock] = useState<string>('');

  const segmentsToOpen = activeSegments - oldActiveSegments;

  const tokenInfo = gnftTokenInfoByType[gnft.meta.tokenType];

  const styles = useStyles();

  const title = `Collect ${tokenInfo.title} #${gnft.tokenId}`;

  const handleSegmentsAmountChange = (e: any, value: number | number[]) => {
    const amount = Array.isArray(value) ? value[0] : value;
    setActiveSegments(Math.max(amount, oldActiveSegments));
  };

  const handleMarketToLockChange = (market: string) => setMarketToLock(market);

  const { data: price, isLoading: isLoadingPrice } = useGetGnftSegmentsActivationPrice({
    tokenId: gnft.tokenId.toString(),
    segmentsToOpen,
  });

  const {
    mutateAsync: activateGnftSegments,
    isLoading: isActivatingGnftSegments,
  } = useActivateGnftSegments();

  const {
    mutateAsync: activateGnftSegmentWithProof,
    isLoading: isActivatingGnftSegmentWithProof,
  } = useActivateGnftSegmentWithProof();

  const activationPrice = useMemo(
    () => (price?.priceWei ? weiToNumber(price.priceWei.toFixed()) : 0),
    [price?.priceWei],
  );

  const gnftAirdropProofs = useMemo(
    () =>
      (airdropProofs?.proofs || []).filter(
        (item: any) => item.selection === AirdropSelectionChose.GNFT && !item.claimed,
      ),
    [airdropProofs?.proofs],
  );

  const onActivate = async () => {
    const resposnse = await activateGnftSegments({
      tokenId: gnft.tokenId.toString(),
      segmentsToOpen,
      fromAccountAddress: accountAddress,
      market: marketToLock || NULL_ADDRESS,
    });

    const easterEggs = await getEasterEggs({
      tokenIds: [gnft.tokenId],
    });

    refetch();

    handleClose();

    return { ...resposnse, easterEggs };
  };

  const onActivateGnftSegmentWithProof = async () => {
    const proof: Proof = gnftAirdropProofs[0];

    const resposnse = await activateGnftSegmentWithProof({
      tokenId: gnft.tokenId.toString(),
      fromAccountAddress: accountAddress,
      nonce: proof.nonce,
      proof: proof.proof,
      market: marketToLock || NULL_ADDRESS,
    });

    const easterEggs = await getEasterEggs({
      tokenIds: [gnft.tokenId],
    });

    refetch();

    handleClose();

    return { ...resposnse, easterEggs };
  };

  const successTransactionModalProps = (transactionReceipt: TransactionReceiptWithEasterEggs) => {
    const unclaimedEasterEggs = transactionReceipt.easterEggs.filter(egg => !egg.claimed);
    const content = (
      <div css={styles.successModalContent}>
        <div>{t('activateGntSegmentButton.successfulTransactionModal.message')}</div>
        {unclaimedEasterEggs.length ? (
          <div>
            Congratulations! You found {unclaimedEasterEggs.length} easter egg(s) while opening segments. Go to <a href={routes.easterEggs.path} target="_blank" rel="noreferrer">Easter Eggs page</a> to find out more information.
          </div>
        ) : null}
      </div>
    );
    return {
      title: t('activateGntSegmentButton.successfulTransactionModal.title'),
      content,
      transactionHash: transactionReceipt.transactionHash,
    };
  };

  const handleClick = () =>
    handleGNFTSegmentOpenTransactionMutation({
      mutate: onActivate,
      successTransactionModalProps,
    });

  const handleActivateWithProofClick = () =>
    handleGNFTSegmentOpenTransactionMutation({
      mutate: onActivateGnftSegmentWithProof,
      successTransactionModalProps,
    });

  const hasFreeSegments = gnftAirdropProofs.length > 0;

  const isFinish = activeSegments === 12;

  const isFinished = oldActiveSegments === 12;

  const cToken = useMemo(() => getCTokenByAddress(marketToLock), [marketToLock]);

  const {
    approveToken: approveTPIToken,
    isApproveTokenLoading: isApproveTPILoading,
    allowance: tpiAllowance,
  } = useTokenApproval({
    token: getTokenByAddress(getContractAddress('tpi')) as Token,
    spenderAddress: getContractAddress('gnftSegmentManager'),
    accountAddress,
  });

  const {
    approveToken: approveMarketToken,
    isApproveTokenLoading: isApproveMarketTokenLoading,
    allowance: marketTokenAllowance,
  } = useTokenApproval({
    token: cToken || (getCTokenByAddress(CERC_TOKENS.weth.address) as CToken),
    spenderAddress: getContractAddress('gnftSegmentManager'),
    accountAddress,
  });

  const marketToLockAmount = useMemo(() => {
    const market = markets.find(m => m.cToken.address.toLowerCase() === marketToLock.toLowerCase());
    return market?.amountToLock || 0;
  }, [markets, marketToLock]);

  const isMarketTokenApproved = marketTokenAllowance > marketToLockAmount;

  const isButtonDisabled =
    activeSegments < 1 ||
    activationPrice === 0 ||
    tpiBalance < activationPrice ||
    (isFinish && !isMarketTokenApproved) ||
    (isFinish && !marketToLock);

  const imgSrc = useMemo(() => `${tokenInfo.imgPath}/${activeSegments}.png`, [
    tokenInfo.imgPath,
    activeSegments,
  ]);

  const showApproveTpiButton = tpiAllowance < activationPrice;

  const showApproveMarketButton = isFinish && !isMarketTokenApproved && !!marketToLock;

  const isActivateWithProofDisabled = activeSegments === 11 && !marketToLock;

  return (
    <Modal isOpen={isOpen} title={title} handleClose={handleClose}>
      <div className={className} css={styles.container}>
        <div css={styles.imgWrapper}>
          <img src={imgSrc} alt={title} />
        </div>
        {isLoadingAirdropProofs ? <Spinner /> : null}
        <SegmentsSlider
          value={activeSegments}
          activeSegments={oldActiveSegments}
          onChange={handleSegmentsAmountChange}
        />

        <div css={styles.infoBox}>
          <InfoItem title="Balance" value={`${tpiBalance} TPI`} loading={isLoadingTpiBalance} />
          <InfoItem
            title="Need for opening"
            value={`${activationPrice} TPI`}
            loading={isLoadingPrice}
          />
        </div>
        {hasFreeSegments && (
          <InfoItem
            title="Free Segments"
            value={gnftAirdropProofs.length}
            loading={isLoadingAirdropProofs}
          />
        )}
        {(isFinish || (activeSegments === 11 && hasFreeSegments)) && (
          <MarketsList
            value={marketToLock}
            onChange={handleMarketToLockChange}
            accountAddress={accountAddress}
            markets={markets}
            loading={isLoadingMarkets}
          />
        )}
        <div css={styles.buttons}>
          {showApproveTpiButton && (
            <Button fullWidth onClick={approveTPIToken} loading={isApproveTPILoading}>
              Approve TPI
            </Button>
          )}
          {showApproveMarketButton && (
            <Button fullWidth onClick={approveMarketToken} loading={isApproveMarketTokenLoading}>
              Approve market
            </Button>
          )}
          {hasFreeSegments && (
            <Button
              fullWidth
              onClick={handleActivateWithProofClick}
              loading={isActivatingGnftSegmentWithProof}
              disabled={isActivateWithProofDisabled}
            >
              Use free segment {activeSegments === 11 ? ' and activate gNFT' : ''}
            </Button>
          )}
          {!isFinished && !showApproveMarketButton && !showApproveTpiButton && !hasFreeSegments && (
            <Button
              fullWidth
              onClick={handleClick}
              loading={isActivatingGnftSegments}
              disabled={isButtonDisabled}
            >
              Open {segmentsToOpen} {segmentsToOpen > 1 ? 'segments' : 'segment'}{' '}
              {isFinish ? ' and activate gNFT' : ''}
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};
