/** @jsxImportSource @emotion/react */
import React from 'react';

import { Spinner } from 'components';

import { useStyles } from './styles';

type InfoItemProps = {
  title: any;
  value: any;
  loading?: boolean;
};

const InfoItem: React.FC<InfoItemProps> = ({ title, value, loading }) => {
  const styles = useStyles();

  return (
    <div css={styles.infoItem}>
      <div css={styles.infoItemTitle}>{title}</div>
      <div css={styles.infoItemValue}>{loading ? <Spinner /> : value}</div>
    </div>
  );
};

export default InfoItem;
