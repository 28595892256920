import { getWeb3NoAccount } from 'clients/web3';
import { Multicall } from 'ethereum-multicall';
import { TPIStatistic } from 'types';
import { getContractAddress } from 'utilities';

import tpiAbi from 'constants/contracts/abis/tpi.json';
import BigNumber from 'bignumber.js';

const getTpiStatistic = async (): Promise<TPIStatistic> => {
  const multicall = new Multicall({ web3Instance: getWeb3NoAccount(), tryAggregate: true });

  const tpiConfig = {
    reference: 'tpi',
    contractAddress: getContractAddress('tpi'),
    abi: tpiAbi,
    calls: [
      {
        reference: 'comptrollerBalance',
        methodName: 'balanceOf',
        methodParameters: [getContractAddress('comptroller')],
      },
      {
        reference: 'deadBalance',
        methodName: 'balanceOf',
        methodParameters: ['0x000000000000000000000000000000000000dead'],
      },
      {
        reference: 'totalSupply',
        methodName: 'totalSupply',
        methodParameters: [],
      },
    ],
  };

  try {
    const result = await multicall.call(tpiConfig);
    const {
      comptrollerBalance,
      deadBalance,
      totalSupply,
    } = result.results.tpi.callsReturnContext.reduce((acc, item) => {
      acc[item.reference] = new BigNumber(item.returnValues[0].hex);

      return acc;
    }, {} as any);

    return {
      totalSupply,
      deadBalance,
      comptrollerBalance,
    };
  } catch (e) {
    console.error(e);

    return {
      totalSupply: new BigNumber(0),
      deadBalance: new BigNumber(0),
      comptrollerBalance: new BigNumber(0),
    };
  }
};

export default getTpiStatistic;
