import { css } from '@emotion/react';

const useStyles = () => ({
  segmentsSlider: css`
        color: #00A6FF;
        height: 8px;
        padding: 15px 0;
        
        & .MuiSlider-thumb {
            height: 24px;
            width: 24px;

            background: #171A1E;
            border: 1px solid #383B40;
            box-shadow: 0px 8px 12px #000000;

            &:focus,
            &:hover,
            &.Mui-active {
                background: #00A6FF;
                box-shadow: none;
            }
        }
        
        & .MuiSlider-valueLabel {
            font-size: 12;
            font-weight: normal;
            top: -6px;
            background-color: unset;
            color: theme.palette.text.primary,
            
            &:before: {
                display: none;
            }
            
            & * {
                background: transparent;
                color: #000;
            }
        }
        
        & .MuiSlider-track {
            border: none;
        }
        
        & .MuiSlider-rail {
            background: #25272B;
            border: 1px solid #383B40;
        }
        
        & .MuiSlider-mark {
            background-color: #383B40;
            height: 8px;
            width: 1px;
            
            &.MuiSlider-markActive {
                opacity: 1;
                background-color: #0D0E0F;
            }
        }
    }`,
  segmentsSliderWrapper: css``,
  segmentsSliderLabel: css`
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    line-height: 22px;
  `,
  segmentsSliderToOpen: css`
    font-weight: 600;
  `,
  segmentsSliderOpened: css`
    font-weight: 600;

    span {
      font-weight: 400;
      color: #8d9196;
    }
  `,
});

export default useStyles;
