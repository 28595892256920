import { css } from '@emotion/react';

export const useStyles = () => ({
  title: css`
    margin-bottom: 20px;
  `,
  image: css`
    margin-bottom: -60px;
  `,
  info: css`
    background: #101316;
    border: 1px solid #0a0c10;
    border-radius: 16px;
    padding: 24px 28px;

    margin-bottom: 20px;

    font-size: 14px;
    line-height: 18px;

    text-align: center;

    color: rgba(255, 255, 255, 0.6);

    a {
      color: #fff;
      text-decoration: underline;
    }
  `,
});
