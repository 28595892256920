import { routes } from 'constants/routing';

import { MenuItem } from './types';

export const menuItems: MenuItem[] = [
  {
    href: routes.dashboard.path,
    // Translation key: do not remove this comment
    // t('layout.menuItems.dashboard')
    i18nKey: 'layout.menuItems.dashboard',
    icon: 'dashboard',
  },
  {
    href: routes.markets.path,
    // Translation key: do not remove this comment
    // t('layout.menuItems.pools')
    i18nKey: 'layout.menuItems.markets',
    icon: 'market',
  },
  {
    href: routes.gnft.path,
    i18nKey: 'layout.menuItems.gnft',
    icon: 'diamond',
  },
  {
    href: routes.tpi.path,
    i18nKey: 'layout.menuItems.tpi',
    icon: 'tpi',
  },
  {
    href: routes.airdrop.path,
    i18nKey: 'layout.menuItems.airdrop',
    icon: 'airdrop',
  },
];
