import config from 'config';
import CHAINS from 'constants/chains';

// Prompt the user to add BSC as a network, or switch to BSC if the wallet is on
// a different network
const setUpNetwork = async () => {
  if (!window.ethereum) {
    // TODO: send error to Sentry

    console.error("Can't set up the BSC network on wallet because window.ethereum is undefined");
    return false;
  }

  const params = CHAINS[config.chainId];

  try {
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: params.chainId }],
    });

    return true;
  } catch (switchError: any) {
    // TODO: send error to Sentry

    if (switchError.code === 4902) {
      try {
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [params],
        });
      } catch (addError) {
        console.error('Failed to add network on wallet:', addError);
        return false;
      }
    } else {
      console.error('Failed to set up network on wallet:', switchError);
      return false;
    }
  }
};

export default setUpNetwork;
