import React, { useContext, useMemo } from 'react';

import { AuthContext } from 'context/AuthContext';

import { GNFT, GnftTokenType } from 'types';

import { useGetGnftInAccount, useGetStatistic } from 'clients/api';

import UserGnfts from 'components/UserGnfts';
import MintGnft from 'components/MintGnft';
import { formatCentsToReadableValue, getContractAddress } from 'utilities';
import StatsContainer from 'components/Stats/StatsContainer';

type StatsProps = {
  totalPendingRewards: number;
};

const Stats: React.FC<StatsProps> = ({ totalPendingRewards }) => {
  const { data } = useGetStatistic();

  const gnft = data?.gnft;
  const treasury = data?.treasury;

  const cells = [
    {
      label: 'Total Minted',
      value: gnft?.total || '-',
    },
    {
      label: 'Total Activated',
      value: gnft?.opened || '-',
    },
    {
      label: 'Treasury To Distribute',
      value: treasury?.totalUsd
        ? formatCentsToReadableValue({
            value: (treasury.totalUsd.toNumber() || 0) * 100,
            shortenLargeValue: true,
          })
        : '-',
    },
    {
      label: 'Your pending rewards',
      value: totalPendingRewards
        ? formatCentsToReadableValue({
            value: totalPendingRewards * 100,
            shortenLargeValue: true,
          })
        : '-',
    },
  ];

  return <StatsContainer cells={cells} />;
};

type GNFTUIProps = {
  accountAddress: string;
  isLoading?: boolean;
  gnfts: GNFT[];
  refetch: () => Promise<any>;
};

const GnftUI: React.FC<GNFTUIProps> = ({ gnfts, refetch, ...props }) => {
  const { topazes, emeralds, diamonds, totalPendingRewards } = useMemo(() => {
    let pendingRewards = 0;
    const topazesNft: GNFT[] = [];
    const emeraldsNft: GNFT[] = [];
    const diamondsNft: GNFT[] = [];
    (gnfts || []).forEach(gnft => {
      const { tokenType, pendingRewards: nftPendingRewards } = gnft.meta;

      pendingRewards += parseFloat(nftPendingRewards || '0');

      if (tokenType === GnftTokenType.TOPAZ) {
        topazesNft.push(gnft);
      } else if (tokenType === GnftTokenType.EMERALD) {
        emeraldsNft.push(gnft);
      } else if (tokenType === GnftTokenType.DIAMOND) {
        diamondsNft.push(gnft);
      }
    });

    return {
      topazes: topazesNft,
      emeralds: emeraldsNft,
      diamonds: diamondsNft,
      totalPendingRewards: pendingRewards,
    };
  }, [gnfts]);
  return (
    <>
      <Stats totalPendingRewards={totalPendingRewards} />
      <MintGnft
        topazes={topazes}
        emeralds={emeralds}
        diamonds={diamonds}
        onMint={refetch}
        {...props}
      />
      <UserGnfts refetch={refetch} gnfts={gnfts} {...props} />
    </>
  );
};

const Gnft = () => {
  const { account } = useContext(AuthContext);
  const accountAddress = account?.address || '';

  const { data: nfts, isLoading, refetch } = useGetGnftInAccount(
    {
      accountAddress,
      treasuryAddress: getContractAddress('treasury'),
    },
    {
      enabled: !!accountAddress,
    },
  );

  return (
    <GnftUI
      accountAddress={accountAddress}
      isLoading={isLoading}
      refetch={refetch}
      gnfts={nfts || []}
    />
  );
};
export default Gnft;
