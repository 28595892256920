import { MutationObserverOptions, useMutation } from 'react-query';

import { ClaimEasterEggInput, ClaimEasterEggOutput, claimEasterEgg, queryClient } from 'clients/api';
import { useTpiAirdropContract } from 'clients/contracts/hooks';
import FunctionKey from 'constants/functionKey';

type Options = MutationObserverOptions<ClaimEasterEggOutput, Error, Omit<ClaimEasterEggInput, 'tpiAirdropContract'>>;

const useClaimEasterEgg = (options?: Options) => {
  const tpiAirdropContract = useTpiAirdropContract();

  return useMutation(
    FunctionKey.CLAIM_EASTER_EGG,
    (params: Omit<ClaimEasterEggInput, 'tpiAirdropContract'>) =>
      claimEasterEgg({
        tpiAirdropContract,
        ...params,
      }),
    {
      ...options,
      onSuccess: async (...onSuccessParams) => {
        queryClient.resetQueries(FunctionKey.GET_EASTER_EGGS);

        if (options?.onSuccess) {
          options.onSuccess(...onSuccessParams);
        }
      },
    },
  );
};

export default useClaimEasterEgg;
