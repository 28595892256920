import { useQuery } from 'react-query';
import BigNumber from 'bignumber.js';

import FunctionKey from 'constants/functionKey';

import { Market } from 'types';

import { getMainMarkets } from 'clients/api';


export interface GetMainMarketsOutput {
  markets: Market[];
  dailyTpiWei: BigNumber;
}

const useGetMainMarkets = () => useQuery([FunctionKey.GET_MAIN_MARKETS], () => getMainMarkets());

export default useGetMainMarkets;
