import { css } from '@emotion/react';

export const useStyles = () => ({
  header: css``,
  section: css`
    padding: 20px 0;
    margin: 20px 0;

    & + & {
      border-top: 1px solid #25272B;
    }
  `,
  title: css`
    display: flex;
    gap: 10px;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 15px;
  `,
  tableWrapper: css`
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  `,
});
