/** @jsxImportSource @emotion/react */
import BigNumber from 'bignumber.js';
import { ConnectWallet, ModalProps, Spinner } from 'components';
import React, { useContext } from 'react';
import { useTranslation } from 'translation';
import { Asset, CToken, Pool } from 'types';
import { areTokensEqual, convertTokensToWei } from 'utilities';

import { useGetPool, useSupply } from 'clients/api';
import { AmountFormProps } from 'containers/AmountForm';
import { AuthContext } from 'context/AuthContext';
import useSuccessfulTransactionModal from 'hooks/useSuccessfulTransactionModal';
import useDataLayer from 'hooks/useDataLayer';

import { useStyles } from '../styles';
import SupplyForm from './form';

export interface SupplyProps {
  onClose: ModalProps['handleClose'];
  cToken: CToken;
  poolComptrollerAddress: string;
}

export interface SupplyUiProps extends Omit<SupplyProps, 'cToken' | 'poolComptrollerAddress'> {
  onSubmit: AmountFormProps['onSubmit'];
  isLoading: boolean;
  className?: string;
  asset?: Asset;
  pool?: Pool;
}

export const SupplyUi: React.FC<SupplyUiProps> = ({
  className,
  asset,
  pool,
  onSubmit,
  isLoading,
}) => {
  const styles = useStyles();

  const { t } = useTranslation();

  const maxInput = React.useMemo(() => {
    if (!asset) {
      return new BigNumber(0);
    }

    const maxInputTokens = asset.userWalletBalanceTokens;

    return maxInputTokens;
  }, [asset]);

  if (!asset) {
    return <></>;
  }

  return (
    <div className={className} css={styles.container}>
      <ConnectWallet message={t('supplyWithdraw.connectWalletToSupply')}>
        {asset && pool ? (
          <SupplyForm
            key="form-supply"
            asset={asset}
            pool={pool}
            onSubmit={onSubmit}
            inputLabel={t('supplyWithdraw.walletBalance')}
            enabledButtonKey={t('supplyWithdraw.supply')}
            disabledButtonKey={t('supplyWithdraw.enterValidAmountSupply')}
            maxInput={maxInput}
            isTransactionLoading={isLoading}
          />
        ) : (
          <Spinner />
        )}
      </ConnectWallet>
    </div>
  );
};

const SupplyModal: React.FC<SupplyProps> = ({ cToken, poolComptrollerAddress, onClose }) => {
  const { account: { address: accountAddress = '' } = {} } = useContext(AuthContext);

  const dataLayer = useDataLayer();

  const { data: getPoolData } = useGetPool({ poolComptrollerAddress, accountAddress });
  const pool = getPoolData?.pool;
  const asset = pool?.assets.find(item => areTokensEqual(item.cToken, cToken));

  const { t } = useTranslation();
  const { openSuccessfulTransactionModal } = useSuccessfulTransactionModal();

  const { mutateAsync: supply, isLoading: isSupplyLoading } = useSupply({
    cToken,
    accountAddress,
  });

  const onSubmit: AmountFormProps['onSubmit'] = async value => {
    const supplyAmountWei = convertTokensToWei({
      value: new BigNumber(value),
      token: cToken.underlyingToken,
    });
    const args = {
      event: 'tonpound_event',
      event_context: 'suply_start',
      event_content: cToken.underlyingToken.symbol,
      event_auth: accountAddress,
    };
    dataLayer.push(args);
    const res = await supply({
      amountWei: supplyAmountWei,
    });

    dataLayer.push({
      ...args,
      event_context: 'suply_success',
      event_value: (asset?.tokenPriceDollars || new BigNumber('0')).multipliedBy(value).toFixed(0),
    });

    onClose();

    openSuccessfulTransactionModal({
      title: t('supplyWithdraw.successfulSupplyTransactionModal.title'),
      content: t('supplyWithdraw.successfulSupplyTransactionModal.message'),
      amount: {
        valueWei: supplyAmountWei,
        token: cToken.underlyingToken,
      },
      transactionHash: res.transactionHash,
    });
  };

  return (
    <SupplyUi
      onClose={onClose}
      asset={asset}
      pool={pool}
      isLoading={isSupplyLoading}
      onSubmit={onSubmit}
    />
  );
};

export default SupplyModal;
