import BigNumber from 'bignumber.js';

import { TpiViewer } from 'types/contracts';

export interface GetTpiRewardInput {
  tpiViewerContract: TpiViewer;
  accountAddress: string;
  tpiAddress: string;
  comptrollerAddress: string;
}

export type GetTpiRewardOutput = {
  TpiRewardWei: BigNumber;
};

const getTpiReward = async ({
  tpiViewerContract,
  accountAddress,
  tpiAddress,
  comptrollerAddress,
}: GetTpiRewardInput): Promise<GetTpiRewardOutput> => {
  const res = await tpiViewerContract.methods.pendingTPI(tpiAddress, comptrollerAddress, accountAddress).call();

  return {
    TpiRewardWei: new BigNumber(res),
  };
};

export default getTpiReward;
