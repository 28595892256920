import { AirdropSelectionChose } from 'clients/api/mutations/setAirdropSelection';
import { restService } from 'utilities';

export type Proof = {
  address: string;
  proof: string[];
  leafHash: string;
  selection: AirdropSelectionChose;
  nonce: string;
  claimed?: boolean;
};

export type ChooseId = {
  id: number;
  salt: string;
};

export interface GetAirdropResponse {
  proofs: Proof[];
  toChooseIds: ChooseId[];
}

export interface GetAirdropProofsInput {
  accountAddress: string;
}

export type GetAirdropProofsOutput = GetAirdropResponse;

const defaultValue = {
  proofs: [],
  toChooseIds: [],
};

const getAirdropProofs = async ({
  accountAddress,
}: GetAirdropProofsInput): Promise<GetAirdropProofsOutput> => {
  try {
    const response = await restService<GetAirdropResponse>({
      endpoint: '/airdrop/community-giveaway/proof',
      method: 'GET',
      params: {
        address: accountAddress,
      },
    });

    const payload = response.data?.data;

    const data = payload || defaultValue;

    return data;
  } catch (e) {
    console.error(e);
    return defaultValue;
  }
};

export default getAirdropProofs;
