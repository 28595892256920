import { useMemo } from 'react';
import { Token } from 'types';
import type { TransactionReceipt } from 'web3-core/types';

import { useApproveToken, useGetAllowance } from 'clients/api';
import { formatUnits } from 'ethers/lib/utils';

interface UseTokenApprovalInput {
  token: Token;
  spenderAddress: string;
  accountAddress?: string;
}

interface UseTokenApprovalOutput {
  isTokenApproved: boolean | undefined;
  isTokenApprovalStatusLoading: boolean;
  approveToken: () => Promise<TransactionReceipt | undefined>;
  isApproveTokenLoading: boolean;
  allowance: number;
}

// TODO: add tests

const useTokenApproval = ({
  token,
  spenderAddress,
  accountAddress,
}: UseTokenApprovalInput): UseTokenApprovalOutput => {
  const {
    data: getTokenAllowanceData,
    isLoading: isTokenApprovalStatusLoading,
    refetch: refetchAllowance,
  } = useGetAllowance(
    {
      accountAddress: accountAddress || '',
      spenderAddress,
      token,
    },
    {
      enabled: !!accountAddress && token && !token.isNative,
    },
  );

  const isTokenApproved = useMemo(() => {
    if (token.isNative) {
      return true;
    }

    if (!getTokenAllowanceData) {
      return undefined;
    }

    return getTokenAllowanceData.allowanceWei.isGreaterThan(0);
  }, [token.isNative, getTokenAllowanceData]);

  const allowanceWei = getTokenAllowanceData?.allowanceWei.toFixed() || '0';

  const { mutateAsync: approveTokenMutation, isLoading: isApproveTokenLoading } = useApproveToken({
    token,
  });

  const approveToken = async () => {
    if (accountAddress) {
      const response = await approveTokenMutation({
        accountAddress,
        spenderAddress,
      });
      await refetchAllowance();
      return response;
    }
  };

  return {
    isTokenApproved,
    isTokenApprovalStatusLoading,
    isApproveTokenLoading,
    approveToken,
    allowance: parseFloat(formatUnits(allowanceWei, token.decimals)),
  };
};

export default useTokenApproval;
