import { QueryObserverOptions, useQuery } from 'react-query';

import { useTpiViewerContract } from 'clients/contracts/hooks';
import { DEFAULT_REFETCH_INTERVAL_MS } from 'constants/defaultRefetchInterval';
import FunctionKey from 'constants/functionKey';

import getTpiReward, { GetTpiRewardInput, GetTpiRewardOutput } from '.';

type Options = QueryObserverOptions<
  GetTpiRewardOutput,
  Error,
  GetTpiRewardOutput,
  GetTpiRewardOutput,
  [FunctionKey.GET_TPI_REWARD, string]
>;

const useGetTpiReward = (
  { accountAddress, tpiAddress, comptrollerAddress }: Omit<GetTpiRewardInput, 'tpiViewerContract'>,
  options?: Options,
) => {
  const tpiViewerContract = useTpiViewerContract();

  return useQuery(
    [FunctionKey.GET_TPI_REWARD, accountAddress],
    () =>
      getTpiReward({
        tpiViewerContract,
        tpiAddress,
        comptrollerAddress,
        accountAddress,
      }),
    {
      refetchInterval: DEFAULT_REFETCH_INTERVAL_MS,
      ...options,
    },
  );
};

export default useGetTpiReward;
