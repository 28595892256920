import { checkForGnftSegmentsManagerTransactionError } from 'errors';
import type { TransactionReceipt } from 'web3-core/types';

import { GnftSegmentManager } from 'types/contracts';
import { GNFT, GnftTokenType } from 'types';

export interface MintGnftInput {
  gnftSegmentManagerContract: GnftSegmentManager;
  fromAccountAddress: string;
  tokenType: GnftTokenType;
  topazes: GNFT[];
  emeralds: GNFT[];
  markets: string[];
}

export type MintGnftOutput = TransactionReceipt;

const mintGnft = async ({
  gnftSegmentManagerContract,
  fromAccountAddress,
  tokenType,
  topazes,
  emeralds,
  markets,
}: MintGnftInput): Promise<MintGnftOutput> => {
  if (tokenType === GnftTokenType.TOPAZ) {
    const resp = await gnftSegmentManagerContract.methods['mint(address[])'](markets).send({
      from: fromAccountAddress,
    });
    return checkForGnftSegmentsManagerTransactionError(resp);
  }
  const ids: number[] = [
    ...topazes.filter(i => !i.meta.usedForMint).map(topaz => topaz.tokenId),
    ...emeralds.filter(i => !i.meta.usedForMint).map(emerald => emerald.tokenId),
  ];
  const resp = await gnftSegmentManagerContract.methods['mint(address[],uint8,uint256[])'](
    markets,
    tokenType,
    ids,
  ).send({ from: fromAccountAddress });

  return checkForGnftSegmentsManagerTransactionError(resp);
};

export default mintGnft;
