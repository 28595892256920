import { Multicall, ContractCallContext } from 'ethereum-multicall';

import { getWeb3NoAccount } from 'clients/web3';

import { getContractAddress, restService } from 'utilities';

import tpiAirdropAbi from 'constants/contracts/abis/tpiAirdrop.json';

export type EasterEggData = {
  deadline: number;
  giftSegment: number;
  nonce: string;
  owner: string;
  signature: {
    compact: string;
    r: string;
    recoveryParam: number;
    s: string;
    v: number;
    yParityAndS: string;
    _vs: string;
  };
  status: string;
  tokenId: number;
  tpiAmount: string;
  claimed?: boolean;
};

export type GetEasterEggsResponse = {
  [x: number]: EasterEggData[];
};

export interface GetEasterEggsInput {
  tokenIds: number[];
}

export type GetEasterEggsOutput = EasterEggData[];

const defaultValue: { [x: number]: EasterEggData[] } = [];

const getEasterEggs = async ({ tokenIds }: GetEasterEggsInput): Promise<GetEasterEggsOutput> => {
  try {
    const response = await restService<GetEasterEggsResponse>({
      endpoint: '/airdrop/easter-egg',
      method: 'GET',
      params: {
        'token-ids': tokenIds,
      },
    });

    const payload = response.data?.data;

    const data = payload || defaultValue;

    const web3 = getWeb3NoAccount();

    const multicall = new Multicall({ web3Instance: web3, tryAggregate: true });

    const callContext: ContractCallContext = {
      reference: 'tpiAirdropCall',
      contractAddress: getContractAddress('tpiAirdrop'),
      abi: tpiAirdropAbi,
      calls: [],
    };

    const eggs: EasterEggData[] = [];

    Object.keys(data).forEach(key => {
      const items = data[parseInt(key, 10)];

      items.forEach(item => {
        if (item.signature) {
          eggs.push(item);

          callContext.calls.push({
            reference: `${item.tokenId}_${item.giftSegment}`,
            methodName: 'nonces',
            methodParameters: [item.nonce],
          });
        }
      });
    });

    const multicallResult = await multicall.call(callContext);

    const eggsWithClaimed = eggs.map((egg, index) => {
      const claimed =
        multicallResult.results.tpiAirdropCall.callsReturnContext[index].returnValues[0];

      return {
        ...egg,
        claimed,
      };
    });

    return eggsWithClaimed;
  } catch (e) {
    console.error(e);
    return [];
  }
};

export default getEasterEggs;
