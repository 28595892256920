import { css } from '@emotion/react';

export const useStyles = () => ({
  marketSelectWrapper: css``,
  marketSelectLabel: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 600;
  `,
  marketSelectBalance: css`
    span {
      font-weight: 400;
      color: #8d9196;
    }
  `,
  option: css`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
    font-weight: 600;
    font-size: 18px;
  `,
  token: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  `,
});
