import BigNumber from 'bignumber.js';
import { checkForTokenTransactionError } from 'errors';
import { CToken } from 'types';
import Web3 from 'web3';
import type { TransactionReceipt } from 'web3-core/types';

import { getCTokenContract } from 'clients/contracts';
import MAX_UINT256 from 'constants/maxUint256';
import { CErc20, CpTonToken } from 'types/contracts';

export interface RepayInput {
  web3: Web3;
  accountAddress: string;
  cToken: CToken;
  amountWei: BigNumber;
  isRepayingFullLoan?: boolean;
}

export type RepayOutput = TransactionReceipt;

export const REPAYMENT_BNB_BUFFER_PERCENTAGE = 0.001;

const repay = async ({
  web3,
  accountAddress,
  cToken,
  amountWei,
  isRepayingFullLoan = false,
}: RepayInput): Promise<RepayOutput> => {
  // Handle repaying tokens other than BNB
  if (!cToken.underlyingToken.isNative) {
    const cTokenContract = getCTokenContract(cToken, web3) as CErc20;

    const resp = await cTokenContract.methods
      .repayBorrow(isRepayingFullLoan ? MAX_UINT256.toFixed() : amountWei.toFixed())
      .send({ from: accountAddress });
    return checkForTokenTransactionError(resp);
  }

  // Handle repaying partial BNB loan
  const cpTonContract = getCTokenContract(cToken, web3) as CpTonToken;
  const contractData = cpTonContract.methods.repayBorrow(amountWei.toFixed()).encodeABI();

  const resp = await web3.eth.sendTransaction({
    from: accountAddress,
    to: cToken.address,
    value: amountWei.toFixed(),
    data: contractData,
  });
  return checkForTokenTransactionError(resp);
};

export default repay;
