import { ChainId } from 'types';
import { RPC_URLS } from './endpoints';
import { SCAN_URLS } from './eth';

type ChainConfig = {
  chainId: string; // A 0x-prefixed hexadecimal string
  chainName: string;
  nativeCurrency: {
    name: string;
    symbol: string; // 2-6 characters long
    decimals: number;
  };
  rpcUrls: string[];
  blockExplorerUrls?: string[];
  iconUrls?: string[]; // Currently ignored.
};

const chainIdToHex = (chainId: number) => `0x${chainId.toString(16)}`;

const CHAINS: { [x in ChainId]: ChainConfig } = {
  [ChainId.MAINNET]: {
    chainId: chainIdToHex(ChainId.MAINNET),
    chainName: 'Ethereum',
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: RPC_URLS[ChainId.MAINNET],
    blockExplorerUrls: [SCAN_URLS[ChainId.MAINNET]],
  },
  [ChainId.GOERLI]: {
    chainId: chainIdToHex(ChainId.GOERLI),
    chainName: 'Goerli Ethereum',
    nativeCurrency: {
      name: 'Goerli Ethereum',
      symbol: 'GETH',
      decimals: 18,
    },
    rpcUrls: RPC_URLS[ChainId.GOERLI],
    blockExplorerUrls: [SCAN_URLS[ChainId.GOERLI]],
  },
  [ChainId.MUMBAI]: {
    chainId: chainIdToHex(ChainId.MUMBAI),
    chainName: 'Polygon Testnet',
    nativeCurrency: {
      name: 'Matic',
      symbol: 'MATIC',
      decimals: 18,
    },
    rpcUrls: RPC_URLS[ChainId.MUMBAI],
    blockExplorerUrls: [SCAN_URLS[ChainId.MUMBAI]],
  },
};

export default CHAINS;
