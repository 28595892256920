/** @jsxImportSource @emotion/react */
/* eslint-disable react/no-array-index-key */
import React, { useMemo, useContext } from 'react';

import { AuthContext } from 'context/AuthContext';

import { useTranslation } from 'translation';

import { GNFT, gnftTokenInfoByType, GnftTokenType } from 'types';

import useDataLayer from 'hooks/useDataLayer';
import useHandleTransactionMutation from 'hooks/useHandleTransactionMutation';
import { useGetUserPools, useMintGnft } from 'clients/api';

import gnftInfo from 'constants/gnftInfo';

import { Button } from 'components';

import { useStyles } from './styles';

type MintGnftItemProps = {
  accountAddress: string;
  tokenType: GnftTokenType;
  isLoading?: boolean;
  topazes: GNFT[];
  emeralds: GNFT[];
  diamonds: GNFT[];
  userMarkets: string[];
  userUsdSupply: number;
  onMint: () => Promise<void>;
};

const MintGnftItem: React.FC<MintGnftItemProps> = ({
  accountAddress,
  tokenType,
  topazes,
  emeralds,
  userMarkets,
  userUsdSupply,
  onMint: onMintCallback,
}) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const dataLayer = useDataLayer();
  const handleTransactionMutation = useHandleTransactionMutation();
  const { mutateAsync: mintGnft, isLoading: isMintingGnft } = useMintGnft();
  const activeTopazes = useMemo(
    () => topazes.filter(topaz => topaz.meta.activeSegment === 12 && !topaz.meta.usedForMint),
    [topazes],
  );
  const activeEmeralds = useMemo(
    () =>
      emeralds.filter(emerald => emerald.meta.activeSegment === 12 && !emerald.meta.usedForMint),
    [emeralds],
  );

  const info = gnftInfo[tokenType];

  const onMint = async () => {
    const response = await mintGnft({
      fromAccountAddress: accountAddress,
      tokenType,
      topazes: activeTopazes,
      emeralds: activeEmeralds,
      markets: userMarkets,
    });

    dataLayer.push({
      event: 'tonpound_event',
      event_context: 'nft_mint_success',
      event_content: info.title,
      event_auth: accountAddress,
    });

    onMintCallback();

    return response;
  };
  const handleClick = () =>
    handleTransactionMutation({
      mutate: onMint,
      successTransactionModalProps: transactionReceipt => ({
        title: t('mintGnftButton.successfulTransactionModal.title'),
        content: t('mintGnftButton.successfulTransactionModal.message'),
        transactionHash: transactionReceipt.transactionHash,
      }),
    });

  let disabledMint = false;
  const mintNoteItems = [];
  if (tokenType === GnftTokenType.TOPAZ) {
    disabledMint = userUsdSupply < 100;
    mintNoteItems.push("$100 equivalent supplied in any of Tonpound's markets.");
  } else if (tokenType === GnftTokenType.EMERALD) {
    disabledMint = activeTopazes.length < 10;
    mintNoteItems.push('10 activated and non-used Topaz gNFTs');
  } else if (tokenType === GnftTokenType.DIAMOND) {
    disabledMint = activeTopazes.length < 10 || activeEmeralds.length < 1;
    mintNoteItems.push('10 activated and non-used Topaz gNFTs');
    mintNoteItems.push('1 activated and non-used Emerald gNFT');
  }

  const tokenInfo = useMemo(() => gnftTokenInfoByType[tokenType], [tokenType]);
  const imgSrc = useMemo(() => `${tokenInfo.imgPath}/0.png`, [tokenInfo]);

  return (
    <div css={[styles.gnftItem, styles.mintGnftItem]}>
      <div css={styles.gnftItemImageWrapper}>
        <img src={imgSrc} alt={`Mint empty ${tokenInfo.title}`} />
      </div>
      <div css={styles.gnftItemInfo}>
        <div css={styles.gnftMintNotes}>
          <div>Required:</div>
          <ul>
            {mintNoteItems.map((item, index) => (
              <li key={index} css={styles.gnftMintNote}>
                {item}
              </li>
            ))}
          </ul>
        </div>
        <Button fullWidth onClick={handleClick} loading={isMintingGnft} disabled={disabledMint}>
          {t(`mintGnftButton.title_${info.key}`)}
        </Button>
      </div>
    </div>
  );
};

type MintGnftProps = {
  accountAddress: string;
  isLoading?: boolean;
  topazes: GNFT[];
  emeralds: GNFT[];
  diamonds: GNFT[];
  onMint: () => Promise<void>;
};

const MintGnft: React.FC<MintGnftProps> = props => {
  const styles = useStyles();
  const { account } = useContext(AuthContext);
  const { data: userPools } = useGetUserPools({ accountAddress: account?.address || '' });
  const { userMarkets, userUsdSupply } = useMemo(() => {
    const markets: string[] = [];
    let usdSupply = 0;
    userPools.forEach(userPool => {
      userPool.assets.forEach(asset => {
        if (asset.userSupplyBalanceTokens.isGreaterThan(0)) {
          markets.push(asset.cToken.address);
          usdSupply += asset.userSupplyBalanceCents / 100;
        }
      });
    });

    return { userMarkets: markets, userUsdSupply: usdSupply };
  }, [userPools]);
  return (
    <>
      <h4 css={styles.title}>Mint your gNFT</h4>
      <div css={styles.gnftItems}>
        <MintGnftItem
          {...props}
          userMarkets={userMarkets}
          userUsdSupply={userUsdSupply}
          tokenType={GnftTokenType.TOPAZ}
        />
        <MintGnftItem
          {...props}
          userMarkets={userMarkets}
          userUsdSupply={userUsdSupply}
          tokenType={GnftTokenType.EMERALD}
        />
        <MintGnftItem
          {...props}
          userMarkets={userMarkets}
          userUsdSupply={userUsdSupply}
          tokenType={GnftTokenType.DIAMOND}
        />
      </div>
    </>
  );
};

export default MintGnft;
