/** @jsxImportSource @emotion/react */
import React from 'react';
import { Slider } from '@mui/material';

import useStyles from './styles';

type SegmentsSliderProps = {
  value: number;
  activeSegments: number;
  onChange: (e: any, value: number | number[]) => void;
};

const SegmentsSlider: React.FC<SegmentsSliderProps> = ({ value, activeSegments, onChange }) => {
  const styles = useStyles();
  const toOpen = value - activeSegments;
  return (
    <div css={styles.segmentsSliderWrapper}>
      <div css={styles.segmentsSliderLabel}>
        <div css={styles.segmentsSliderToOpen}>
          Open {toOpen} {toOpen > 1 ? 'segments' : 'segment'}
        </div>
        <div css={styles.segmentsSliderOpened}>
          <span>Opened:</span> {activeSegments} of 12
        </div>
      </div>
      <Slider
        css={styles.segmentsSlider}
        value={value}
        min={0}
        max={12}
        onChange={onChange}
        marks
      />
    </div>
  );
};

export default SegmentsSlider;
