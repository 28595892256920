import { css } from '@emotion/react';

export const useStyles = () => ({
  title: css`
    margin-bottom: 20px;
  `,
  gnftItems: css`
    display: flex;
    gap: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    flex-wrap: wrap;
  `,
  gnftItem: css`
    overflow: hidden;
    background: rgba(20, 27, 35, 0.5);
    border: 1px solid #242f3d;
    border-radius: 15px;
    min-width: 200px;
    max-width: 293px;
    width: 100%;
  `,
  mintGnftItems: css`
    flex-wrap: nowrap;
  `,
  mintGnftItem: css`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-bottom: 10px;
    max-width: unset;
  `,
  gnftItemImageWrapper: css`
    min-height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #101112;

    svg {
      width: 60px;
      height: auto;
      color: rgba(255,255,255,0.8);

      &:hover {
        cursor: pointer;
        color: rgba(255,255,255,1);
      }
    }

    svg.disabled {
      pointer-events: none;
      color: rgba(255,255,255,0.5);
    }
  `,
  gnftItemImage: css`
    margin-top: 10px;
    max-width: 160px;
    max-height: 160px;
  `,
  gnftItemHeader: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 20px;
  `,
  gnftItemTitle: css`
    text-overflow: ellipsis;
    overflow: hidden;
  `,
  gnftItemMore: css``,
  gnftItemInfo: css`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px 20px 18px;
  `,
  gnftMintNote: css`
    margin-bottom: 5px;
  `,
  gnftMintNotes: css`
    margin: 10px auto;
    width: 100%;

    & > div {
      margin-bottom: 10px;
    }

    & > ul {
      padding-left: 10px;
    }
  `,
  noData: css`
    text-align: center;
    margin: 20px auto;
  `,
});
