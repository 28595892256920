/* eslint-disable no-await-in-loop */
import BigNumber from 'bignumber.js';
import { CToken, Token } from 'types';
import { getContractAddress, getCTokenByAddress, getTokenByAddress } from 'utilities';
import { getWeb3NoAccount } from 'clients/web3';
import { Multicall } from 'ethereum-multicall';

import { getMarkets } from 'clients/subgraph';

import cErc20Abi from 'constants/contracts/abis/cErc20.json';
import cpTONAbi from 'constants/contracts/abis/cpTonToken.json';
import oracleAbi from 'constants/contracts/abis/oracle.json';
import comptrollerAbi from 'constants/contracts/abis/comptroller.json';

import { DAYS_PER_YEAR } from 'constants/daysPerYear';
import { COMPOUND_MANTISSA } from 'constants/compoundMantissa';
import { formatUnits } from 'ethers/lib/utils';

import getTokenBalances from 'clients/api/queries/getTokenBalances';

const BIG_TEN = new BigNumber(10);

const getMainMarkets = async () => {
  const response = await getMarkets();
  const originalMarkets = response?.markets || [];
  const markets: any[] = [];

  const multicall = new Multicall({ web3Instance: getWeb3NoAccount(), tryAggregate: true });

  const uniV3PoolAddress = getContractAddress('uniV3Pool');

  let distrTokenPrice = 0;

  if (uniV3PoolAddress) {
    const tpiAddress = getContractAddress('tpi');
    const usdtAddress = getContractAddress('usdt');
    const { tokenBalances } = await getTokenBalances({
      multicall,
      web3: getWeb3NoAccount(),
      accountAddress: uniV3PoolAddress,
      tokens: [getTokenByAddress(usdtAddress) as Token, getTokenByAddress(tpiAddress) as Token],
    });

    const [usdt, tpi] = tokenBalances;

    const usdtBalance = formatUnits(usdt.balanceWei.toFixed(), usdt.token.decimals);
    const tpiBalance = formatUnits(tpi.balanceWei.toFixed(), tpi.token.decimals);

    distrTokenPrice = parseFloat(usdtBalance) / parseFloat(tpiBalance);
  }

  for (let i = 0; i < originalMarkets.length; i++) {
    const market = originalMarkets[i];

    const cToken: CToken | undefined = getCTokenByAddress(market.id);

    if (cToken) {
      const tokenAbi = cToken.symbol === 'cpTon' ? cpTONAbi : cErc20Abi;

      const cTokenMethods = [
        'exchangeRateStored',
        'supplyRatePerBlock',
        'borrowRatePerBlock',
        'getCash',
        'totalBorrows',
        'totalSupply',
        'totalReserves',
      ];

      const cTokenConfig = {
        reference: 'cToken',
        contractAddress: cToken.address,
        abi: tokenAbi,
        calls: cTokenMethods.map(methodName => ({
          reference: methodName,
          methodName,
          methodParameters: [],
        })),
      };

      const oracleConfig = {
        reference: 'oracle',
        contractAddress: getContractAddress('oracle'),
        abi: oracleAbi,
        calls: [
          {
            reference: 'getUnderlyingPrice',
            methodName: 'getUnderlyingPrice',
            methodParameters: [cToken.address],
          },
        ],
      };

      const comptrollerMethods = ['markets', 'compSupplySpeeds', 'compBorrowSpeeds', 'borrowCaps'];

      const comptrollerConfig = {
        reference: 'comptroller',
        contractAddress: getContractAddress('comptroller'),
        abi: comptrollerAbi,
        calls: comptrollerMethods.map(methodName => ({
          reference: methodName,
          methodName,
          methodParameters: [cToken.address],
        })),
      };

      try {
        const multicallResult = await multicall.call([
          cTokenConfig,
          oracleConfig,
          comptrollerConfig,
        ]);

        const { results } = multicallResult;

        const {
          cToken: cTokenResult,
          oracle: oracleResult,
          comptroller: comptrollerResult,
        } = results;

        const exchangeRate = new BigNumber(cTokenResult.callsReturnContext[0].returnValues[0].hex);
        const supplyRatePerBlock = new BigNumber(
          cTokenResult.callsReturnContext[1].returnValues[0].hex,
        );
        const borrowRatePerBlock = new BigNumber(
          cTokenResult.callsReturnContext[2].returnValues[0].hex,
        );
        const cash = new BigNumber(cTokenResult.callsReturnContext[3].returnValues[0].hex);
        const totalBorrows = new BigNumber(cTokenResult.callsReturnContext[4].returnValues[0].hex);
        // const totalSupply = new BigNumber(cTokenResult.callsReturnContext[5].returnValues[0].hex);
        // const totalReserves = new BigNumber(cTokenResult.callsReturnContext[6].returnValues[0].hex);

        const tokenPriceWei = new BigNumber(oracleResult.callsReturnContext[0].returnValues[0].hex);
        const tokenPrice = tokenPriceWei.div(BIG_TEN.pow(36 - cToken.underlyingToken.decimals));

        const collateralFactor = new BigNumber(
          comptrollerResult.callsReturnContext[0].returnValues[1].hex,
        ).toString();

        const compSupplySpeeds = new BigNumber(
          comptrollerResult.callsReturnContext[1].returnValues[0].hex,
        );
        const compBorrowSpeeds = new BigNumber(
          comptrollerResult.callsReturnContext[2].returnValues[0].hex,
        );

        const borrowCaps = new BigNumber(
          comptrollerResult.callsReturnContext[3].returnValues[0].hex,
        ).div(BIG_TEN.pow(cToken.underlyingToken.decimals));

        const totalBorrows2 = parseFloat(
          formatUnits(totalBorrows.toFixed(), cToken.underlyingToken.decimals),
        );
        const totalSupply2 = market.totalSupply;

        const blocksPerDay = 7200;

        // APY calcuation is: ( 1 + RATE_PER_DAY )^365 - 1
        // Where RATE_PER_DAY = blocksPerDay * supplyRatePerBlock / 1e18

        const getApy = (value: BigNumber) =>
          value
            .multipliedBy(blocksPerDay)
            .dividedBy(COMPOUND_MANTISSA)
            .plus(1)
            .pow(DAYS_PER_YEAR)
            .minus(1)
            .multipliedBy(100);

        const exchangeRateCTokens = exchangeRate
          ? new BigNumber(1).div(
              new BigNumber(exchangeRate).div(
                BIG_TEN.pow(18 + cToken.underlyingToken.decimals - cToken.decimals),
              ),
            )
          : new BigNumber(0);

        const totalSupplyUsd = new BigNumber(totalSupply2)
          .div(exchangeRateCTokens)
          .multipliedBy(tokenPrice)
          .toFixed();

        const getDistrSupplyApr = (value: BigNumber) =>
          value
            .dividedBy(COMPOUND_MANTISSA)
            .multipliedBy(blocksPerDay)
            .multipliedBy(distrTokenPrice)
            .dividedBy(totalSupplyUsd)
            .multipliedBy(DAYS_PER_YEAR)
            .multipliedBy(100);

        const supplyApy = getApy(supplyRatePerBlock);
        const borrowApy = getApy(borrowRatePerBlock);
        const supplyTPIApr = getDistrSupplyApr(compSupplySpeeds);
        const borrowTPIApr = new BigNumber('0');
        const supplierDailyTPI = compSupplySpeeds.multipliedBy(blocksPerDay);
        const borrowerDailyTPI = compBorrowSpeeds.multipliedBy(blocksPerDay);

        const liquidity = cash.div(BIG_TEN.pow(18)).multipliedBy(tokenPrice);

        markets.push({
          ...market,
          collateralFactor: collateralFactor.toString(),
          exchangeRate: exchangeRate.toString(),
          address: market.id,
          cash: cash.toString(),
          liquidity,
          totalReserves: market.reserves,
          tokenPrice,
          borrowRatePerBlock: borrowRatePerBlock.toString(),
          borrowCaps: borrowCaps.toString(),
          borrowApy,
          totalBorrowsUsd: new BigNumber(totalBorrows2).multipliedBy(tokenPrice).toFixed(),
          totalBorrows2: totalBorrows2.toString(),
          borrowerCount: 0,
          supplyRatePerBlock: supplyRatePerBlock.toString(),
          supplyApy,
          totalSupply2,
          totalSupplyUsd,
          supplierCount: 0,
          supplyTPIApr,
          supplierDailyTPI: supplierDailyTPI.toFixed(),
          borrowTPIApr,
          borrowerDailyTPI: borrowerDailyTPI.toFixed(),
        });
      } catch (e) {
        console.error(e);
      }
    }
  }

  const preparedData = {
    ...response,
    markets,
    dailyTpiWei: new BigNumber(0),
  };

  return preparedData;
};

export default getMainMarkets;
