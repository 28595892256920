import { checkForComptrollerTransactionError } from 'errors';
import type { TransactionReceipt } from 'web3-core/types';

import { CERC_TOKENS } from 'constants/tokens';
import { Comptroller } from 'types/contracts';

export interface ClaimTpiRewardInput {
  comptrollerContract: Comptroller;
  fromAccountAddress: string;
}

export type ClaimTpiRewardOutput = TransactionReceipt;

const claimTpiReward = async ({
  comptrollerContract,
  fromAccountAddress,
}: ClaimTpiRewardInput): Promise<ClaimTpiRewardOutput> => {
  // Fetch list of tokens for which user have a positive balance, since these
  // are the tokens susceptible to have generated TPI rewards
  const cTokenAddresses = Object.values(CERC_TOKENS).map(cToken => cToken.address);
  // Send query to claim TPI reward
  const resp = await comptrollerContract.methods['claimComp(address[],address[],bool,bool)'](
    [fromAccountAddress],
    cTokenAddresses,
    false,
    true,
  ).send({
    from: fromAccountAddress,
  });
  return checkForComptrollerTransactionError(resp);
};

export default claimTpiReward;
