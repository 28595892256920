/** @jsxImportSource @emotion/react */

import React, { useContext, useMemo } from 'react';
import { formatEther } from 'ethers/lib/utils';
import { NavLink } from 'react-router-dom';

import { AuthContext } from 'context/AuthContext';

import {
  EasterEggData,
  useClaimEasterEgg,
  useGetEasterEggs,
  useGetGnftTokenIdsInAccount,
} from 'clients/api';

import { routes } from 'constants/routing';

import { Button, Icon, Spinner } from 'components';

import { useStyles } from './styles';


type EasterEggProps = {
  egg: EasterEggData;
};

const EasterEgg: React.FC<EasterEggProps> = ({ egg }) => {
  const { account } = useContext(AuthContext);
  const accountAddress = account?.address || '';

  const styles = useStyles();
  const { mutateAsync, isLoading } = useClaimEasterEgg();

  const title = useMemo(() => `${egg.giftSegment} segment of #${egg.tokenId} gNFT`, [egg]);

  const onClick = async () => {
    await mutateAsync({
      fromAccountAddress: accountAddress,
      easterEgg: egg,
    });
  };

  return (
    <div css={styles.easterEgg}>
      <div css={styles.easterEggInfo}>
        <Icon name="easterEgg" css={styles.easterEggIcon} />
        <div>
          <div css={styles.easterEggReward}>{formatEther(egg.tpiAmount)} TPI</div>
          <div css={styles.easterEggTitle}>{title}</div>
        </div>
      </div>
      <div>
        {egg.claimed ? (
          <Button small disabled>Claimed</Button>
        ) : (
          <Button small onClick={onClick} loading={isLoading}>
            Claim
          </Button>
        )}
      </div>
    </div>
  );
};

type EasterEggsProps = {
  easterEggs: EasterEggData[];
  isLoading: boolean;
};

const EasterEggsUI: React.FC<EasterEggsProps> = ({ easterEggs, isLoading }) => {
  const styles = useStyles();
  const showSpinner = isLoading && !easterEggs.length;
  const sortedEggs = useMemo(
    () =>
      easterEggs.sort((a, b) => {
        if (a.tokenId === b.tokenId) return a.giftSegment - b.giftSegment;
        if (b.claimed) return -1;
        return a.tokenId - b.tokenId;
      }),
    [easterEggs],
  );
  return (
    <div css={styles.easterEggs}>
      <h4 css={styles.easterEggsTitle}>Your founded gNFT Easter Eggs</h4>
      {showSpinner && <Spinner />}
      {!showSpinner && !sortedEggs.length ? (
        <div css={styles.noEasterEggs}>
          <Icon name="easterEgg" css={styles.easterEggIcon} /> <div>No easter eggs found yet. <NavLink to={routes.gnft.path}>Open gNFT segments</NavLink> to find your first easter egg.</div>
        </div>
      ) : null}
      {sortedEggs.map(item => (
        <EasterEgg key={`${item.tokenId}_${item.giftSegment}`} egg={item} />
      ))}
    </div>
  );
};

const EasterEggs = () => {
  const { account } = useContext(AuthContext);
  const accountAddress = account?.address || '';

  const { data: tokenIds = [], isLoading: isLoadingTokenIds } = useGetGnftTokenIdsInAccount({
    accountAddress,
  });
  const { data = [], isLoading } = useGetEasterEggs(
    {
      tokenIds,
    },
    { enabled: !!tokenIds.length },
  );

  return <EasterEggsUI easterEggs={data} isLoading={isLoadingTokenIds || isLoading} />;
};

export default EasterEggs;
