import { css } from '@emotion/react';

export const useStyles = () => ({
  infoItem: css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    line-height: 22px;
    color: #fff;

    & + & {
      margin-top: 10px;
    }
  `,
  infoItemTitle: css`
    color: #8d9196;
    font-weight: 400;
  `,
  infoItemValue: css`
    font-weight: 600;
  `,
});
