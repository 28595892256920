import FunctionKey from 'constants/functionKey';
import { useQuery, QueryObserverOptions } from 'react-query';

import { getAirdropProofs, GetAirdropProofsInput, GetAirdropProofsOutput } from 'clients/api';

type Options = QueryObserverOptions<
  GetAirdropProofsOutput,
  Error,
  GetAirdropProofsOutput,
  GetAirdropProofsOutput,
  [FunctionKey.GET_AIRDROP, GetAirdropProofsInput]
>;

const useGetAirdropProofs = (input: GetAirdropProofsInput, options: Options) =>
  useQuery([FunctionKey.GET_AIRDROP, input], () => getAirdropProofs(input), options);

export default useGetAirdropProofs;
