/** @jsxImportSource @emotion/react */

import React, { useMemo } from 'react';
import { Paper } from '@mui/material';

import { GnftStatistc, TPIStatistic } from 'types';

import { useGetStatistic } from 'clients/api';
import { GetTreasuryBalancesOutput } from 'clients/api/queries/getTreasuryTotal';

import { formatCentsToReadableValue, formatTokensToReadableValue } from 'utilities';

import {
  LayeredValues,
  Spinner,
  Table,
  TableColumn,
  TokenIconWithSymbol,
} from 'components';
import StatsContainer from 'components/Stats/StatsContainer';
import StatsSection from 'components/StatsSection';

import { Icon } from 'components/Icon';

import { useStyles } from './styles';


export interface StatisticUIProps {
  loading: boolean;
  data?: {
    gnft: GnftStatistc;
    tpi: TPIStatistic;
    treasury: GetTreasuryBalancesOutput;
  };
}

type TreasurySectionProps = {
  treasury: GetTreasuryBalancesOutput;
};

const TreasurySection: React.FC<TreasurySectionProps> = ({ treasury }) => {
  const styles = useStyles();

  const treasuryColumns: TableColumn<any>[] = [
    {
      key: 'token',
      label: 'Asset',
      renderCell: ({ cToken }) => <TokenIconWithSymbol token={cToken.underlyingToken} />,
    },
    {
      key: 'balance',
      label: 'Tokens',
      align: 'right',
      renderCell: ({ total, totalUsd, cToken }) => (
        <LayeredValues
          topValue={formatTokensToReadableValue({
            value: total,
            token: cToken.underlyingToken,
            shortenLargeValue: true,
          })}
          bottomValue={formatCentsToReadableValue({
            value: totalUsd * 100,
            shortenLargeValue: true,
          })}
        />
      ),
    },
  ];

  const { balances, totalUsd } = treasury;

  const treasuryPools = useMemo(() => Object.keys(balances).map(key => balances[key]), [balances]);

  const cells = useMemo(
    () => [
      {
        label: 'Total',
        value: formatCentsToReadableValue({
          value: totalUsd.toNumber() * 100,
          shortenLargeValue: true,
        }),
      },
    ],
    [totalUsd],
  );

  return (
    <div css={styles.section}>
      <div css={styles.title}>Treasury</div>
      <StatsContainer cells={cells} />
      <Paper css={styles.tableWrapper}>
        <Table
          columns={treasuryColumns}
          data={treasuryPools}
          rowKeyExtractor={row => `treasury-table-row-${row.cToken.address}`}
          breakpoint="sm"
        />
      </Paper>
    </div>
  );
};

type GNFTSectionProps = {
  gnft: GnftStatistc;
};

const getGnftCells = (gnft: GnftStatistc, type: 'total' | 'topaz' | 'emerald' | 'diamond') => {
  const values = type === 'total' ? gnft : gnft[type];
  const cells = [
    {
      label: 'Total Minted',
      value: values.total || '-',
    },
    {
      label: 'Total Activated',
      value: values.opened || '-',
    },
    { label: 'Total Used', value: values.used || '-' },
  ];

  return cells;
};

const GNFTSection: React.FC<GNFTSectionProps> = ({ gnft }) => {
  const styles = useStyles();
  const gnftCells = useMemo(() => getGnftCells(gnft, 'total'), [gnft]);
  const gnftTopazesCells = useMemo(() => getGnftCells(gnft, 'topaz'), [gnft]);
  const gnftEmeraldsCells = useMemo(() => getGnftCells(gnft, 'emerald'), [gnft]);
  const gnftDiamondsCells = useMemo(() => getGnftCells(gnft, 'diamond'), [gnft]);

  return (
    <div css={styles.section}>
      <div css={styles.title}>
        <Icon name="diamond" /> gNFT
      </div>
      <StatsContainer cells={gnftCells} />
      <div css={styles.title}>Topazes</div>
      <StatsContainer cells={gnftTopazesCells} />
      <div css={styles.title}>Emeralds</div>
      <StatsContainer cells={gnftEmeraldsCells} />
      <div css={styles.title}>Diamonds</div>
      <StatsContainer cells={gnftDiamondsCells} />
    </div>
  );
};

const StatisticUI: React.FC<StatisticUIProps> = ({ loading, data = {} }) => {
  const { gnft, tpi, treasury } = data;

  return loading ? (
    <Spinner />
  ) : (
    <div>
      {tpi && <StatsSection tpi={tpi} />}
      {treasury && <TreasurySection treasury={treasury} />}
      {gnft && <GNFTSection gnft={gnft} />}
    </div>
  );
};

const Statistic: React.FC = () => {
  const { data, isLoading } = useGetStatistic();

  return <StatisticUI data={data} loading={isLoading} />;
};

export default Statistic;
