import BigNumber from 'bignumber.js';
import { useMemo } from 'react';
import { Asset, AssetDistribution } from 'types';
import {
  calculateCollateralValue,
  convertDollarsToCents,
  convertTokensToWei,
  convertWeiToTokens,
  getCTokenByAddress,
  indexBy,
} from 'utilities';

import {
  IGetCTokenBalancesAllOutput,
  useGetAssetsInAccount,
  useGetCTokenBalancesAll,
  useGetMainMarkets,
} from 'clients/api';
import { COMPOUND_MANTISSA } from 'constants/compoundMantissa';
import { CERC_TOKENS, TOKENS } from 'constants/tokens';

export interface Data {
  assets: Asset[];
  userTotalBorrowLimitCents: BigNumber;
  userTotalBorrowBalanceCents: BigNumber;
  userTotalSupplyBalanceCents: BigNumber;
}

export interface UseGetMainAssetsOutput {
  isLoading: boolean;
  data?: Data;
}

const cTokenAddresses = Object.values(CERC_TOKENS).reduce(
  (acc, item) => (item.address ? [...acc, item.address] : acc),
  [] as string[],
);

const useGetMainAssets = ({
  accountAddress,
}: {
  accountAddress?: string;
}): UseGetMainAssetsOutput => {
  const { data: getMainMarketsData, isLoading: isGetMainMarketsLoading } = useGetMainMarkets();

  const {
    data: assetsInAccount = {
      tokenAddresses: [],
    },
    isLoading: isGetAssetsInAccountLoading,
  } = useGetAssetsInAccount(
    { accountAddress: accountAddress || '' },
    {
      enabled: !!accountAddress,
      placeholderData: {
        tokenAddresses: [],
      },
    },
  );

  const {
    data: cTokenBalancesAccount = { balances: [] },
    isLoading: isGetCTokenBalancesAccountLoading,
  } = useGetCTokenBalancesAll(
    { account: accountAddress || '', cTokenAddresses },
    { enabled: !!accountAddress, placeholderData: { balances: [] } },
  );

  const cTokenBalances = useMemo(
    () =>
      indexBy(
        (item: IGetCTokenBalancesAllOutput['balances'][number]) => item.cToken.toLowerCase(), // index by CToken address
        cTokenBalancesAccount.balances,
      ),
    [cTokenBalancesAccount],
  );

  const isLoading =
    isGetMainMarketsLoading || isGetAssetsInAccountLoading || isGetCTokenBalancesAccountLoading;

  const data = useMemo(() => {
    if (!getMainMarketsData?.markets) {
      return undefined;
    }

    const {
      assets,
      // userTotalBorrowBalanceCents,
      userTotalBorrowLimitCents,
      userTotalSupplyBalanceCents,
    } = getMainMarketsData.markets.reduce(
      (acc, market) => {
        const cToken = getCTokenByAddress(market.address);

        // Skip token if it isn't listed
        if (!cToken) {
          return acc;
        }

        if (market.underlyingAddress.toLowerCase() !== cToken.underlyingToken.address.toLowerCase()) return acc;

        const cTokenAddress = cToken.address.toLowerCase();
        const isCollateralOfUser = (assetsInAccount.tokenAddresses || [])
          .map(address => address.toLowerCase())
          .includes(cTokenAddress);

        const tokenPriceDollars = new BigNumber(market.tokenPrice || 0);

        let userWalletBalanceTokens = new BigNumber(0);
        let userSupplyBalanceTokens = new BigNumber(0);
        let userSupplyBalanceCents = 0;
        let userBorrowBalanceTokens = new BigNumber(0);
        let userBorrowBalanceCents = 0;
        let userWalletBalanceCents = 0;

        const wallet = cTokenBalances && cTokenBalances[cTokenAddress];
        if (accountAddress && wallet) {
          const toDecimalAmount = (mantissa: string) =>
            new BigNumber(mantissa).shiftedBy(-cToken.underlyingToken.decimals);

          userWalletBalanceTokens = toDecimalAmount(wallet.tokenBalance);
          userWalletBalanceCents = convertDollarsToCents(
            userWalletBalanceTokens.times(tokenPriceDollars),
          );

          userSupplyBalanceTokens = toDecimalAmount(wallet.balanceOfUnderlying);
          userSupplyBalanceCents = convertDollarsToCents(
            userSupplyBalanceTokens.times(tokenPriceDollars),
          );

          userBorrowBalanceTokens = toDecimalAmount(wallet.borrowBalanceCurrent);
          userBorrowBalanceCents = convertDollarsToCents(
            userBorrowBalanceTokens.times(tokenPriceDollars),
          );
        }

        const reserveTokens = market.totalReserves
          ? convertWeiToTokens({
              valueWei: new BigNumber(market.totalReserves),
              token: cToken.underlyingToken,
            })
          : new BigNumber(0);

        const cashTokens = market.cash
          ? convertWeiToTokens({
              valueWei: new BigNumber(market.cash),
              token: cToken.underlyingToken,
            })
          : new BigNumber(0);

        const exchangeRateCTokens = market.exchangeRate
          ? new BigNumber(1).div(
              new BigNumber(market.exchangeRate).div(
                new BigNumber(10).pow(18 + cToken.underlyingToken.decimals - cToken.decimals),
              ),
            )
          : new BigNumber(0);

        const supplyRatePerBlockTokens = market.supplyRatePerBlock
          ? new BigNumber(market.supplyRatePerBlock).dividedBy(COMPOUND_MANTISSA)
          : new BigNumber(0);

        const borrowRatePerBlockTokens = market.borrowRatePerBlock
          ? new BigNumber(market.borrowRatePerBlock).dividedBy(COMPOUND_MANTISSA)
          : new BigNumber(0);

          const tpiDistribution: AssetDistribution = {
            token: TOKENS.tpi,
            dailyDistributedTokens: new BigNumber(market.supplierDailyTPI || 0)
              .plus(new BigNumber(market.borrowerDailyTPI || 0))
              .div(new BigNumber(10).pow(TOKENS.tpi.decimals)),
            supplyAprPercentage: new BigNumber(market.supplyTPIApr || 0),
            borrowAprPercentage: new BigNumber(market.borrowTPIApr || 0),
          };

        const asset: Asset = {
          cToken,
          tokenPriceDollars: new BigNumber(market.tokenPrice || 0),
          supplyApyPercentage: new BigNumber(market.supplyApy || 0),
          borrowApyPercentage: new BigNumber(market.borrowApy || 0),
          collateralFactor: new BigNumber(market.collateralFactor || 0)
            .div(COMPOUND_MANTISSA)
            .toNumber(),
          reserveFactor: new BigNumber(market.reserveFactor || 0).div(COMPOUND_MANTISSA).toNumber(),
          reserveTokens,
          cashTokens,
          exchangeRateCTokens,
          liquidityCents: market.tokenPrice * cashTokens.toNumber() * 100,
          borrowCapTokens: new BigNumber(market.borrowCaps || 0),
          supplierCount: market.supplierCount || 0,
          borrowerCount: market.borrowerCount || 0,
          supplyBalanceTokens: new BigNumber(market.totalSupply2 || 0).div(exchangeRateCTokens),
          supplyBalanceCents: convertDollarsToCents(
            market.totalSupplyUsd ? +market.totalSupplyUsd : 0,
          ),
          borrowBalanceTokens: new BigNumber(market.totalBorrows2 || 0),
          borrowBalanceCents: convertDollarsToCents(
            market.totalBorrowsUsd ? +market.totalBorrowsUsd : 0,
          ),
          supplyRatePerBlockTokens,
          borrowRatePerBlockTokens,
          isCollateralOfUser,
          userWalletBalanceTokens,
          userWalletBalanceCents,
          userPercentOfLimit: 0,
          userSupplyBalanceTokens,
          userSupplyBalanceCents,
          userBorrowBalanceTokens,
          userBorrowBalanceCents,
          distributions: [tpiDistribution],
        };

        acc.userTotalBorrowBalanceCents =
          acc.userTotalBorrowBalanceCents.plus(userBorrowBalanceCents);

        acc.userTotalSupplyBalanceCents =
          acc.userTotalSupplyBalanceCents.plus(userSupplyBalanceCents);

        // Create borrow limit based on assets supplied as isCollateralOfUser
        if (asset.isCollateralOfUser) {
          acc.userTotalBorrowLimitCents = acc.userTotalBorrowLimitCents.plus(
            calculateCollateralValue({
              amountWei: convertTokensToWei({
                value: asset.userSupplyBalanceTokens,
                token: cToken.underlyingToken,
              }),
              token: asset.cToken.underlyingToken,
              tokenPriceDollars: asset.tokenPriceDollars,
              collateralFactor: asset.collateralFactor,
            }).times(100),
          );
        }

        return { ...acc, assets: [...acc.assets, asset] };
      },
      {
        assets: [] as Asset[],
        userTotalBorrowBalanceCents: new BigNumber(0),
        userTotalBorrowLimitCents: new BigNumber(0),
        userTotalSupplyBalanceCents: new BigNumber(0),
      },
    );

    let assetList = assets;

    // percent of limit
    assetList = assetList.map((item: Asset) => ({
      ...item,
      userPercentOfLimit: new BigNumber(item.userBorrowBalanceCents)
        .times(100)
        .div(userTotalBorrowLimitCents)
        .dp(2)
        .toNumber(),
    }));

    return {
      assets: assetList,
      userTotalBorrowBalanceCents: new BigNumber('0'),
      userTotalBorrowLimitCents,
      userTotalSupplyBalanceCents,
    };
  }, [getMainMarketsData?.markets, assetsInAccount, cTokenBalances]);

  return {
    isLoading,
    data,
    // TODO: handle errors and retry scenarios
  };
};

export default useGetMainAssets;
