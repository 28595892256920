import { useMemo } from 'react';

import { useGetPools } from 'clients/api';

const useGetUserPools = ({ accountAddress }: { accountAddress: string }) => {
  const { data: getPoolsData, isLoading: isGetPoolsLoading } = useGetPools({
    accountAddress,
  });

  const pools = getPoolsData?.pools || [];

  const userPools = useMemo(
    () =>
      pools.filter(
        pool =>
          !!pool.assets.find(
            asset => asset.userSupplyBalanceCents > 0 || asset.userBorrowBalanceCents > 0,
          ),
      ),
    [pools],
  );

  return {
    data: userPools,
    isLoading: isGetPoolsLoading,
  };
};

export default useGetUserPools;
