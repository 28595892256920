import React, { SVGProps } from 'react';

const SvgAirdrop = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <path
        d="M21 10C21 5.02944 17 1 11 1C5 1 1 5.02944 1 10M21 10L14 16M21 10C18 8 14 8 11 10M1 10L8 16M1 10C4 8 8 8 11 10M8 16H14M8 16V21H14V16M11 16V10"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgAirdrop;
