/** @jsxImportSource @emotion/react */
import React from 'react';
import { Paper } from '@mui/material';

import { GNFT } from 'types';

import { Spinner } from 'components';

import UserGnft from './UserGnft';

import { useStyles } from './styles';

type GnftsListProps = {
  loading?: boolean;
  accountAddress: string;
  nfts: GNFT[];
  noDataText: string;
  refetch: () => Promise<any>;
};

const GnftsList: React.FC<GnftsListProps> = ({ loading, accountAddress, nfts, noDataText, refetch }) => {
  const styles = useStyles();
  if (loading) {
    return (
      <div css={styles.gnftItems}>
        <Spinner />
      </div>
    );
  }
  return (
    <div css={styles.gnftItems}>
      {nfts.length ? (
        nfts.map(nft => <UserGnft refetch={refetch} accountAddress={accountAddress} nft={nft} key={nft.tokenId} />)
      ) : (
        <div css={styles.noData}>{noDataText}</div>
      )}
    </div>
  );
};

type UserNFTsProps = {
  accountAddress: string;
  isLoading?: boolean;
  gnfts: GNFT[];
  refetch: () => Promise<any>;
};

const UserGnfts: React.FC<UserNFTsProps> = ({ accountAddress, isLoading, gnfts, refetch }) => {
  const styles = useStyles();
  return (
    <>
      <Paper css={styles.card}>
        <h4 css={styles.title}>Collection</h4>
        <GnftsList
          nfts={gnfts}
          accountAddress={accountAddress}
          loading={isLoading}
          noDataText="No gNFT yet"
          refetch={refetch}
        />
      </Paper>
    </>
  );
};

export default UserGnfts;
