import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    container: css`
      padding: ${theme.spacing(3, 0, 10)};
    `,
    headerIcon: css`
      width: ${theme.spacing(16)};
      height: ${theme.spacing(16)};
      color: ${theme.palette.interactive.success};
      margin-bottom: ${theme.spacing(6)};
    `,
    title: css`
      margin-bottom: ${theme.spacing(4)};
      text-align: center;
    `,
    messageContainer: css`
      margin: auto;
      display: flex;
      align-items: center;
      margin-bottom: ${theme.spacing(10)};

      ${theme.breakpoints.down('sm')} {
        display: block;
      }
    `,
    amountContainer: css`
      display: flex;
      align-items: center;
      margin-left: ${theme.spacing(2)};

      ${theme.breakpoints.down('sm')} {
        margin-left: 0;
      }
    `,
    amountTokenIcon: css`
      margin-right: ${theme.spacing(1)};
    `,
    tpiBalance: css`
      margin-bottom: 20px;
    `,
    activeSegments: css`
      margin-bottom: 20px;
    `,
    burnNote: css`
      margin-top: 20px;
      margin-bottom: 20px;
    `,
    buttons: css`
      margin-top: 20px;
      display: flex;
      align-items: center;
      gap: 10px;
    `,
    imgWrapper: css`
      width: 100%;
      text-align: center;
      margin-bottom: 20px;
    `,
    lastSegmentInfo: css`
      padding: 16px;
      color: rgba(255, 255, 255, 0.6);
      background: #001b29;
      border: 1px solid #002a40;
      border-radius: 16px;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 20px;
    `,
    lastSegmentInfoTitle: css`
      color: #fff;
    `,

    infoBox: css`
      margin: 20px auto;
    `,

    successModalContent: css`
      text-align: center;

      & > div + div {
        margin-top: 10px;
      }

      a {
        color: #00A6FF;
      }
    `,
  };
};
