import { CToken, Token } from 'types';
import { getContractAddress } from 'utilities';
import Web3 from 'web3';
import { AbiItem } from 'web3-utils';

import { getWeb3NoAccount } from 'clients/web3';
import comptrollerAbi from 'constants/contracts/abis/comptroller.json';
import erc20Abi from 'constants/contracts/abis/erc20.json';
import interestModelAbi from 'constants/contracts/abis/interestModel.json';
// import maximillionAbi from 'constants/contracts/abis/maximillion.json';
import oracleAbi from 'constants/contracts/abis/oracle.json';
import tonpoundLensAbi from 'constants/contracts/abis/tonpoundLens.json';
import cErc20Abi from 'constants/contracts/abis/cErc20.json';
import cpTONTokenAbi from 'constants/contracts/abis/cpTonToken.json';
import gNFTAbi from 'constants/contracts/abis/gnft.json';
import gNFTSegmentManagerAbi from 'constants/contracts/abis/gnftSegmentManager.json';
import tpiViewerAbi from 'constants/contracts/abis/tpiViewer.json';
import treasuryViewAbi from 'constants/contracts/abis/treasuryView.json';
import tpiAirdropAbi from 'constants/contracts/abis/tpiAirdrop.json';
// import { TOKENS } from 'constants/tokens';
import {
  Comptroller,
  Erc20,
  InterestModel, // Maximillion,
  Oracle,
  TonpoundLens,
  Gnft,
  GnftSegmentManager,
  TpiViewer,
  TreasuryView,
  TpiAirdrop,
} from 'types/contracts';

import { CTokenContract, TokenContract } from './types';

const getContract = <T>(abi: AbiItem | AbiItem[], address: string, web3Instance: Web3) => {
  const web3 = web3Instance ?? getWeb3NoAccount();
  return (new web3.eth.Contract(abi, address) as unknown) as T;
};

export const getTokenContract = (token: Token, web3: Web3) =>
  getContract<TokenContract>(erc20Abi as AbiItem[], token.address, web3);

export const getTokenContractByAddress = (address: string, web3: Web3): Erc20 =>
  (getContract(erc20Abi as AbiItem[], address, web3) as unknown) as Erc20;

export const getCTokenContract = (cToken: CToken, web3: Web3) => {
  if (cToken.symbol === 'cpTON') {
    return (getContract(
      cpTONTokenAbi as AbiItem[],
      cToken.address,
      web3,
    ) as unknown) as CTokenContract<'pTON'>;
  }

  return (getContract(cErc20Abi as AbiItem[], cToken.address, web3) as unknown) as CTokenContract;
};

export const getComptrollerContract = (web3: Web3) =>
  (getContract(
    comptrollerAbi as AbiItem[],
    getContractAddress('comptroller'),
    web3,
  ) as unknown) as Comptroller;

export const getPriceOracleContract = (web3: Web3) =>
  (getContract(oracleAbi as AbiItem[], getContractAddress('oracle'), web3) as unknown) as Oracle;

export const getInterestModelContract = (address: string, web3: Web3) =>
  (getContract(interestModelAbi as AbiItem[], address, web3) as unknown) as InterestModel;

export const getTonpoundLensContract = (web3: Web3) =>
  (getContract(
    tonpoundLensAbi as AbiItem[],
    getContractAddress('tonpoundLens'),
    web3,
  ) as unknown) as TonpoundLens;

export const getGNFTContract = (web3: Web3) =>
  (getContract(gNFTAbi as AbiItem[], getContractAddress('gnft'), web3) as unknown) as Gnft;

export const getGNFTSegmentManagerContract = (web3: Web3) =>
  (getContract(
    gNFTSegmentManagerAbi as AbiItem[],
    getContractAddress('gnftSegmentManager'),
    web3,
  ) as unknown) as GnftSegmentManager;

export const getTpiViewerContract = (web3: Web3) =>
  (getContract(
    tpiViewerAbi as AbiItem[],
    getContractAddress('tpiViewer'),
    web3,
  ) as unknown) as TpiViewer;

export const getTreasuryViewContract = (web3: Web3) =>
  (getContract(
    treasuryViewAbi as AbiItem[],
    getContractAddress('treasuryView'),
    web3,
  ) as unknown) as TreasuryView;

export const getTpiAirdropContract = (web3: Web3) =>
  (getContract(
    tpiAirdropAbi as AbiItem[],
    getContractAddress('tpiAirdrop'),
    web3,
  ) as unknown) as TpiAirdrop;
