import * as React from 'react';
import { SVGProps } from 'react';

const SvgDashboard = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect
      x="3"
      y="3"
      width="7"
      height="9"
      rx="1"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <rect
      x="3"
      y="16"
      width="7"
      height="5"
      rx="1"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <rect
      x="21"
      y="21"
      width="7"
      height="9"
      rx="1"
      transform="rotate(-180 21 21)"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <rect
      x="21"
      y="8"
      width="7"
      height="5"
      rx="1"
      transform="rotate(-180 21 8)"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgDashboard;
