/** @jsxImportSource @emotion/react */
/* eslint-disable react/no-array-index-key */
import React, { useState, useMemo } from 'react';
import { formatUnits } from 'ethers/lib/utils';

import { GNFT } from 'types';

import { formatCentsToReadableValue, getCTokenByAddress } from 'utilities';

import gnftInfo from 'constants/gnftInfo';

import { Button } from 'components';
import { GnftModal } from 'components/GnftModal';

import { useStyles } from './styles';

type InfoItem = { title: string; value: any; isMain: boolean };

type GnftInfoItemsProps = {
  title: any;
  items: InfoItem[];
};

const GnftInfoItems: React.FC<GnftInfoItemsProps> = ({ title, items }) => {
  const styles = useStyles();
  return (
    <div css={styles.gnftInfoItems}>
      <div css={styles.gnftInfoItemHeader}>{title}</div>
      {items.map((item, index) => (
        <div key={index} css={styles.gnftInfoItem}>
          <div css={styles.gnftInfoItemTitle}>{item.title}</div>
          <div css={styles.gnftInfoItemValue}>{item.value}</div>
        </div>
      ))}
    </div>
  );
};

type UserGnftProps = {
  nft: GNFT;
  accountAddress: string;
  refetch: () => Promise<any>;
};

const UserGnft: React.FC<UserGnftProps> = ({ nft, accountAddress, refetch }) => {
  const styles = useStyles();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const info = gnftInfo[nft.meta.tokenType];

  const market = getCTokenByAddress(nft.meta.lockedMarketAddress);
  const lockedAmount = market ? parseFloat(formatUnits(nft.meta.lockedAmount, market.decimals)) : 0;

  const onOpenSegments = () => {
    setModalOpen(true);
  };

  const closeModal = () => setModalOpen(false);

  const nftTitle = useMemo(() => `${info.title} #${nft.tokenId}`, [info.title, nft.tokenId]);

  const isReady = nft.meta.activeSegment === 12;

  const items = useMemo(
    () =>
      ([
        {
          title: 'Collected parts',
          value: isReady ? 'Ready' : `${nft.meta.activeSegment}/12`,
        },
        {
          title: 'Voting weight',
          value: isReady ? nft.meta.voteWeight : '-',
        },
        {
          title: 'Rewards weight',
          value: isReady ? nft.meta.rewardWeight : '-',
        },
        {
          title: 'Used for mint',
          value: nft.meta.usedForMint ? 'Yes' : 'No',
        },
        {
          title: 'Locked Liquidity',
          value: market ? `${lockedAmount} ${market.symbol}` : '-',
        },
        {
          title: 'Pending rewards',
          value: nft.meta.pendingRewards
            ? formatCentsToReadableValue({ value: parseFloat(nft.meta.pendingRewards) * 100 })
            : '-',
        },
      ] as InfoItem[]).filter(Boolean),
    [info.title, nft, isReady],
  );

  const imgSrc = useMemo(() => {
    const imgName = nft.meta.activeSegment === 12 ? 'collected' : nft.meta.activeSegment;
    return `/jewelery/${info.title.toLowerCase()}/${imgName}.png`;
  }, [info.title, nft.meta.activeSegment]);

  return (
    <div css={styles.gnftItem}>
      <div css={styles.gnftItemImageWrapper}>
        <img css={styles.gnftItemImage} src={imgSrc} alt={nftTitle} />
      </div>
      <div css={styles.gnftInfo}>
        <GnftInfoItems title={nftTitle} items={items} />
        {nft.meta.activeSegment < 12 ? (
          <Button fullWidth onClick={onOpenSegments}>
            Open segments
          </Button>
        ) : null
        // <LiquidityButton
        //   tokenId={nft.tokenId}
        //   tokenType={nft.meta.tokenType}
        //   lockedAmount={lockedAmount}
        // />
        }
      </div>
      {modalOpen && (
        <GnftModal
          accountAddress={accountAddress}
          isOpen={modalOpen}
          gnft={nft}
          handleClose={closeModal}
          refetch={refetch}
        />
      )}
    </div>
  );
};

export default UserGnft;
