/* eslint-disable no-await-in-loop */
import { Gnft, TreasuryView } from 'types/contracts';
import { GNFT, GnftTokenType } from 'types';
import { formatEther } from 'ethers/lib/utils';
import { chunk } from 'lodash';

import getGnftTokenIdsInAccount from 'clients/api/queries/getGnftTokenIdsInAccount';

export interface GetGnftsInAccountInput {
  gnftContract: Gnft;
  treasuryViewContract: TreasuryView;
  treasuryAddress: string;
  accountAddress: string;
}

export type GetGnftsInAccountOutput = GNFT[];

const getGnftInAccount = async ({
  gnftContract,
  treasuryViewContract,
  treasuryAddress,
  accountAddress,
}: GetGnftsInAccountInput): Promise<GetGnftsInAccountOutput> => {
  const tokenIds = await getGnftTokenIdsInAccount({ gnftContract, accountAddress });

  const finishedTokenIds: number[] = [];

  const nfts: { [x: number]: GNFT } = {};

  for (let i = 0; i < tokenIds.length; i++) {
    const tokenId = tokenIds[i];

    try {
      const tokenData = await gnftContract.methods.getTokenData(tokenId).call();
      const [
        [
          tokenType,
          activeSegment,
          voteWeight,
          rewardWeight,
          usedForMint,
          completionTimestamp,
          lockedMarket,
        ],
        [lockedAmount],
      ] = tokenData;

      const activeSegments = parseInt(activeSegment, 10);

      if (activeSegments === 12) {
        finishedTokenIds.push(tokenId);
      }

      nfts[tokenId] = {
        tokenId,
        meta: {
          tokenType: parseInt(tokenType, 10) as GnftTokenType,
          activeSegment: activeSegments,
          voteWeight: parseInt(voteWeight, 10),
          rewardWeight: parseInt(rewardWeight, 10),
          completionTimestamp: parseInt(completionTimestamp, 10) * 1000,
          usedForMint,
          lockedMarketAddress: lockedMarket,
          lockedAmount,
          pendingRewards: '',
        },
      };
    } catch (e) {
      console.error('fetch gnft error', e);
    }
  }

  const pendingRewards: string[] = [];

  const tokenIdsChunks = chunk(finishedTokenIds, 20);

  try {
    for (let i = 0; i < tokenIdsChunks.length; i++) {
      const tokenIdsChunk = tokenIdsChunks[i];
      const res = await treasuryViewContract.methods
        .rewardMultipleIdsWithEvaluation(tokenIdsChunk, treasuryAddress)
        .call();
      pendingRewards.push(...res);
    }
    finishedTokenIds.forEach((tokenId, index) => {
      const rewards = pendingRewards[index];
      nfts[tokenId].meta.pendingRewards = formatEther(rewards);
    });
  } catch (e) {
    console.error('Pending Rewards fetch error', e, tokenIdsChunks, pendingRewards);
  }

  return Object.keys(nfts)
    .map(key => parseInt(key, 10))
    .sort((a, b) => b - a)
    .map(key => nfts[key]);
};

export default getGnftInAccount;
