import BigNumber from 'bignumber.js';
import { ContractCallContext, ContractCallResults } from 'ethereum-multicall';

import interestModelAbi from 'constants/contracts/abis/interestModel.json';

import formatToApySnapshots from './formatToApySnapshots';
import { GetCTokenApySimulationsOutput, GetCTokenInterestRatesInput } from './types';

export * from './types';

const REFERENCE_AMOUNT_WEI = 1e4;

const getCTokenApySimulations = async ({
  multicall,
  reserveFactorMantissa,
  interestRateModelContractAddress,
}: GetCTokenInterestRatesInput): Promise<GetCTokenApySimulationsOutput> => {
  const calls: ContractCallContext['calls'] = [];

  for (let u = 1; u <= 100; u++) {
    const utilizationRate = u / 100;
    const cashAmountWei = new BigNumber(1 / utilizationRate - 1)
      .times(REFERENCE_AMOUNT_WEI)
      .dp(0)
      .toFixed();

    const borrowsAmountWei = new BigNumber(REFERENCE_AMOUNT_WEI).toFixed();
    const reservesAmountWei = new BigNumber(0).toFixed();

    calls.push({
      reference: 'getBorrowRate',
      methodName: 'getBorrowRate',
      methodParameters: [cashAmountWei, borrowsAmountWei, reservesAmountWei],
    });

    calls.push({
      reference: 'getSupplyRate',
      methodName: 'getSupplyRate',
      methodParameters: [
        cashAmountWei,
        borrowsAmountWei,
        reservesAmountWei,
        reserveFactorMantissa.toFixed(),
      ],
    });
  }

  const contractCallContext: ContractCallContext = {
    reference: 'getCTokenRates',
    contractAddress: interestRateModelContractAddress,
    abi: interestModelAbi,
    calls,
  };

  const CTokenBalanceCallResults: ContractCallResults = await multicall.call(contractCallContext);
  const apySimulations = formatToApySnapshots({ CTokenBalanceCallResults });

  return { apySimulations };
};

export default getCTokenApySimulations;
