import { TonpoundLens } from 'types/contracts';

export interface GetCTokenBalancesAllInput {
  tonpoundLensContract: TonpoundLens;
  account: string;
  cTokenAddresses: string[];
}

interface GetCTokenBalancesAllResponse extends Array<string> {
  balanceOf: string;
  balanceOfUnderlying: string;
  borrowBalanceCurrent: string;
  tokenAllowance: string;
  tokenBalance: string;
  cToken: string;
}

interface GetCTokenBalanceOutput {
  balanceOf: string;
  balanceOfUnderlying: string;
  borrowBalanceCurrent: string;
  tokenAllowance: string;
  tokenBalance: string;
  cToken: string;
}

export type IGetCTokenBalancesAllOutput = {
  balances: GetCTokenBalanceOutput[];
};

const getCTokenBalancesAll = async ({
  tonpoundLensContract,
  cTokenAddresses,
  account,
}: GetCTokenBalancesAllInput): Promise<IGetCTokenBalancesAllOutput> => {
  const response = await tonpoundLensContract.methods
    .cTokenBalancesAll(cTokenAddresses, account?.toLowerCase())
    .call();

  // This is original returned as an array with these properties but at some
  // point the properties are getting removed from the type
  const balances = (response as unknown as GetCTokenBalancesAllResponse[]).map(item => ({
    balanceOf: item.balanceOf,
    balanceOfUnderlying: item.balanceOfUnderlying,
    borrowBalanceCurrent: item.borrowBalanceCurrent,
    tokenAllowance: item.tokenAllowance,
    tokenBalance: item.tokenBalance,
    cToken: item.cToken,
  }));

  return { balances };
};

export default getCTokenBalancesAll;
