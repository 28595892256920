import { CErc20, CpTonToken } from 'types/contracts';

export interface GetCTokenInterestRateModelInput {
  CTokenContract: CErc20 | CpTonToken;
}

export type GetCTokenInterestRateModelOutput = {
  contractAddress: string;
};

const getCTokenInterestRateModel = async ({
  CTokenContract,
}: GetCTokenInterestRateModelInput): Promise<GetCTokenInterestRateModelOutput> => {
  const contractAddress = await CTokenContract.methods.interestRateModel().call();

  return {
    contractAddress,
  };
};

export default getCTokenInterestRateModel;
