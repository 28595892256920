import BigNumber from 'bignumber.js';
import { GnftTokenType } from 'types';
import { Multicall } from 'ethereum-multicall';

import gnftSegmentManagerAbi from 'constants/contracts/abis/gnftSegmentManager.json';

import { getWeb3NoAccount } from 'clients/web3';

import { getContractAddress } from 'utilities';

export interface GetQuoteLiquidityForLockInput {
  tokenType: GnftTokenType;
  markets: string[];
}

export type GetQuoteLiquidityForLockOutput = {
  quoteLiquidityForLock: {
    cTokenAddress: string;
    amount: BigNumber;
  }[];
};

const getQuoteLiquidityForLock = async ({
  tokenType,
  markets,
}: GetQuoteLiquidityForLockInput): Promise<GetQuoteLiquidityForLockOutput> => {
  const multicall = new Multicall({ web3Instance: getWeb3NoAccount(), tryAggregate: true });
  const config = {
    reference: 'quoteLiquidityForLock',
    contractAddress: getContractAddress('gnftSegmentManager'),
    abi: gnftSegmentManagerAbi,
    calls: markets.map(marketAddress => ({
      reference: marketAddress,
      methodName: 'quoteLiquidityForLock',
      methodParameters: [marketAddress, tokenType],
    })),
  };

  const multicallResult = await multicall.call(config);

  const { results } = multicallResult;

  const quoteLiquidityForLock: {
    cTokenAddress: string;
    amount: BigNumber;
  }[] = [];

  results.quoteLiquidityForLock.callsReturnContext.forEach(item => {
    if (item.returnValues.length) {
      quoteLiquidityForLock.push({
        cTokenAddress: item.reference,
        amount: new BigNumber(item.returnValues[0].hex),
      });
    }
  });

  return {
    quoteLiquidityForLock,
  };
};

export default getQuoteLiquidityForLock;
