import BigNumber from 'bignumber.js';
import { QueryObserverOptions, useQuery } from 'react-query';
import { CToken } from 'types';

import getCTokenApySimulations, {
  GetCTokenApySimulationsOutput,
} from 'clients/api/queries/getCTokenApySimulations';
import useGetCTokenInterestRateModel from 'clients/api/queries/getCTokenInterestRateModel/useGetCTokenInterestRateModel';
import { useMulticall } from 'clients/web3';
import FunctionKey from 'constants/functionKey';

type Options = QueryObserverOptions<
  GetCTokenApySimulationsOutput,
  Error,
  GetCTokenApySimulationsOutput,
  GetCTokenApySimulationsOutput,
  [FunctionKey.GET_C_TOKEN_APY_SIMULATIONS, { cTokenAddress: string }]
>;

const useGetCTokenApySimulations = (
  { cToken, reserveFactorMantissa }: { cToken: CToken; reserveFactorMantissa?: BigNumber },
  options?: Options,
) => {
  const multicall = useMulticall();
  const { data: interestRateModelData } = useGetCTokenInterestRateModel({ cToken });

  return useQuery(
    [FunctionKey.GET_C_TOKEN_APY_SIMULATIONS, { cTokenAddress: cToken.address }],
    () =>
      getCTokenApySimulations({
        multicall,
        reserveFactorMantissa: reserveFactorMantissa || new BigNumber(0),
        interestRateModelContractAddress: interestRateModelData?.contractAddress || '',
      }),
    {
      ...options,
      enabled:
        (options?.enabled === undefined || options?.enabled) &&
        interestRateModelData?.contractAddress !== undefined &&
        reserveFactorMantissa !== undefined,
    },
  );
};

export default useGetCTokenApySimulations;
