import { checkForComptrollerTransactionError } from 'errors';
import type { TransactionReceipt } from 'web3-core/types';

import { TpiAirdrop } from 'types/contracts';

export interface ClaimTpiAirdropInput {
  tpiAirdropContract: TpiAirdrop;
  nonce: string;
  proofs: string[];
  fromAccountAddress: string;
}

export type ClaimTpiAirdropOutput = TransactionReceipt;

const claimTpiAirdrop = async ({
  tpiAirdropContract,
  nonce,
  proofs,
  fromAccountAddress,
}: ClaimTpiAirdropInput): Promise<ClaimTpiAirdropOutput> => {
  // Fetch list of tokens for which user have a positive balance, since these
  // are the tokens susceptible to have generated TPI rewards
  // Send query to claim TPI reward
  const resp = await tpiAirdropContract.methods.claim(
    fromAccountAddress,
    '800000000000000000000',
    nonce,
    proofs,
  ).send({
    from: fromAccountAddress,
  });
  return checkForComptrollerTransactionError(resp);
};

export default claimTpiAirdrop;
