import BigNumber from 'bignumber.js';
import { ContractCallContext, Multicall } from 'ethereum-multicall';

import { getWeb3NoAccount } from 'clients/web3';

import { CToken } from 'types';
import { convertWeiToTokens, getContractAddress, getCTokenByAddress } from 'utilities';

import { CERC_TOKENS } from 'constants/tokens';

import erc20Abi from 'constants/contracts/abis/erc20.json';
import cErc20Abi from 'constants/contracts/abis/cErc20.json';
import cpTONAbi from 'constants/contracts/abis/cpTonToken.json';
import oracleAbi from 'constants/contracts/abis/oracle.json';

// export interface GetTreasuryTotalInput {}

export type TreasuryBalances = {
  [x: string]: {
    cToken: CToken;
    reserve: BigNumber;
    balance: BigNumber;
    total: BigNumber;
    totalUsd: BigNumber;
  };
};

export type GetTreasuryBalancesOutput = {
  balances: TreasuryBalances;
  totalUsd: BigNumber;
};

const getTreasuryBalances = async (): Promise<GetTreasuryBalancesOutput> => {
  const multicall = new Multicall({ web3Instance: getWeb3NoAccount(), tryAggregate: true });

  const cTokensCalls: ContractCallContext[] = [];
  const tokensCalls: ContractCallContext[] = [];
  const oracleCalls: ContractCallContext[] = [];

  Object.keys(CERC_TOKENS).forEach((key: any) => {
    const cToken = (CERC_TOKENS as any)[key] as CToken;

    const tokenAbi = cToken.symbol === 'cpTon' ? cpTONAbi : cErc20Abi;

    cTokensCalls.push({
      reference: cToken.symbol,
      contractAddress: cToken.address,
      abi: tokenAbi,
      calls: [
        {
          reference: 'totalReserves',
          methodName: 'totalReserves',
          methodParameters: [],
        },
      ],
    });

    tokensCalls.push({
      reference: cToken.symbol,
      contractAddress: cToken.underlyingToken.address,
      abi: erc20Abi,
      calls: [
        {
          reference: 'balanceOfTreasury',
          methodName: 'balanceOf',
          methodParameters: [getContractAddress('treasury')],
        },
      ],
    });

    oracleCalls.push({
      reference: cToken.symbol,
      contractAddress: getContractAddress('oracle'),
      abi: oracleAbi,
      calls: [
        {
          reference: 'getUnderlyingPrice',
          methodName: 'getUnderlyingPrice',
          methodParameters: [cToken.address],
        },
      ],
    });
  });

  const { results: cTokensCallsResult } = await multicall.call(cTokensCalls);
  const { results: tokensCallsResult } = await multicall.call(tokensCalls);
  const { results: oracleCallResult } = await multicall.call(oracleCalls);

  let totalUsd = new BigNumber(0);

  const balances = Object.keys(cTokensCallsResult).reduce((acc, key: string) => {
    const cToken = getCTokenByAddress(
      cTokensCallsResult[key].originalContractCallContext.contractAddress,
    );
    const reserveWei = new BigNumber(
      cTokensCallsResult[key].callsReturnContext[0].returnValues[0].hex,
    );
    const balanceWei = new BigNumber(
      tokensCallsResult[key].callsReturnContext[0].returnValues[0].hex,
    );
    if (cToken) {
      const tokenPriceWei = new BigNumber(oracleCallResult[key].callsReturnContext[0].returnValues[0].hex);
      const tokenPrice = tokenPriceWei.div(new BigNumber(10).pow(36 - cToken.underlyingToken.decimals));
      const reserve = convertWeiToTokens({ valueWei: reserveWei, token: cToken.underlyingToken });
      const balance = convertWeiToTokens({ valueWei: balanceWei, token: cToken.underlyingToken });
      const tokenTotal = reserve.plus(balance);
      const tokenTotalUsd = tokenTotal.multipliedBy(tokenPrice);

      totalUsd = totalUsd.plus(tokenTotalUsd);

      acc[key] = {
        cToken,
        reserve,
        balance,
        total: tokenTotal,
        totalUsd: tokenTotalUsd,
      };
    }

    return acc;
  }, {} as TreasuryBalances);

  return {
    balances,
    totalUsd,
  };
};

export default getTreasuryBalances;
