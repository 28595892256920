import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    header: css`
      margin-bottom: ${theme.spacing(6)};
    `,
    headerBottomRow: css`
      display: flex;
      align-items: center;
      justify-content: space-between;

      ${theme.breakpoints.down('md')} {
        display: block;
      }
    `,
    title: css`
      margin-bottom: 20px;
    `,
    rightColumn: css`
      display: flex;
      align-items: center;
    `,
    tabletButtonGroup: css`
      margin-bottom: ${theme.spacing(6)};
    `,
    tabletSearchTextField: css`
      width: 100%;
      margin-bottom: ${theme.spacing(6)};
    `,
    desktopSearchTextField: css`
      margin-left: ${theme.spacing(8)};
      min-width: ${theme.spacing(75)};
    `,
    desktopContainer: css`
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: ${theme.spacing(6)};
      margin-bottom: 20px;
    `,
    row: css`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 32px;
      gap: 32px;

      @media (max-width: 1279.95px) {
        flex-direction: column;
      }
    `,
    column: css`
      flex: 1;

      @media (max-width: 991px) {
        border: none;
        padding: 0;
      }
    `,
    columnTable: css`
      padding-left: 0;
      padding-right: 0;

      .supplied-market-table,
      .borrowed-market-table { 
        margin-bottom: 20px;
      }
    `,
  };
};
