type PushArgs = {
  event: string;
  event_context: string;
  event_content?: string;
  event_auth?: string;
  event_value?: string;
};

type DataLayer = {
  push: (args: PushArgs) => void;
};

const dataLayerMock: DataLayer = {
  push: (args: any) => console.log('dataLayer.push', args),
};

const useDataLayer = (): DataLayer => {
  if (window && window.dataLayer) return window.dataLayer as DataLayer;
  return dataLayerMock;
};

export default useDataLayer;
