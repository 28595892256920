/** @jsxImportSource @emotion/react */
import React from 'react';
import { Paper } from '@mui/material';

import Header from './Header';
import Table from './Table';

import { useStyles } from './styles';

const Tpi: React.FC = () => {
  const styles = useStyles();

  return (
    <div>
      <Header css={styles.header} />
      <Paper css={styles.paper}>
        <Table />
      </Paper>
    </div>
  );
};

export default Tpi;
