import { css } from '@emotion/react';

export const useStyles = () => ({
  title: css`
    margin-bottom: 20px;
  `,
  card: css`
    margin-bottom: 20px;
  `,
  gnftItems: css`
    display: flex;
    gap: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    flex-wrap: wrap;
  `,
  gnftItem: css`
    overflow: hidden;
    background: #101112;
    border: 1px solid #25272B;
    border-radius: 15px;
    max-width: 275px;
    width: 100%;
  `,
  mintGnftItems: css`
    flex-wrap: nowrap;
  `,
  mintGnftItem: css`
    flex: 1;
    margin-bottom: 10px;
    max-width: unset;
  `,
  gnftItemImageWrapper: css`
    min-height: 180px;
    text-align: center;
    background: #101112;
  `,
  gnftItemImage: css`
    margin-top: 10px;
    max-width: 160px;
    max-height: 160px;
  `,
  gnftItemHeader: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 20px;
  `,
  gnftItemTitle: css`
    text-overflow: ellipsis;
    overflow: hidden;
  `,
  gnftItemMore: css``,
  gnftInfo: css`
    padding: 16px 20px 18px;
  `,
  gnftInfoItems: css`
      margin-bottom: 20px;
  `,
  gnftInfoItem: css`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  gnftInfoItemHeader: css`
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    font-size: 16px;
    margin-bottom: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid #25272B;
  `,
  gnftInfoItemTitle: css`
    opacity: 0.5;
  `,
  gnftInfoItemValue: css`
      
  `,
  noData: css`
    text-align: center;
    margin: 20px auto;
  `,
});
