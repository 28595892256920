import { Alchemy, Network } from 'alchemy-sdk';

const API_KEY = process.env.REACT_APP_ALCHEMY_API_KEY;

const settings = {
  apiKey: API_KEY,
  network: Network.ETH_GOERLI,
};

const alchemyClient = new Alchemy(settings);

export default alchemyClient;
