import { MutationObserverOptions, useMutation } from 'react-query';

import {
  ClaimTpiAirdropInput,
  ClaimTpiAirdropOutput,
  claimTpiAirdrop,
  queryClient,
} from 'clients/api';
import { useTpiAirdropContract } from 'clients/contracts/hooks';
import FunctionKey from 'constants/functionKey';

type Options = MutationObserverOptions<
ClaimTpiAirdropOutput,
  Error,
  Omit<ClaimTpiAirdropInput, 'tpiAirdropContract'>
>;

const useClaimTpiAirdrop = (options?: Options) => {
  const tpiAirdropContract = useTpiAirdropContract();

  return useMutation(
    FunctionKey.CLAIM_TPI_AIRDROP,
    (params: Omit<ClaimTpiAirdropInput, 'tpiAirdropContract'>) =>
      claimTpiAirdrop({
        tpiAirdropContract,
        ...params,
      }),
    {
      ...options,
      onSuccess: async (...onSuccessParams) => {
        queryClient.resetQueries(FunctionKey.GET_AIRDROP);

        if (options?.onSuccess) {
          options.onSuccess(...onSuccessParams);
        }
      },
    },
  );
};

export default useClaimTpiAirdrop;
