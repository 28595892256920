/** @jsxImportSource @emotion/react */
import BigNumber from 'bignumber.js';
import {
  AccountData,
  ConnectWallet,
  FormikSubmitButton,
  FormikTokenTextField,
  IsolatedAssetWarning,
  Spinner,
} from 'components';
import { VError } from 'errors';
import React, { useContext } from 'react';
import { useTranslation } from 'translation';
import { Asset, CToken, Pool } from 'types';
import { areTokensEqual, convertTokensToWei, formatTokensToReadableValue } from 'utilities';
import type { TransactionReceipt } from 'web3-core/types';

import { useBorrow, useGetPool } from 'clients/api';
import { SAFE_BORROW_LIMIT_PERCENTAGE } from 'constants/safeBorrowLimitPercentage';
import { AmountForm, AmountFormProps, ErrorCode } from 'containers/AmountForm';
import { AuthContext } from 'context/AuthContext';
import useHandleTransactionMutation from 'hooks/useHandleTransactionMutation';
import useDataLayer from 'hooks/useDataLayer';

import { useStyles } from '../styles';
import Notice from './Notice';
import TEST_IDS from './testIds';

// TODO: add stories

export interface BorrowFormProps {
  asset: Asset;
  pool: Pool;
  limitTokens: string;
  safeBorrowLimitPercentage: number;
  safeLimitTokens: string;
  borrow: (amountWei: BigNumber) => Promise<TransactionReceipt>;
  isBorrowLoading: boolean;
  hasUserCollateralizedSuppliedAssets: boolean;
}

export const BorrowForm: React.FC<BorrowFormProps> = ({
  asset,
  pool,
  limitTokens,
  safeBorrowLimitPercentage,
  safeLimitTokens,
  borrow,
  isBorrowLoading,
  hasUserCollateralizedSuppliedAssets,
}) => {
  const { t, Trans } = useTranslation();
  const sharedStyles = useStyles();

  const handleTransactionMutation = useHandleTransactionMutation();

  const readableTokenBorrowableAmount = React.useMemo(
    () =>
      formatTokensToReadableValue({
        value: new BigNumber(limitTokens),
        token: asset.cToken.underlyingToken,
      }),
    [limitTokens],
  );

  const onSubmit: AmountFormProps['onSubmit'] = async amountTokens => {
    const formattedAmountTokens = new BigNumber(amountTokens);

    const amountWei = convertTokensToWei({
      value: formattedAmountTokens,
      token: asset.cToken.underlyingToken,
    });

    return handleTransactionMutation({
      mutate: () => borrow(amountWei),
      successTransactionModalProps: transactionReceipt => ({
        title: t('borrowRepayModal.borrow.successfulTransactionModal.title'),
        content: t('borrowRepayModal.borrow.successfulTransactionModal.message'),
        amount: {
          valueWei: amountWei,
          token: asset.cToken.underlyingToken,
        },
        transactionHash: transactionReceipt.transactionHash,
      }),
    });
  };

  return (
    <AmountForm onSubmit={onSubmit} maxAmount={limitTokens}>
      {({ values, dirty, isValid, errors }) => (
        <>
          {pool.isIsolated && (
            <IsolatedAssetWarning
              pool={pool}
              token={asset.cToken.underlyingToken}
              type="borrow"
              css={sharedStyles.isolatedAssetWarning}
            />
          )}

          <div css={[sharedStyles.getRow({ isLast: true })]}>
            <FormikTokenTextField
              name="amount"
              token={asset.cToken.underlyingToken}
              disabled={isBorrowLoading || !hasUserCollateralizedSuppliedAssets}
              rightMaxButton={{
                label: t('borrowRepayModal.borrow.rightMaxButtonLabel', {
                  limitPercentage: safeBorrowLimitPercentage,
                }),
                valueOnClick: safeLimitTokens,
              }}
              data-testid={TEST_IDS.tokenTextField}
              // Only display error state if amount is higher than borrow limit
              hasError={errors.amount === ErrorCode.HIGHER_THAN_MAX}
              description={
                <Trans
                  i18nKey="borrowRepayModal.borrow.borrowableAmount"
                  components={{
                    White: <span css={sharedStyles.whiteLabel} />,
                  }}
                  values={{ amount: readableTokenBorrowableAmount }}
                />
              }
            />

            <Notice
              hasUserCollateralizedSuppliedAssets={hasUserCollateralizedSuppliedAssets}
              amount={values.amount}
              safeLimitTokens={safeLimitTokens}
              limitTokens={limitTokens}
              asset={asset}
            />
          </div>

          <AccountData
            asset={asset}
            pool={pool}
            amountTokens={new BigNumber(values.amount || 0)}
            action="borrow"
          />

          <FormikSubmitButton
            loading={isBorrowLoading}
            disabled={!isValid || !dirty || isBorrowLoading || !hasUserCollateralizedSuppliedAssets}
            fullWidth
            enabledLabel={t('borrowRepayModal.borrow.submitButton')}
            disabledLabel={t('borrowRepayModal.borrow.submitButtonDisabled')}
          />
        </>
      )}
    </AmountForm>
  );
};

export interface BorrowProps {
  cToken: CToken;
  poolComptrollerAddress: string;
  onClose: () => void;
}

const Borrow: React.FC<BorrowProps> = ({ cToken, poolComptrollerAddress, onClose }) => {
  const { t } = useTranslation();
  const { account: { address: accountAddress = '' } = {} } = useContext(AuthContext);
  const dataLayer = useDataLayer();

  const { data: getPoolData } = useGetPool({
    poolComptrollerAddress,
    accountAddress,
  });
  const pool = getPoolData?.pool;
  const asset = pool?.assets.find(item => areTokensEqual(item.cToken, cToken));

  const hasUserCollateralizedSuppliedAssets = React.useMemo(
    () =>
      !!pool &&
      pool.assets.some(
        userAsset =>
          userAsset.isCollateralOfUser && userAsset.userSupplyBalanceTokens.isGreaterThan(0),
      ),
    [pool?.assets],
  );

  const { mutateAsync: borrow, isLoading: isBorrowLoading } = useBorrow({
    cToken,
  });

  const handleBorrow: BorrowFormProps['borrow'] = async amountWei => {
    if (!accountAddress) {
      throw new VError({ type: 'unexpected', code: 'walletNotConnected' });
    }
    const args = {
      event: 'tonpound_event',
      event_context: 'borrow_start',
      event_content: cToken.underlyingToken.symbol,
      event_auth: accountAddress,
    };
    dataLayer.push(args);
    const res = await borrow({
      amountWei,
      fromAccountAddress: accountAddress,
    });
    if (asset) {
      const { decimals } = asset.cToken.underlyingToken;

      const value = amountWei.div(new BigNumber(10).pow(decimals));
      dataLayer.push({
        ...args,
        event_context: 'borrow_success',
        event_value: (asset.tokenPriceDollars || new BigNumber('0')).multipliedBy(value).toFixed(0),
      });
    }
    // Close modal on success
    onClose();
    return res;
  };

  // Calculate maximum and safe maximum amount of tokens user can borrow
  const [limitTokens, safeLimitTokens] = React.useMemo(() => {
    // Return 0 values while asset is loading or if borrow limit has been
    // reached
    const userBorrowBalanceCents = pool?.userBorrowBalanceCents || 0;
    if (
      !asset ||
      !pool ||
      // !pool.userBorrowBalanceCents ||
      !pool.userBorrowLimitCents ||
      userBorrowBalanceCents >= pool.userBorrowLimitCents
    ) {
      return ['0', '0'];
    }

    const marginWithBorrowLimitDollars =
      (pool.userBorrowLimitCents - userBorrowBalanceCents) /
      // Convert cents to dollars
      100;

    const liquidityDollars = asset.liquidityCents / 100;
    let maxTokens = BigNumber.minimum(liquidityDollars, marginWithBorrowLimitDollars)
      // Convert dollars to tokens
      .dividedBy(asset.tokenPriceDollars);

    // Take borrow cap in consideration if asset has one
    if (asset.borrowCapTokens) {
      const marginWithBorrowCapTokens = asset.borrowCapTokens.minus(asset.borrowBalanceTokens);
      maxTokens = marginWithBorrowCapTokens.isLessThanOrEqualTo(0)
        ? new BigNumber(0)
        : BigNumber.minimum(maxTokens, marginWithBorrowCapTokens);
    }

    const safeBorrowLimitCents = (pool.userBorrowLimitCents * SAFE_BORROW_LIMIT_PERCENTAGE) / 100;
    const marginWithSafeBorrowLimitDollars =
      (safeBorrowLimitCents - userBorrowBalanceCents) /
      // Convert cents to dollars
      100;

    const safeMaxTokens =
      userBorrowBalanceCents < safeBorrowLimitCents
        ? // Convert dollars to tokens
          new BigNumber(marginWithSafeBorrowLimitDollars).dividedBy(asset.tokenPriceDollars)
        : new BigNumber(0);

    const formatValue = (value: BigNumber) =>
      value.dp(cToken.underlyingToken.decimals, BigNumber.ROUND_DOWN).toFixed();

    return [formatValue(maxTokens), formatValue(safeMaxTokens)];
  }, [
    cToken.underlyingToken.decimals,
    asset?.tokenPriceDollars,
    asset?.liquidityCents,
    pool?.userBorrowLimitCents,
    pool?.userBorrowBalanceCents,
  ]);

  return (
    <ConnectWallet message={t('borrowRepayModal.borrow.connectWalletMessage')}>
      {asset && pool ? (
        <BorrowForm
          asset={asset}
          pool={pool}
          limitTokens={limitTokens}
          safeBorrowLimitPercentage={SAFE_BORROW_LIMIT_PERCENTAGE}
          safeLimitTokens={safeLimitTokens}
          borrow={handleBorrow}
          isBorrowLoading={isBorrowLoading}
          hasUserCollateralizedSuppliedAssets={hasUserCollateralizedSuppliedAssets}
        />
      ) : (
        <Spinner />
      )}
    </ConnectWallet>
  );
};

export default Borrow;
