import { QueryObserverOptions, useQuery } from 'react-query';
import { CToken } from 'types';

import getCTokenInterestRateModel, {
  GetCTokenInterestRateModelOutput,
} from 'clients/api/queries/getCTokenInterestRateModel';
import { useCTokenContract } from 'clients/contracts/hooks';
import FunctionKey from 'constants/functionKey';

type Options = QueryObserverOptions<
  GetCTokenInterestRateModelOutput,
  Error,
  GetCTokenInterestRateModelOutput,
  GetCTokenInterestRateModelOutput,
  [FunctionKey.GET_C_TOKEN_INTEREST_RATE_MODEL, { cTokenAddress: string }]
>;

const useGetCTokenInterestRateModel = ({ cToken }: { cToken: CToken }, options?: Options) => {
  const CTokenContract = useCTokenContract(cToken);

  return useQuery(
    [FunctionKey.GET_C_TOKEN_INTEREST_RATE_MODEL, { cTokenAddress: cToken.address }],
    () => getCTokenInterestRateModel({ CTokenContract }),
    options,
  );
};

export default useGetCTokenInterestRateModel;
