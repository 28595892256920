import React, { SVGProps } from 'react';

const SvgTpi = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <path
        d="M12.0009 1L21.5272 6.5V17.5L12.0009 23L2.47461 17.5V6.5L12.0009 1Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path d="M12 17L7 9L17 9L12 17Z" stroke="currentColor" strokeWidth="2" strokeLinejoin="round" />
    </g>
  </svg>
);

export default SvgTpi;
