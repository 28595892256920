/** @jsxImportSource @emotion/react */
import { Spinner } from 'components';
import React, { useContext } from 'react';
import { Pool } from 'types';

import { useGetUserPools } from 'clients/api';
import { AuthContext } from 'context/AuthContext';

import PoolBreakdown from './PoolBreakdown';
import Summary from './Summary';
import { useStyles } from './styles';

export interface AccountUiProps {
  pools: Pool[];
  isFetchingPools?: boolean;
}

export const AccountUi: React.FC<AccountUiProps> = ({ isFetchingPools, pools }) => {
  const styles = useStyles();

  if (isFetchingPools) {
    return <Spinner />;
  }

  return (
    <>
      <Summary css={styles.section} pools={pools} />

      {pools.map(pool => (
        <PoolBreakdown key={`pool-breakdown-${pool.name}`} css={styles.section} pool={pool} />
      ))}
    </>
  );
};

const Account: React.FC = () => {
  const { account } = useContext(AuthContext);
  const { data: pools, isLoading: isGetPoolsLoading } = useGetUserPools({
    accountAddress: account?.address || '',
  });

  return <AccountUi isFetchingPools={isGetPoolsLoading} pools={pools} />;
};

export default Account;
