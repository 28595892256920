/** @jsxImportSource @emotion/react */
/* eslint-disable react/no-array-index-key */
import React from 'react';

import { Cell } from 'components/CellGroup';

import StatsItem from './StatsItem';
import { useStyles } from './styles';

type StatsContainerProps = {
  cells: Cell[];
};

const StatsContainer: React.FC<StatsContainerProps> = ({ cells }) => {
  const styles = useStyles();
  return (
    <div css={styles.statsContainer}>
      {cells.map((cell, index) => (
        <StatsItem key={index} {...cell} />
      ))}
    </div>
  );
};

export default StatsContainer;
