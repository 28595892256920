enum FunctionKey {
  // Queries
  GET_AIRDROP = 'GET_AIRDROP',
  GET_BLOCK_NUMBER = 'GET_BLOCK_NUMBER',
  GET_VAI_TREASURY_PERCENTAGE = 'GET_VAI_TREASURY_PERCENTAGE',
  GET_MAIN_MARKETS = 'GET_MAIN_MARKETS',
  GET_MAIN_MARKETS_SUBGRAPH = 'GET_MAIN_MARKETS_SUBGRAPH',
  GET_MARKET_HISTORY = 'GET_MARKET_HISTORY',
  GET_ASSETS_IN_ACCOUNT = 'GET_ASSETS_IN_ACCOUNT',
  GET_TONPOUND_ACCRUED = 'GET_TONPOUND_ACCRUED',
  GET_TONPOUND_VAI_STATE = 'GET_TONPOUND_VAI_STATE',
  GET_MINTED_VAI = 'GET_MINTED_VAI',
  GET_TPI_REWARD = 'GET_TPI_REWARD',
  GET_TOKEN_ALLOWANCE = 'GET_TOKEN_ALLOWANCE',
  GET_BALANCE_OF = 'GET_BALANCE_OF',
  GET_TOKEN_BALANCES = 'GET_TOKEN_BALANCES',
  GET_MINTABLE_VAI = 'GET_MINTABLE_VAI',
  GET_VRT_CONVERSION_END_TIME = 'GET_VRT_CONVERSION_END_TIME',
  GET_VRT_CONVERSION_RATIO = 'GET_VRT_CONVERSION_RATIO',
  GET_TPI_WITHDRAWABLE_AMOUNT = 'GET_TPI_WITHDRAWABLE_AMOUNT',
  GET_TONPOUND_VAI_VAULT_DAILY_RATE = 'GET_TONPOUND_VAI_VAULT_DAILY_RATE',
  GET_TONPOUND_VAI_VAULT_RATE = 'GET_TONPOUND_VAI_VAULT_RATE',
  GET_C_TOKEN_BALANCE = 'GET_C_TOKEN_BALANCE',
  GET_C_TOKEN_BALANCES_ALL = 'GET_C_TOKEN_BALANCES_ALL',
  GET_C_TOKEN_INTEREST_RATE_MODEL = 'GET_C_TOKEN_INTEREST_RATE_MODEL',
  GET_C_TOKEN_APY_SIMULATIONS = 'GET_C_TOKEN_APY_SIMULATIONS',
  GET_TRANSACTIONS = 'GET_TRANSACTIONS',
  GET_TPI_VAULT_REWARD_PER_BLOCK = 'GET_TPI_VAULT_REWARD_PER_BLOCK',
  GET_TPI_VAULT_TOTAL_ALLOCATION_POINTS = 'GET_TPI_VAULT_TOTAL_ALLOCATION_POINTS',
  GET_TPI_VAULT_POOL_INFOS = 'GET_TPI_VAULT_POOL_INFOS',
  GET_TPI_VAULT_PENDING_REWARD = 'GET_TPI_VAULT_PENDING_REWARD',
  GET_TPI_VAULT_USER_INFO = 'GET_TPI_VAULT_USER_INFO',
  GET_TPI_VAULT_POOLS_COUNT = 'GET_TPI_VAULT_POOLS_COUNT',
  GET_TPI_VAULT_WITHDRAWAL_REQUESTS = 'GET_TPI_VAULT_WITHDRAWAL_REQUESTS',
  GET_CURRENT_VOTES = 'GET_CURRENT_VOTES',
  GET_VOTERS = 'GET_VOTERS',
  GET_PENDING_TPI = 'GET_PENDING_TPI',
  GET_PROPOSALS = 'GET_PROPOSALS',
  GET_PROPOSAL = 'GET_PROPOSAL',
  GET_VOTE_RECEIPT = 'GET_VOTE_RECEIPT',
  GET_VAI_VAULT_USER_INFO = 'GET_VAI_VAULT_USER_INFO',
  GET_VOTE_DELEGATE_ADDRESS = 'GET_VOTE_DELEGATE_ADDRESS',
  GET_PROPOSAL_STATE = 'GET_PROPOSAL_STATE',
  GET_LATEST_PROPOSAL_ID_BY_PROPOSER = 'GET_LATEST_PROPOSAL_ID_BY_PROPOSER',
  GET_VAI_VAULT_PENDING_TPI = 'GET_VAI_VAULT_PENDING_TPI',
  GET_VRT_VAULT_INTEREST_RATE_PER_BLOCK = 'GET_VRT_VAULT_INTEREST_RATE_PER_BLOCK',
  GET_VRT_VAULT_USER_INFO = 'GET_VRT_VAULT_USER_INFO',
  GET_VRT_VAULT_ACCRUED_INTEREST = 'GET_VRT_VAULT_ACCRUED_INTEREST',
  GET_VOTER_ACCOUNTS = 'GET_VOTER_ACCOUNTS',
  GET_VOTER_DETAILS = 'GET_VOTER_DETAILS',
  GET_VOTER_HISTORY = 'GET_VOTER_HISTORY',
  GET_PROPOSAL_THRESHOLD = 'GET_PROPOSAL_THRESHOLD',
  GET_PROPOSAL_ETA = 'GET_PROPOSAL_ETA',
  GET_PANCAKE_SWAP_PAIRS = 'GET_PANCAKE_SWAP_PAIRS',
  GET_GNFT_IN_ACCOUNT = 'GET_GNFT_IN_ACCOUNT',
  GET_GNFT_IDS_IN_ACCOUNT = 'GET_GNFT_IDS_IN_ACCOUNT',
  GET_GNFT_SEGMENT_ACTIVATION_PRICE = 'GET_GNFT_SEGMENT_ACTIVATION_PRICE',
  GET_STATISTIC = 'GET_STATISTIC',
  GET_EASTER_EGGS = 'GET_EASTER_EGGS',

  // Mutations
  MINT_GNFT = 'MINT_GNFT',
  MINT_VAI = 'MINT_VAI',
  ENTER_MARKETS = 'ENTER_MARKETS',
  EXIT_MARKET = 'EXIT_MARKET',
  REPAY_VAI = 'REPAY_VAI',
  APPROVE_TOKEN = 'APPROVE_TOKEN',
  APPROVE_VRT = 'APPROVE_VRT',
  CONVERT_VRT = 'CONVERT_VRT',
  SUPPLY = 'SUPPLY',
  REDEEM = 'REDEEM',
  REDEEM_UNDERLYING = 'REDEEM_UNDERLYING',
  CLAIM_TPI_REWARD = 'CLAIM_TPI_REWARD',
  CLAIM_TPI_AIRDROP = 'CLAIM_TPI_AIRDROP',
  REPAY = 'REPAY',
  BORROW = 'BORROW',
  WITHDRAW_TPI = 'WITHDRAW_TPI',
  CREATE_PROPOSAL = 'CREATE_PROPOSAL',
  EXECUTE_PROPOSAL = 'EXECUTE_PROPOSAL',
  QUEUE_PROPOSAL = 'QUEUE_PROPOSAL',
  CANCEL_PROPOSAL = 'CANCEL_PROPOSAL',
  SET_VOTE_DELEGATE = 'SET_VOTE_DELEGATE',
  CLAIM_VAI_VAULT_REWARD = 'CLAIM_VAI_VAULT_REWARD',
  CLAIM_VRT_VAULT_REWARD = 'CLAIM_VRT_VAULT_REWARD',
  CLAIM_TPI_VAULT_REWARD = 'CLAIM_TPI_VAULT_REWARD',
  CAST_VOTE = 'CAST_VOTE',
  CAST_VOTE_WITH_REASON = 'CAST_VOTE_WITH_REASON',
  STAKE_IN_TPI_VAULT = 'STAKE_IN_TPI_VAULT',
  STAKE_IN_VAI_VAULT = 'STAKE_IN_VAI_VAULT',
  STAKE_IN_VRT_VAULT = 'STAKE_IN_VRT_VAULT',
  WITHDRAW_FROM_VAI_VAULT = 'WITHDRAW_FROM_VAI_VAULT',
  WITHDRAW_FROM_VRT_VAULT = 'WITHDRAW_FROM_VRT_VAULT',
  REQUEST_WITHDRAWAL_FROM_TPI_VAULT = 'REQUEST_WITHDRAWAL_FROM_TPI_VAULT',
  EXECUTE_WITHDRAWAL_FROM_TPI_VAULT = 'EXECUTE_WITHDRAWAL_FROM_TPI_VAULT',
  SWAP_TOKENS = 'SWAP_TOKENS',
  ACTIVATE_GNFT_SEGMENTS = 'ACTIVATE_GNFT_SEGMENTS',
  ACTIVATE_GNFT_SEGMENT_WITH_PROOF = 'ACTIVATE_GNFT_SEGMENT_WITH_PROOF',
  LOCK_LIQUIDITY_ON_GNFT = 'LOCK_LIQUIDITY_ON_GNFT',
  UNLOCK_LIQUIDITY_ON_GNFT = 'UNLOCK_LIQUIDITY_ON_GNFT',
  SET_AIRDROP_SELECTION = 'SET_AIRDROP_SELECTION',
  CLAIM_EASTER_EGG = 'CLAIM_EASTER_EGG',
}

export default FunctionKey;
