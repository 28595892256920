/** @jsxImportSource @emotion/react */

import React, { useMemo } from 'react';
import BigNumber from 'bignumber.js';

import { Token, TPIStatistic } from 'types';

import {
  convertWeiToTokens,
  formatTokensToReadableValue,
  getContractAddress,
  getTokenByAddress,
} from 'utilities';

import { TokenIcon } from 'components';
import StatsContainer from 'components/Stats/StatsContainer';

import { useStyles } from './styles';

const toReadable = (valueWei: BigNumber, token: Token) =>
  formatTokensToReadableValue({
    value: convertWeiToTokens({
      valueWei,
      token,
    }),
    token,
    minimizeDecimals: true,
  });

type StatsSectionProps = {
  tpi: TPIStatistic;
};

const StatsSection: React.FC<StatsSectionProps> = ({ tpi }) => {
  const styles = useStyles();
  const tpiToken = getTokenByAddress(getContractAddress('tpi')) as Token;
  const tpiCells = useMemo(
    () => [
      {
        label: 'To Distribute',
        value: tpi ? toReadable(tpi.comptrollerBalance, tpiToken) : '-',
      },
      {
        label: 'Total Burned',
        value: tpi ? toReadable(tpi.deadBalance, tpiToken) : '-',
      },
      {
        label: 'Total Supply',
        value: tpi ? toReadable(tpi.totalSupply, tpiToken) : '-',
      },
    ],
    [tpi],
  );
  return (
    <div css={styles.section}>
      <div css={styles.title}>
        <TokenIcon token={tpiToken} /> TPI
      </div>
      <StatsContainer cells={tpiCells} />
    </div>
  );
};

export default StatsSection;
