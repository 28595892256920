import config from 'config';

import { MAINNET_TOKENS } from './common/mainnet';
import { TESTNET_TOKENS } from './common/testnet';
import { MAINNET_CERC_TOKENS } from './cErc/mainnet';
import { TESTNET_CERC_TOKENS } from './cErc/testnet';

export * from './common/mainnet';
export * from './common/testnet';
export * from './cErc/testnet';
export * from './cErc/testnet';

export const TOKENS = config.isOnTestnet ? TESTNET_TOKENS : MAINNET_TOKENS;

export const CERC_TOKENS = config.isOnTestnet ? TESTNET_CERC_TOKENS : MAINNET_CERC_TOKENS;
