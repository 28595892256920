import BigNumber from 'bignumber.js';
import { ContractCallContext, Multicall } from 'ethereum-multicall';

import gnftAbi from 'constants/contracts/abis/gnft.json';

import { Gnft } from 'types/contracts';
import { getContractAddress } from 'utilities';
import { getWeb3NoAccount } from 'clients/web3';

export interface GetGnftTokenIdsInAccountInput {
  gnftContract: Gnft;
  accountAddress: string;
}

export type GetGnftTokenIdsInAccountOutput = number[];

const getGnftTokenIdsInAccount = async ({
  gnftContract,
  accountAddress,
}: GetGnftTokenIdsInAccountInput) => {
  const multicall = new Multicall({ web3Instance: getWeb3NoAccount(), tryAggregate: true });

  const balanceOf = await gnftContract.methods.balanceOf(accountAddress).call();

  const indexes = [];

  for (let i = 0; i < parseFloat(balanceOf); i++) {
    indexes.push(i);
  }

  const contractCallContexts = indexes.reduce((acc, tokenIndex) => {
    const contractCallContext: ContractCallContext = {
      reference: tokenIndex.toString(),
      contractAddress: getContractAddress('gnft'),
      abi: gnftAbi,
      calls: [
        {
          reference: 'tokenOfOwnerByIndex',
          methodName: 'tokenOfOwnerByIndex',
          methodParameters: [accountAddress, tokenIndex],
        },
      ],
    };

    return [...acc, contractCallContext];
  }, [] as ContractCallContext[]);

  const multicallResult = await multicall.call(contractCallContexts);

  const tokenIds = Object.keys(multicallResult.results).map(key => {
    const result = multicallResult.results[key];

    return new BigNumber(result.callsReturnContext[0].returnValues[0].hex).toNumber();
  });

  return tokenIds;
};

export default getGnftTokenIdsInAccount;
