import { CToken, ChainId } from 'types';

import cBtcb from 'assets/img/tokens/cWbtc.svg';
import cEth from 'assets/img/tokens/cEth.svg';
import cUsdc from 'assets/img/tokens/cUsdc.svg';
import cUsdt from 'assets/img/tokens/cUsdt.svg';
import cpTon from 'assets/img/tokens/cpTon.svg';
import cDai from 'assets/img/tokens/cDai.svg';

import C_TOKEN_ADDRESSES from '../../contracts/addresses/cTokens.json';
import { TESTNET_TOKENS } from '../common/testnet';

export const TESTNET_CERC_TOKENS = {
  usdc: {
    id: 'usdc',
    symbol: 'tUSDC',
    address: C_TOKEN_ADDRESSES.usdc[ChainId.MUMBAI],
    decimals: 8,
    asset: cUsdc,
    underlyingToken: TESTNET_TOKENS.usdc,
  } as CToken,
  usdt: {
    id: 'usdt',
    symbol: 'tUSDT',
    address: C_TOKEN_ADDRESSES.usdt[ChainId.MUMBAI],
    decimals: 8,
    asset: cUsdt,
    underlyingToken: TESTNET_TOKENS.usdt,
  } as CToken,
  wbtc: {
    id: 'wbtc',
    symbol: 'tWBTC',
    address: C_TOKEN_ADDRESSES.wbtc[ChainId.MUMBAI],
    decimals: 8,
    asset: cBtcb,
    underlyingToken: TESTNET_TOKENS.wbtc,
  } as CToken,
  dai: {
    id: 'dai',
    symbol: 'tDAI',
    address: C_TOKEN_ADDRESSES.dai[ChainId.MUMBAI],
    decimals: 8,
    asset: cDai,
    underlyingToken: TESTNET_TOKENS.dai,
  } as CToken,
  weth: {
    id: 'weth',
    symbol: 'tWETH',
    address: C_TOKEN_ADDRESSES.weth[ChainId.MUMBAI],
    decimals: 8,
    asset: cEth,
    underlyingToken: TESTNET_TOKENS.weth,
  } as CToken,
  pton: {
    id: 'pton',
    symbol: 'tPTON',
    address: C_TOKEN_ADDRESSES.pton[ChainId.MUMBAI],
    decimals: 8,
    asset: cpTon,
    underlyingToken: TESTNET_TOKENS.pton,
  } as CToken,
};
