import { request } from 'graphql-request';

import {
  AssetsInAccountQueryDocument,
  MarketsQueryDocument,
  subgraphEndpoint,
} from 'clients/subgraph/gql/queries';

export const getAssetsInAccount = (accountAddress: string) =>
  request(subgraphEndpoint, AssetsInAccountQueryDocument, { accountAddress });

export const getMarkets = () => request(subgraphEndpoint, MarketsQueryDocument);
