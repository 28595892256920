import React, { SVGProps } from 'react';

const SvgGnftSegment = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.11033 16.7666C1.91264 16.5779 1.80078 16.3165 1.80078 16.0433L1.80078 3.35779C1.80078 2.8055 2.2485 2.35779 2.80078 2.35779L29.2008 2.35779C29.753 2.35779 30.2008 2.8055 30.2008 3.35779L30.2008 16.0432C30.2008 16.3165 30.0889 16.5779 29.8912 16.7666L16.6912 29.3657C16.3048 29.7345 15.6967 29.7345 15.3103 29.3657L2.11033 16.7666Z"
      fill="#0B2533"
      stroke="#27B1FF"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M2.80078 3.35779L2.80078 16.0122L16.0008 28.6423L16.0008 3.35779L2.80078 3.35779Z"
      fill="#005380"
      stroke="#27B1FF"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M29.2 3.35779L29.2 16.0122L16 28.6423L16 3.35779L29.2 3.35779Z"
      fill="#002A40"
      stroke="#27B1FF"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M2.80078 3.35779L29.2008 3.35779L16.0008 16L2.80078 3.35779Z"
      fill="#007DBF"
      stroke="#27B1FF"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgGnftSegment;
