import { QueryObserverOptions, useQuery } from 'react-query';

import { useGnftSegmentManagerContract } from 'clients/contracts/hooks';
import { DEFAULT_REFETCH_INTERVAL_MS } from 'constants/defaultRefetchInterval';
import FunctionKey from 'constants/functionKey';

import getGnftSegmentsActivationPrice, {
  GetGnftSegmentsActivationPriceInput,
  GetGnftSegmentsActivationPriceOutput,
} from '.';

type Options = QueryObserverOptions<
  GetGnftSegmentsActivationPriceOutput,
  Error,
  GetGnftSegmentsActivationPriceOutput,
  GetGnftSegmentsActivationPriceOutput,
  [FunctionKey.GET_GNFT_SEGMENT_ACTIVATION_PRICE, string, number]
>;

const useGetGnftSegmentsActivationPrice = (
  { tokenId, segmentsToOpen }: Omit<GetGnftSegmentsActivationPriceInput, 'gnftSegmentManagerContract'>,
  options?: Options,
) => {
  const gnftSegmentManagerContract = useGnftSegmentManagerContract();

  return useQuery(
    [FunctionKey.GET_GNFT_SEGMENT_ACTIVATION_PRICE, tokenId, segmentsToOpen],
    () =>
      getGnftSegmentsActivationPrice({
        gnftSegmentManagerContract,
        tokenId,
        segmentsToOpen,
      }),
    {
      refetchInterval: DEFAULT_REFETCH_INTERVAL_MS,
      ...options,
    },
  );
};

export default useGetGnftSegmentsActivationPrice;
