import sample from 'lodash/sample';
import { ChainId } from 'types';

import { SCAN_URLS } from 'constants/eth';
import { API_ENDPOINT_URLS, RPC_URLS } from 'constants/endpoints';

export interface Config {
  chainId: ChainId;
  isOnTestnet: boolean;
  rpcUrl: string;
  apiUrl: string;
  scanUrl: string;
}

const chainId: ChainId = process.env.REACT_APP_CHAIN_ID
  ? parseInt(process.env.REACT_APP_CHAIN_ID, 10)
  : ChainId.MAINNET;

const isOnTestnet = [ChainId.GOERLI, ChainId.MUMBAI].includes(chainId);
const rpcUrl = sample(RPC_URLS[chainId]) as string;
const apiUrl = API_ENDPOINT_URLS[chainId];
const scanUrl = SCAN_URLS[chainId];

const config: Config = {
  chainId,
  isOnTestnet,
  rpcUrl,
  apiUrl,
  scanUrl,
};

export default config;
