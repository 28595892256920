/** @jsxImportSource @emotion/react */
import React, { useContext } from 'react';
import { Paper } from '@mui/material';
import { Link } from 'react-router-dom';

import { AuthContext } from 'context/AuthContext';

import ConnectButton from 'components/Layout/ConnectButton';

import { useStyles } from './styles';

import diamond from './diamond.png';

type GnftCardProps = {
  className?: string;
};

const GnftCard: React.FC<GnftCardProps> = ({ className }) => {
  const { account } = useContext(AuthContext);

  const styles = useStyles();

  return (
    <Paper className={className}>
      <h4 css={styles.title}>gNFT Perfomarnce</h4>
      <div css={styles.info}>
        <img css={styles.image} src={diamond} alt="gNFT Perfomarnce" />
        <p>
          The Tonpound protocol is managed by its DAO using governance NFTs. Please connect your
          wallet to claim gNFT on the <Link to="/gnft">gNFT Page</Link>
        </p>
      </div>
      {!account && <ConnectButton fullWidth />}
    </Paper>
  );
};

export default GnftCard;
