import FunctionKey from 'constants/functionKey';
import { useQuery, QueryObserverOptions } from 'react-query';

import { getEasterEggs, GetEasterEggsInput, GetEasterEggsOutput } from 'clients/api';

type Options = QueryObserverOptions<
GetEasterEggsOutput,
  Error,
  GetEasterEggsOutput,
  GetEasterEggsOutput,
  [FunctionKey.GET_EASTER_EGGS, GetEasterEggsInput]
>;

const useGetEasterEggs = (input: GetEasterEggsInput, options?: Options) =>
  useQuery([FunctionKey.GET_EASTER_EGGS, input], () => getEasterEggs(input), options);

export default useGetEasterEggs;
