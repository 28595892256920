import { QueryObserverOptions, useQuery } from 'react-query';

import { useGnftContract, useTreasuryViewContract } from 'clients/contracts/hooks';
import { DEFAULT_REFETCH_INTERVAL_MS } from 'constants/defaultRefetchInterval';
import FunctionKey from 'constants/functionKey';

import getGnftInAccount, { GetGnftsInAccountInput, GetGnftsInAccountOutput } from '.';

type Options = QueryObserverOptions<
  GetGnftsInAccountOutput,
  Error,
  GetGnftsInAccountOutput,
  GetGnftsInAccountOutput,
  [FunctionKey.GET_GNFT_IN_ACCOUNT, string]
>;

const useGetGnftInAccount = (
  { accountAddress, treasuryAddress }: Omit<GetGnftsInAccountInput, 'gnftContract' | 'treasuryViewContract'>,
  options?: Options,
) => {
  const gnftContract = useGnftContract();
  const treasuryViewContract = useTreasuryViewContract();

  return useQuery(
    [FunctionKey.GET_GNFT_IN_ACCOUNT, accountAddress],
    () =>
      getGnftInAccount({
        gnftContract,
        treasuryViewContract,
        treasuryAddress,
        accountAddress,
      }),
    {
      refetchInterval: DEFAULT_REFETCH_INTERVAL_MS,
      ...options,
    },
  );
};

export default useGetGnftInAccount;
