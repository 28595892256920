import { useMemo } from 'react';
import { Asset, Pool } from 'types';

const isAssetInSearch = ({
  asset,
  pool,
  searchValue,
}: {
  asset: Asset;
  pool: Pool;
  searchValue: string;
}) => {
  const lowerCasedSearchValue = searchValue.toLowerCase();

  return (
    asset.cToken.underlyingToken.symbol.toLowerCase().includes(lowerCasedSearchValue) ||
    pool.name.toLowerCase().includes(lowerCasedSearchValue)
  );
};

const useFormatPools = ({
  pools,
  includeHigherRiskPools,
  searchValue,
}: {
  pools: Pool[];
  includeHigherRiskPools: boolean;
  searchValue: string;
}) => {
  const filteredPools = useMemo(
    () =>
      // Filter ou pools that don't have a minimal risk rating if switch is
      // turned off
      includeHigherRiskPools ? pools : pools.filter(pool => pool.riskRating === 'MINIMAL'),
    [pools, includeHigherRiskPools],
  );

  const formattedPools = useMemo(() => {
    if (!searchValue) {
      return filteredPools;
    }

    return filteredPools.map(pool => ({
      ...pool,
      assets: pool.assets.filter(asset =>
        isAssetInSearch({
          asset,
          pool,
          searchValue,
        }),
      ),
    }));
  }, [filteredPools, searchValue]);

  const userPools: Pool[] = [];
  const poolsToSupply: Pool[] = [];
  const poolsToBorrow: Pool[] = [];
  const userSuppliedPools: Pool[] = [];
  const userBorrowedPools: Pool[] = [];

  formattedPools.forEach(pool => {
    const suppliedAssets: Asset[] = [];
    const borrowedAssets: Asset[] = [];
    const assetsToSupply: Asset[] = [];
    const assetsToBorrow: Asset[] = [];
    const userAssets: Asset[] = [];

    pool.assets.forEach(asset => {
      const supplied = asset.userSupplyBalanceCents > 0;
      const borrowed = asset.userBorrowBalanceCents > 0;

      if (supplied) {
        suppliedAssets.push(asset);
      } else {
        assetsToSupply.push(asset);
      }
      if (borrowed) {
        borrowedAssets.push(asset);
      } else {
        assetsToBorrow.push(asset);
      }
      if (supplied || borrowed) userAssets.push(asset);
    });

    if (suppliedAssets.length) {
      userSuppliedPools.push({
        ...pool,
        assets: suppliedAssets,
      });
    }

    if (borrowedAssets.length) {
      userBorrowedPools.push({
        ...pool,
        assets: borrowedAssets,
      });
    }

    if (userAssets.length) {
      userPools.push({
        ...pool,
        assets: userAssets,
      });
    }

    if (assetsToSupply.length) {
      poolsToSupply.push({
        ...pool,
        assets: assetsToSupply,
      });
    }

    if (assetsToBorrow.length) {
      poolsToBorrow.push({
        ...pool,
        assets: assetsToBorrow,
      });
    }
  });

  return {
    userBorrowedPools,
    userSuppliedPools,
    userPools,
    formattedPools,
    poolsToBorrow,
    poolsToSupply,
  };
};

export default useFormatPools;
