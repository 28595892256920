/** @jsxImportSource @emotion/react */
import React, { useContext, useState } from 'react';
import { Pool } from 'types';
import { Paper } from '@mui/material';

import { useGetPools } from 'clients/api';
import { MarketTable } from 'containers/MarketTable';
import { AuthContext } from 'context/AuthContext';
// import { useHideXlDownCss } from 'hooks/responsive';

import Summary from '../Account/Summary';

import { useStyles } from './styles';
import TEST_IDS from './testIds';
import useFormatPools from './useFormatPools';

import GnftCard from './GnftCard';

interface DashboardUiProps {
  areHigherRiskPoolsDisplayed: boolean;
  onHigherRiskPoolsToggleChange: (newValue: boolean) => void;
  searchValue: string;
  onSearchInputChange: (newValue: string) => void;
  pools: Pool[];
  isFetchingPools?: boolean;
}

const DashboardUi: React.FC<DashboardUiProps> = ({
  pools,
  isFetchingPools,
  areHigherRiskPoolsDisplayed,
  searchValue,
}) => {
  const styles = useStyles();

  const { poolsToBorrow, poolsToSupply, userPools, userSuppliedPools, userBorrowedPools } = useFormatPools({
    pools,
    includeHigherRiskPools: areHigherRiskPoolsDisplayed,
    searchValue,
  });

  return (
    <>
      <div css={styles.row}>
        <Summary pools={userPools} displayAccountHealth css={styles.column} />
        <GnftCard css={styles.column} />
      </div>

      <div css={styles.row}>
        <Paper css={[styles.column, styles.columnTable]}>
          {userSuppliedPools.length ? (
            <MarketTable
              className="supplied-market-table"
              title="Supplied"
              key="dashboard-supplied-market-table"
              pools={userSuppliedPools}
              isFetching={isFetchingPools}
              marketType="supply"
              breakpoint="lg"
              columns={['asset', 'supplyApy', 'userSupplyBalance', 'collateral']}
              initialOrder={{
                orderBy: 'supplyApy',
                orderDirection: 'desc',
              }}
            />
          ) : null}
          <MarketTable
            title="Supply market"
            testId={TEST_IDS.supplyMarketTable}
            key="dashboard-supply-market-table"
            pools={poolsToSupply}
            isFetching={isFetchingPools}
            marketType="supply"
            breakpoint="lg"
            columns={['asset', 'supplyApy', 'userWalletBalanceTokens', 'collateral']}
            initialOrder={{
              orderBy: 'supplyApy',
              orderDirection: 'desc',
            }}
          />
        </Paper>
        <Paper css={[styles.column, styles.columnTable]}>
          {userBorrowedPools.length ? (
            <MarketTable
              className="borrowed-market-table"
              title="Borrowed"
              key="dashboard-borrowed-market-table"
              pools={userBorrowedPools}
              isFetching={isFetchingPools}
              marketType="borrow"
              breakpoint="lg"
              columns={['asset', 'borrowApy', 'userBorrowBalance', 'liquidity']}
              initialOrder={{
                orderBy: 'borrowApy',
                orderDirection: 'desc',
              }}
            />
          ) : null}
          <MarketTable
            title="Borrow market"
            testId={TEST_IDS.borrowMarketTable}
            key="dashboard-borrow-market-table"
            pools={poolsToBorrow}
            isFetching={isFetchingPools}
            marketType="borrow"
            breakpoint="lg"
            columns={['asset', 'borrowApy', 'userWalletBalanceTokens', 'liquidity']}
            initialOrder={{
              orderBy: 'borrowApy',
              orderDirection: 'desc',
            }}
          />
        </Paper>
      </div>
    </>
  );
};

const Dashboard: React.FC = () => {
  const { account } = useContext(AuthContext);
  const accountAddress = account?.address || '';

  const [searchValue, setSearchValue] = useState('');
  const [areHigherRiskPoolsDisplayed, setAreHigherRiskTokensDisplayed] = useState(true);

  const { data: getPoolData, isLoading: isGetPoolsLoading } = useGetPools({
    accountAddress,
  });

  return (
    <DashboardUi
      pools={getPoolData?.pools || []}
      isFetchingPools={isGetPoolsLoading}
      areHigherRiskPoolsDisplayed={areHigherRiskPoolsDisplayed}
      onHigherRiskPoolsToggleChange={setAreHigherRiskTokensDisplayed}
      searchValue={searchValue}
      onSearchInputChange={setSearchValue}
    />
  );
};

export default Dashboard;
