import BigNumber from 'bignumber.js';
import { useMemo } from 'react';
import { Asset, Market } from 'types';
import {
  calculateCollateralValue,
  convertTokensToWei,
  getCTokenByAddress,
  indexBy,
} from 'utilities';

import {
  IGetCTokenBalancesAllOutput,
  useGetAssetsInAccount,
  useGetMainMarkets,
  useGetCTokenBalancesAll,
} from 'clients/api';
import { TOKENS, CERC_TOKENS } from 'constants/tokens';

export interface Data {
  assets: Asset[];
  userTotalBorrowLimitCents: BigNumber;
  userTotalBorrowBalanceCents: BigNumber;
  userTotalSupplyBalanceCents: BigNumber;
  totalTpiDistributedWei: BigNumber;
  dailyTpiWei: BigNumber;
}

export interface UseGetUserMarketInfoOutput {
  isLoading: boolean;
  data: Data;
}

const cTokenAddresses = Object.values(CERC_TOKENS).reduce(
  (acc, item) => (item.address ? [...acc, item.address] : acc),
  [] as string[],
);

// TODO: decouple, this hook handles too many things (see https://app.clickup.com/t/2d4rfx6)
const useGetUserMarketInfo = ({
  accountAddress,
}: {
  accountAddress?: string;
}): UseGetUserMarketInfoOutput => {
  const {
    data: getMarketsData = {
      markets: [],
      dailyTpiWei: new BigNumber(0),
    },
    isLoading: isGetMarketsLoading,
  } = useGetMainMarkets();

  const {
    data: assetsInAccount = {
      tokenAddresses: [],
    },
    isLoading: isGetAssetsInAccountLoading,
  } = useGetAssetsInAccount(
    { accountAddress: accountAddress || '' },
    {
      enabled: !!accountAddress,
      placeholderData: {
        tokenAddresses: [],
      },
    },
  );

  const {
    data: cTokenBalancesAccount = { balances: [] },
    isLoading: isGetCTokenBalancesAccountLoading,
  } = useGetCTokenBalancesAll(
    { account: accountAddress || '', cTokenAddresses },
    { enabled: !!accountAddress, placeholderData: { balances: [] } },
  );

  const cTokenBalances = useMemo(
    () =>
      indexBy(
        (item: IGetCTokenBalancesAllOutput['balances'][number]) => item.cToken.toLowerCase(), // index by vToken address
        cTokenBalancesAccount.balances,
      ),
    [JSON.stringify(cTokenBalancesAccount)],
  );

  const isLoading =
    isGetMarketsLoading || isGetAssetsInAccountLoading || isGetCTokenBalancesAccountLoading;

  const initialData = {
    assets: [] as Asset[],
    userTotalBorrowBalanceCents: new BigNumber(0),
    userTotalBorrowLimitCents: new BigNumber(0),
    userTotalSupplyBalanceCents: new BigNumber(0),
    totalTpiDistributedWei: new BigNumber(0),
  };

  const data = useMemo(() => {
    const markets: Market[] = getMarketsData?.markets || [];

    let dailyTpiWei = new BigNumber(0);

    const {
      assets,
      userTotalBorrowBalanceCents,
      userTotalBorrowLimitCents,
      userTotalSupplyBalanceCents,
      totalTpiDistributedWei,
    } = markets.reduce((acc, market): any => {
      const cToken = getCTokenByAddress(market.address);
      const token = cToken?.underlyingToken;

      // Skip token if it isn't listed
      if (!token || !cToken) {
        return acc;
      }

      const cTokenAddress = cToken.address.toLowerCase();
      const collateral = (assetsInAccount.tokenAddresses || [])
        .map((address: string) => address.toLowerCase())
        .includes(cTokenAddress);

      let walletBalance = new BigNumber(0);
      let supplyBalance = new BigNumber(0);
      let borrowBalance = new BigNumber(0);
      const percentOfLimit = '0';

      const wallet = cTokenBalances && cTokenBalances[cTokenAddress];
      if (accountAddress && wallet) {
        const toDecimalAmount = (mantissa: string) =>
          new BigNumber(mantissa).shiftedBy(-token.decimals);

        walletBalance = toDecimalAmount(wallet.tokenBalance);
        supplyBalance = toDecimalAmount(wallet.balanceOfUnderlying);
        borrowBalance = toDecimalAmount(wallet.borrowBalanceCurrent);
      }

      const tpiPerDayWei = new BigNumber(market?.supplierDailyTPI || 0)
        .plus(new BigNumber(market?.borrowerDailyTPI || 0));

      const tpiPerDay = tpiPerDayWei.div(new BigNumber(10).pow(TOKENS.tpi.decimals));

      dailyTpiWei = dailyTpiWei.plus(tpiPerDayWei);

      const asset = {
        token,
        supplyApy: new BigNumber(market?.supplyApy || 0),
        borrowApy: new BigNumber(market?.borrowApy || 0),
        tpiSupplyApy: new BigNumber(market?.supplyTPIApr || 0),
        tpiBorrowApy: new BigNumber(market?.borrowTPIApr || 0),
        collateralFactor: new BigNumber(market?.collateralFactor || 0).div(1e18),
        tokenPrice: new BigNumber(market?.tokenPrice || 0),
        liquidity: new BigNumber(market?.liquidity || 0),
        borrowCaps: new BigNumber(market?.borrowCaps || 0),
        treasuryTotalBorrowsCents: new BigNumber(market?.totalBorrowsUsd || 0).times(100),
        treasuryTotalSupplyCents: new BigNumber(market?.totalSupplyUsd || 0).times(100),
        treasuryTotalSupply: new BigNumber(market?.totalSupply || 0),
        treasuryTotalBorrows: new BigNumber(market?.totalBorrows2 || 0),
        walletBalance,
        supplyBalance,
        borrowBalance,
        collateral,
        percentOfLimit,
        tpiPerDay,
      };

      // user totals
      const borrowBalanceCents = asset.borrowBalance.times(asset.tokenPrice).times(100);
      const supplyBalanceCents = asset.supplyBalance.times(asset.tokenPrice).times(100);
      acc.userTotalBorrowBalanceCents = acc.userTotalBorrowBalanceCents.plus(borrowBalanceCents);
      acc.userTotalSupplyBalanceCents = acc.userTotalSupplyBalanceCents.plus(supplyBalanceCents);

      acc.totalTpiDistributedWei = acc.totalTpiDistributedWei.plus(
        new BigNumber(market?.totalDistributed || 0).times(
          new BigNumber(10).pow(TOKENS.tpi.decimals),
        ),
      );

      // Create borrow limit based on assets supplied as collateral
      if (asset.collateral) {
        acc.userTotalBorrowLimitCents = acc.userTotalBorrowLimitCents.plus(
          calculateCollateralValue({
            amountWei: convertTokensToWei({ value: asset.supplyBalance, token }),
            token: asset.token,
            tokenPriceDollars: asset.tokenPrice,
            collateralFactor: asset.collateralFactor.toNumber(),
          }).times(100),
        );
      }

      return { ...acc, assets: [...acc.assets, asset] };
    }, initialData);

    let assetList = assets;

    // percent of limit
    assetList = assetList.map((item: Asset) => ({
      ...item,
      percentOfLimit: new BigNumber(userTotalBorrowLimitCents).isZero()
        ? '0'
        : new BigNumber(item.borrowBalanceCents)
            .times(item.tokenPriceDollars)
            .div(userTotalBorrowLimitCents)
            .times(100)
            .dp(0, 1)
            .toFixed(),
    }));

    return {
      assets: assetList,
      userTotalBorrowBalanceCents,
      userTotalBorrowLimitCents,
      userTotalSupplyBalanceCents,
      dailyTpiWei,
      totalTpiDistributedWei,
    };
  }, [
    JSON.stringify(getMarketsData?.markets),
    JSON.stringify(assetsInAccount),
    JSON.stringify(cTokenBalances),
    JSON.stringify(getMarketsData),
  ]);

  return {
    isLoading,
    data,
    // TODO: handle errors and retry scenarios
  };
};

export default useGetUserMarketInfo;
