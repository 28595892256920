import { checkForComptrollerTransactionError } from 'errors';
import type { TransactionReceipt } from 'web3-core/types';

import { GnftSegmentManager } from 'types/contracts';

export interface ActivateGnftSegmentsInput {
  gnftSegmentManagerContract: GnftSegmentManager;
  fromAccountAddress: string;
  tokenId: string;
  segmentsToOpen: number;
  market: string;
}

export type ActivateGnftSegmentsOutput = TransactionReceipt;

const activateGnftSegments = async ({
  gnftSegmentManagerContract,
  fromAccountAddress,
  tokenId,
  segmentsToOpen,
  market,
}: ActivateGnftSegmentsInput): Promise<ActivateGnftSegmentsOutput> => {
  const resp = await gnftSegmentManagerContract.methods
    .activateSegments(tokenId, segmentsToOpen, market)
    .send({ from: fromAccountAddress });

  return checkForComptrollerTransactionError(resp);
};

export default activateGnftSegments;
