/** @jsxImportSource @emotion/react */
import { Typography } from '@mui/material';
import { Table, TableColumn, TokenIconWithSymbol } from 'components';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'translation';
import { AssetDistribution, Token } from 'types';
import {
  compareBigNumbers,
  formatToReadablePercentage,
  formatTokensToReadableValue,
} from 'utilities';

import { useGetMainAssets } from 'clients/api';
import { TOKENS } from 'constants/tokens';
import { AuthContext } from 'context/AuthContext';

import { useStyles } from '../styles';

type TableAsset = {
  token: Token;
  tpiPerDay: AssetDistribution['dailyDistributedTokens'] | undefined;
  tpiSupplyApy: AssetDistribution['supplyAprPercentage'] | undefined;
  tpiBorrowApy: AssetDistribution['borrowAprPercentage'] | undefined;
};

interface TpiTableProps {
  assets: TableAsset[];
  isFetchingAssets: boolean;
}

const TpiTableUi: React.FC<TpiTableProps> = ({ assets, isFetchingAssets }) => {
  const { t } = useTranslation();
  const styles = useStyles();

  const columns: TableColumn<TableAsset>[] = useMemo(
    () => [
      {
        key: 'asset',
        label: t('tpi.columns.asset'),
        renderCell: ({ token }) => <TokenIconWithSymbol token={token} />,
      },
      {
        key: 'tpiPerDay',
        label: t('tpi.columns.tpiPerDay'),
        align: 'right',
        renderCell: ({ tpiPerDay }) => (
          <Typography variant="small1" css={[styles.whiteText, styles.fontWeight400]}>
            {formatTokensToReadableValue({
              value: tpiPerDay,
              token: TOKENS.tpi,
              minimizeDecimals: true,
            })}
          </Typography>
        ),
        sortRows: (rowA, rowB, direction) =>
          compareBigNumbers(rowA.tpiPerDay, rowB.tpiPerDay, direction),
      },
      {
        key: 'supplyTpiApy',
        label: t('tpi.columns.supplyTpiApy'),
        align: 'right',
        renderCell: ({ tpiSupplyApy }) => (
          <Typography variant="small1" css={[styles.whiteText, styles.fontWeight400]}>
            {formatToReadablePercentage(tpiSupplyApy)}
          </Typography>
        ),
        sortRows: (rowA, rowB, direction) =>
          compareBigNumbers(rowA.tpiSupplyApy, rowB.tpiSupplyApy, direction),
      },
      // {
      //   key: 'borrowTpiApy',
      //   label: t('tpi.columns.borrowTpiApy'),
      //   align: 'right',
      //   renderCell: ({ tpiBorrowApy }) => (
      //     <Typography variant="small1" css={[styles.whiteText, styles.fontWeight400]}>
      //       {formatToReadablePercentage(tpiBorrowApy)}
      //     </Typography>
      //   ),
      //   sortRows: (rowA, rowB, direction) =>
      //     compareBigNumbers(rowA.tpiBorrowApy, rowB.tpiBorrowApy, direction),
      // },
    ],
    [],
  );

  return (
    <Table
      data={assets}
      columns={columns}
      isFetching={isFetchingAssets}
      initialOrder={{
        orderBy: columns[1],
        orderDirection: 'desc',
      }}
      rowKeyExtractor={row =>
        `tpi-table-row-${row.token.address}-${row.tpiBorrowApy}-${row.tpiPerDay}-${row.tpiSupplyApy}`
      }
      breakpoint="sm"
      css={styles.cardContentGrid}
    />
  );
};

const TpiTable: React.FC = () => {
  const { account } = useContext(AuthContext);

  const accountAddress = account?.address || '';

  const { data: getMainAssetsData, isLoading: isGetMainAssetsLoading } = useGetMainAssets({
    accountAddress,
  });

  const assets = useMemo(() => {
    const allAssets: TableAsset[] = (getMainAssetsData?.assets || []).map(asset => ({
      token: asset.cToken.underlyingToken,
      // Note: assets from the main pool only yield XVS, hence why we only take
      // the first distribution token in consideration (which will always be XVS
      // here)
      tpiPerDay: asset.distributions[0].dailyDistributedTokens,
      tpiSupplyApy: asset.distributions[0].supplyAprPercentage,
      tpiBorrowApy: asset.distributions[0].borrowAprPercentage,
    }));

    return allAssets;
  }, [getMainAssetsData?.assets]);

  return <TpiTableUi assets={assets} isFetchingAssets={isGetMainAssetsLoading} />;
};

export default TpiTable;
