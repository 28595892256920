import { css } from '@emotion/react';

export const useStyles = () => ({
  statsContainer: css`
    display: flex;
    gap: 8px;
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: 10px;
  `,
  statsItem: css`
    padding: 24px 24px 32px;
    border: 1px solid #212429;
    border-radius: 20px;
    flex: 1;
  `,
  statsItemValue: css`
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #ffffff;
  `,
  statsItemTitle: css`
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
    opacity: 0.6;
  `,
});
