import { QueryObserverOptions, useQuery } from 'react-query';

import { useGnftContract } from 'clients/contracts/hooks';
import { DEFAULT_REFETCH_INTERVAL_MS } from 'constants/defaultRefetchInterval';
import FunctionKey from 'constants/functionKey';

import getGnftTokenIdsInAccount, {
  GetGnftTokenIdsInAccountInput,
  GetGnftTokenIdsInAccountOutput,
} from '.';

type Options = QueryObserverOptions<
  GetGnftTokenIdsInAccountOutput,
  Error,
  GetGnftTokenIdsInAccountOutput,
  GetGnftTokenIdsInAccountOutput,
  [FunctionKey.GET_GNFT_IDS_IN_ACCOUNT, string]
>;

const useGetGnftTokenIdsInAccount = (
  { accountAddress }: Omit<GetGnftTokenIdsInAccountInput, 'gnftContract'>,
  options?: Options,
) => {
  const gnftContract = useGnftContract();

  return useQuery(
    [FunctionKey.GET_GNFT_IDS_IN_ACCOUNT, accountAddress],
    () =>
      getGnftTokenIdsInAccount({
        gnftContract,
        accountAddress,
      }),
    {
      refetchInterval: DEFAULT_REFETCH_INTERVAL_MS,
      ...options,
      enabled: !!accountAddress && options?.enabled,
    },
  );
};

export default useGetGnftTokenIdsInAccount;
